import Cookies from 'js-cookie';

import './index.css';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import NewSidebar from '../NewSidebar';
import { dataContext } from '../../Context/data';

const UsersAccount=()=>{
    const {setCurrentUser}=useContext(dataContext);

    const navigate=useNavigate();
    const handleLogout=()=>{
        Cookies.remove('userData');
        Cookies.remove('currentBusiness');

        // const busines=Cookies.get('currentBusiness');
        // if(){

        // }
        setCurrentUser("");
        navigate('/');
    }
    const [userData,setUserData]=useState("");
    
    useEffect(()=>{
        const usersData=Cookies.get('userData');
    if(usersData){
        setUserData(JSON.parse(usersData));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <>
        <NewSidebar/>
        <div className="">
            <div className='user-profile'>
                <p>Name:  {userData.displayName} </p>
                <p>Gmail: {userData.email} </p>
                
            </div>
            
            <button onClick={()=>{handleLogout()}}>Logout</button>
        </div>
        </>
    )
}

export default UsersAccount;