import NewSidebar from '../NewSidebar';
import showdown  from 'showdown';
import { VscSend } from "react-icons/vsc";
import { IoMenuOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { ThreeDots } from 'react-loader-spinner';
import './index.css';
import { base_url } from '../../utils/new';
import { useEffect, useRef, useState } from 'react';

const ChatTest=()=>{
    const [chatData,setChatData]=useState([{"answer":"Hello! How can I help you today?"},]);
        const [query, setQuery] = useState("");
        const chatBodyRef = useRef(null);
        const [isTyping,setIsTyping]=useState(false);
        const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        const openaiApiKey ="sk-proj-Wz2SovoIJ2hHNeMFJy3YT3BlbkFJh3FjQCaWH7WVtV3Yd0L5";

       
        const formatAnswerToHtml = (answer) => {
            // Replace new lines with <br/> tags
            let formattedAnswer = answer.replace(/\n/g, '<br/>');
            formattedAnswer = formattedAnswer.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" target="_blank">$2</a>');
            formattedAnswer = formattedAnswer.replace(/(\+91 \d{5} \d{5})/g, '<br/>Contact: $1');
           formattedAnswer = formattedAnswer.replace(/([a-zA-Z0-9._%+-]+@gmail\.com)/g, '<br/>Email: <a href="mailto:$1">$1</a>');
           formattedAnswer = formattedAnswer.replace(/\*\*([^\*]+)\*\*/g, '<strong>$1</strong>');
            return formattedAnswer;
        };

        const sendQuery = async() => {
            let tempQuery=query;
            const tempDataWithQuestion = [...chatData, { "question": query }];
            setChatData(tempDataWithQuestion);
            setQuery("");
            setIsTyping(true);
            // await sleep(2000);
            const answe = await fetch(base_url+`api/chatbot-test/${tempQuery}`);
            let {answer}= await answe.json();

            let converter = new showdown.Converter();
            answer=converter.makeHtml(answer);
        
                // const tempDataWithAnswer = [...tempDataWithQuestion, { "answer": answer }];
                setIsTyping(false);
                // setChatData(tempDataWithAnswer);
                let currentAnswer = "";
                const typingSpeed = 8; // Adjust the typing speed (in milliseconds) as needed

                for (let i = 0; i < answer.length; i++) {
                    currentAnswer += answer[i];
                    await sleep(typingSpeed);

                    // Update the state with the current portion of the answer
                    setChatData(tempDataWithQuestion.concat({ "answer": currentAnswer }));
                }

        };

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [chatData]);
    return(
        <>
        <NewSidebar/>
        <div className='recordingusers-container'>
            {/* <div className='voicecampaign-action-bar'>
                <span className="action-gap">
                    <h3 className="action-title">Chat Bot</h3>
                    <p className="action-text">Total Chat Bots : </p>
                </span>
            </div> */}
            <div className='' style={{display:"flex",flexDirection:"column"}}>
                <h2 style={{}}>Chat Bot</h2>
                <div className='' style={{display:"flex"}}>
                <div className="chatbot-wrapper2">
                    <div className={`chatbot-container2`}>
                        {/* <div className='chatbot-header'>
                            <img src="petfolk.jpeg" alt="profile-icon" className='profile-icon' style={{cursor:"pointer"}} />
                            <div className={`header-icons`}>
                                <IoMenuOutline style={{ cursor: "pointer" }}  />
                                <RxCross2 style={{ cursor: "pointer" }}  />
                            </div>
                        </div> */}
                        { (
                        <>
                            <div className='chatbot-body'ref={chatBodyRef}>
                                {chatData.map((chat, idx) =>
                                    <div key={idx} className={`chatbox ${chat.question ? 'questionBox' : 'answerBox'}`} 
                                    dangerouslySetInnerHTML={{ __html: chat.question ? chat.question : chat.answer }}>
                            </div>
                                )}
                                {isTyping && <div className="chatbox-typing"><p className=''>Typing</p><ThreeDots
                                                    visible={true}
                                                    height="30"
                                                    width="50"
                                                    color="#aaa"
                                                    radius="4"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    /></div>}
                            </div>
                            <div className='chatbot-input'>
                                <input
                                    type="text"
                                    placeholder="Type your question here..."
                                    className="chat-input"
                                    onChange={(e) => setQuery(e.target.value)}
                                    onKeyDown={(e) => { if (e.key === 'Enter') sendQuery(); }}
                                    value={query}
                                />
                                <VscSend style={{ cursor: "pointer" }} onClick={sendQuery} />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className='' style={{display:"flex",flexDirection:"column",alignItems:"flex-start",marginLeft:"10px"}}>
                <h3 style={{margin:0,marginTop:"15px"}}>Title</h3>
                <p style={{margin:0}}>PetFolk</p>
                <h3 style={{margin:0,marginTop:"15px"}}>Description</h3>
                <p style={{margin:0}}>A company which dekivers quality pet food in time.</p>
                <h3 style={{margin:0,marginTop:"15px"}}>Chat ID</h3>
                <p style={{margin:0}}>12423drg523Yb</p>
                <h3 style={{margin:0,marginTop:"15px"}}>Source</h3>
                <p style={{margin:0}}>www.petfolk.app</p>

            </div>
                </div>

            </div>
        </div>
        </>
    )
}

export default ChatTest;