import { useState } from "react";
import NewSidebar from "../NewSidebar";
import { FaArrowRight } from "react-icons/fa";
import { TbWorld, TbFileUpload } from "react-icons/tb";
import "./index.css";

const CreateChatbot=()=>{
    const [toggleChat,setToggleChat]=useState(false);
    const [language,setLanguage]=useState("");
    const [uploadType,setUploadType]=useState("website");

    return(
        <>
        <NewSidebar/>
        <div className='recordingusers-container'>
            <div className='voicecampaign-action-bar'>
                <span className="action-gap">
                    <h3 className="action-title">AI Chatbot{` > Build`}</h3>
                    <p className="action-text">Total Chat Bots : </p>
                </span>
            
                <button className="voicecampaignAddBtn" onClick={()=>{setToggleChat(p=>!p)}}><h3>Toggle</h3> </button>
            </div>
            { toggleChat?<div className="">
                <div className="" style={{display:"flex",flexDirection:"column",alignItems:"flex-start",marginLeft:"10px"}}>
                    <h2 className="" style={{margin:"0px",marginTop:"8px"}}>Create Chatbot</h2>
                    <p>Customize your AI Chat Bot</p>
                    <div className="" style={{display:"flex",gap:"3px",marginLeft:"19px"}}>
                        <div className=""
                        onClick={()=>{setUploadType('website')}}
                        style={{display:"flex",backgroundColor:"#dedfe0",borderRadius:"5px",border:"1px solid black",width:"120px",paddingLeft:"30px",paddingTop:'4px',paddingBottom:'4px',gap:"3px",cursor:"pointer"}}><TbWorld /><p style={{ margin: 0 }}>WEBSITE</p> </div>
                        <div className="" 
                        onClick={()=>{setUploadType('files')}}
                        style={{display:"flex",backgroundColor:"#dedfe0",borderRadius:"5px",border:"1px solid black",width:"100px",paddingLeft:"30px",paddingTop:'4px',paddingBottom:'4px',gap:"3px",cursor:"pointer"}}><TbFileUpload /><p style={{ margin: 0 }}>FILES</p></div>
                    </div>
                </div>
                
                <div className="create-bot-container">
                    {uploadType==="website" ?
                    <>
                    <h3 className=""  style={{margin:0,marginBottom:"5px",marginTop:'12px'}}>Website</h3>
                    <p className="" style={{margin:0}}>Enter the url of the website that you want to build for</p>
                    <h3 className="" style={{margin:0,marginBottom:"5px",marginTop:'12px'}}>Url</h3>
                    <input type="" className="" placeholder="e.g: 'https://www.demo.com/subdirectory'" style={{width:"470px",paddingTop:"5px",borderRadius:"5px",paddingBottom:"5px"}} />
                    <h3 className="" style={{margin:0,marginBottom:"5px",marginTop:'12px'}}>Language</h3>
                    <select onChange={(e)=>{setLanguage(e.target.value)}} value={language} style={{width:"100px",paddingTop:"4px",paddingBottom:"4px"}}>
                        <option value="english">English</option>
                        <option value="hindi">Hindi</option>
                    </select>
                    <p style={{margin:0,marginTop:'8px'}}>Select language of source website</p></>:
                    <>
                        <h2 className=""  style={{margin:0,marginBottom:"5px",marginTop:'12px'}}>Upload Files</h2>
                        <p className="" style={{margin:0}}>Upload files to build a chat bot</p>
                        <div className="" style={{display:"flex",flexDirection:"column",backgroundColor:"#e6e7e8",textAlign:"center",width:"95%"}}>
                            <TbFileUpload style={{marginLeft:"46%",fontSize: "50px",marginTop:"10px" }} />
                            <p className="">Drag & Drop files here or click to select files(upload limit 2.5 MB)</p>
                            <p className="">Accepts: pdf, csv, txt</p>
                        </div>

                    </>}
                    <button
                    style={{backgroundColor:"#63dbeb",color:"white",border:"none",borderRadius:"3px",paddingLeft:"8px",paddingRight:"8px",paddingTop:"7px",paddingBottom:"7px",marginTop:"18px"}}>
                    Build Now
                    </button>
                </div>
            </div>:
            <div className="">
                <div className="" style={{display:"flex",justifyContent:"space-between"}}>
                    <div className="" style={{display:"flex",flexDirection:"column",alignItems:"flex-start", marginLeft:"6px"}}>
                        <h3 className="" style={{margin:"0px",marginTop:"7px"}}>ChatBots</h3>
                        <p className="">Manage Your Chat Bot here</p>
                    </div>
                    <div className="" style={{marginRight:"12px",marginTop:"13px",display:"flex",gap:"4px"}}><p style={{margin:0,cursor:"pointer"}}>New Bot </p><FaArrowRight /></div>
                </div>
                <div className="">
                    <img src="chatbot-image.png" alt="chatbot" className="" style={{height:"40vh"}} />
                    <p className="">Create a New chat bot to get started</p>
                    <button className=""
                    onClick={()=>{setToggleChat(p=>!p)}}
                    style={{backgroundColor:"#63dbeb",color:"white",border:"none",borderRadius:"3px",paddingLeft:"8px",paddingRight:"8px",paddingTop:"7px",paddingBottom:"7px"}}>
                        Build Now
                    </button>
                </div>
            </div>}

        </div>
        </>
    )
}


export default CreateChatbot;