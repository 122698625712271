import { useContext, useEffect, useState } from 'react';
import { FaWhatsapp } from "react-icons/fa6";
import { doc, getDoc } from 'firebase/firestore';
import NewSidebar from '../NewSidebar';

import './index.css';
import { cashfree } from '../../cashfree/util';
import { base_url } from '../../utils/new';
import { dataContext } from '../../Context/data';
import { db } from '../../Config-firebase/firebase';

const VoiceAccount=()=>{

    const [plans,setPlans]=useState([{"credit":"1000","amount":"900","isSelected":false},{"credit":"2000","amount":"1600","isSelected":false},{"credit":"3000","amount":"2100","isSelected":false},{"credit":"4000","amount":"2400","isSelected":false},{"credit":"5000","amount":"2500","isSelected":false}]);
    const {runningBusiness}=useContext(dataContext);
    const [totalCost,setTotalCost]=useState(0);
    const [reqCredit,setReqCredit]=useState(0);
    const [totalAmount,setTotalAmount]=useState(0);
    const [totalCredits,setTotalCredits]=useState(0);
    const [activePaymentService,setActivePaymentService]=useState('');
    // const [sessionId,setSessionId]=useState("");
    const getSessionId=async()=>{ //get session id from backend
        // const newRunningBusiness=JSON.parse(runningBusiness);
        // console.log(newRunningBusiness,newRunningBusiness.businessEmail);
        console.log(runningBusiness,runningBusiness.businessName);
        const {businessEmail,businessName,businessOwnerNumber}=runningBusiness;
        console.log(runningBusiness.businessEmail,businessEmail);
        const customerData={
            "customerName":businessName || "",
            "cutsomerNumber":businessOwnerNumber || "",
            "customerEmail":businessEmail || "",
            "paymentValue":totalAmount || 0,
            "ivrCredits":totalCredits ||0,
        }
        console.log(customerData);
        const options={
            method:'POST',
            headers:{
                "Content-Type": "application/json",
            },
            body:JSON.stringify(customerData)
        }
        console.log(options,"options");
        const response =await fetch(base_url+"api/payment",options);
        console.log(response,"response from backend");
        if(response.ok){
            const responseFormat= await response.json();
            console.log(responseFormat);
            const reqSession={...responseFormat}.result.payment_session_id;
            const unique_order_id={...responseFormat}.result.order_id;
            console.log(reqSession,"sessinss");
            // setSessionId(reqSession);
            return {"sessionId":reqSession,"orderId":unique_order_id};
        }
        else{
        console.log("soe wrong fuck it up")
        }
      
    }
    const handleAmount=(credits)=>{
        if(credits<1000){
            return credits;
        }
        else if(credits<2000){
            return credits-credits*0.1;
        }
        else if(credits<3000){
            return credits-credits*0.2;
        }
        else if(credits<4000){
            return credits-credits*0.3;
        }
        else if(credits<5000){
            return credits-credits*0.4;
        }
        else if(credits>=5000){
            return credits-credits*0.5;
        }
    }
    // const [showReq,setShowReq]=useState(false);

    const handleChange=(amount,index)=>{
        console.log("enyered")
        let currentPlan=plans;
        currentPlan[index].isSelected=!currentPlan[index].isSelected;
        if(currentPlan[index].isSelected){
            let temp=parseInt(totalCost);
            temp+=parseInt(amount);
            setTotalCost(temp)
        }
        else{
            let temp=parseInt(totalCost);
            temp-=parseInt(amount);
            setTotalCost(temp)
        }
        
        setPlans(currentPlan);
    }
    const handleChange2=(prop)=>{
        console.log(prop);
        const {amount,credit}=prop;
        setTotalAmount(amount);
        setTotalCredits(credit);
        console.log(credit,"credit check ");
    }
    const handlePayment=async()=>{ //need session id
        console.log("payment entered");
        if(totalAmount<=0){
            alert("select a valid plan before payment");
            return;
        }
        // if(reqCredit!==0 && reqCredit<=5000){
        //     alert("atleast 5001 credits need to be selected for this plan");
        //     return;
        // }
        // console.log(cashfree);
        // await getSessionId();
        const {sessionId,orderId} = await getSessionId();
        console.log("current session id",sessionId);
        let checkoutOptions = {
            paymentSessionId: sessionId,
            // returnUrl: "http://localhost:8084/api/status/{orderId}",
            returnUrl: base_url+`api/status/${orderId}`,
        }
        console.log(checkoutOptions);
        cashfree.checkout(checkoutOptions).then(function(result){
            if(result.error){
                alert(result.error.message)
            }
            if(result.redirect){
                console.log("Redirection")
                console.log(result);
            }
            if(result.paymentDetails){
                console.log("Payment has been completed, Check for Payment Status");
                console.log(result.paymentDetails.paymentMessage);
            }
        });
    }
    const phonePePayment=async()=>{
        if(totalAmount<=0){
            alert("select a valid plan before payment");
            return;
        }
        console.log(runningBusiness,runningBusiness.businessName);
        const {businessEmail,businessName,businessOwnerNumber}=runningBusiness;
        console.log(runningBusiness.businessEmail,businessEmail);
        const customerData={
            "customerName":businessName || "",
            "cutsomerNumber":businessOwnerNumber || "",
            "customerEmail":businessEmail || "",
            "paymentValue":totalAmount || 0,
            "ivrCredits":totalCredits ||0,
        }
        console.log(customerData);
        const options={
            method:'POST',
            headers:{
                "Content-Type": "application/json",
            },
            body:JSON.stringify(customerData)
        }
        console.log(options,"options");
        const response=await fetch(base_url+"api/phonepe/create-payment",options);
        const responseFormat= await response.json();
        console.log(responseFormat.url,"phonepe url");
        
        window.location.href=responseFormat.url;
        // const paymentResponse= await fetch(responseFormat.url);
        // const paymentResponseFormat= await paymentResponse.json();
        // console.log(paymentResponseFormat);
        
    }
    // useEffect(()=>{
    //     setSessionId(isSessionId);
    // },[isSessionId])

    useEffect(()=>{
        if(totalAmount!==reqCredit*0.5 && reqCredit!==0){
            setTotalAmount(handleAmount(reqCredit));
            setTotalCredits(reqCredit);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reqCredit])
    useEffect(()=>{
        if(activePaymentService===""){
            const getServiceProvider=async()=>{
                const providersRef= doc(db,"service-providers","LX7Vp5RvFLz5wiMZwSXC");
                const providerSnap=await getDoc(providersRef);
                if (providerSnap.exists()) {
                    const providerData = providerSnap.data();
                    setActivePaymentService(providerData.activeProviders.payment);
                    console.log(providerData.activeProviders.payment)
                } else {
                    console.log("No such document!");
                }
            }
            getServiceProvider();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
    <>
            <NewSidebar/>
            <div className="voice-acccount-container">
                <div className='voice-acccount-left'>
                    <div className='voice-credits-card'>
                        {/* <img src="" alt="voice (IVR)" className=''/> */}
                        {/* <FaWhatsapp className='voice-icoo' /> */}
                        <h2 className='credits-card-headers'>Available Credits</h2>
                        <h2>{`${runningBusiness?.ivrCredits}`}</h2>
                        <p className='' style={{"font-size":"22px","font-weight":600}}>Voice IVR</p>
                    </div>
                    <a href="https://web.whatsapp.com/send/?phone=9972968390&text=i have some queries regarding credits" target="_blank_" className='talk-wrapper'>
                        <button className='talk-to-us' >
                            <img src="whatsapp-icon.png" alt="whatsapp-icon" className='whatsapp-icon'/>
                        <p className=''>Talk to Us</p> 
                        </button>
                    </a>
                    
                    
                </div>
                <div className='voice-acccount-right'>
                    <div className=''>
                        <table className='table-style'>
                            <thead>
                                <tr>
                                    <th>Credit</th>
                                    <th>Amount</th>
                                    <th>Select</th>
                                </tr>
                            </thead>
                            <tbody>
                                {plans.map((plan,index)=>{
                                    let amount = plan.amount;
                                    let credit = plan.credit;
                                    return(
                                    <tr key={index}>
                                        <td className='slim-35'>{credit}</td>
                                        <td className='slim-35'>{amount}</td>
                                        <td className='slim-30' >
                                            <label htmlFor={credit} className='inpu-labe' onClick={()=>{handleChange2({amount,credit})}}><input id={credit} type="radio" check={amount===totalAmount} name="plan" className='' /> </label>
                                            
                                        </td>
                                    </tr>)
                                })}
                                <tr className=''>
                                    <td><input type="number" className='' name="plan" onChange={(e)=>{setReqCredit(e.target.value)}} value={reqCredit} /></td>
                                    <td>{handleAmount(reqCredit)}</td>
                                    <td><input type="radio" name="plan"  check={reqCredit*0.5===totalAmount} onClick={()=>{handleChange2({amount:handleAmount(reqCredit),"credit":reqCredit})}} /></td>
                                </tr>
                                <tr>
                                    <td className='slim-70' colspan="2">Total</td>
                                    <td className='slim-30'>{totalAmount}{" /-"}</td>
                                </tr>
                                
                            </tbody>
                        </table>
                            <div className='' style={{textAlign:"start"}}>
                            <p style={{fontStyle:"italic", color: "#555",fontSize: "14px",}}><span style={{color:"red"}}>*Note </span>: 1 rupee for recharges below 1000 credits, 0.5 paise for recharges exceeding 5000 credits.</p>
                            <p style={{fontStyle:"italic", color: "#555",fontSize: "14px",}}><span style={{color:"red"}}>*Note </span>: 1 credit = 27 seconds of IVR</p>
                            </div>
                    </div>
                    <div className='pay-div'>
                        <button className="payment-btn" style={{marginLeft:'46%'}} onClick={()=>{activePaymentService==="cashFree"?  handlePayment():phonePePayment()}}>Make Payment </button>
                        {/* <button className='payment-btn' style={{marginLeft:"46%",marginTop:"20px"}} onClick={()=>{phonePePayment()}}>Phone pe payment</button> */}
                    </div>
                                            
                </div>

            </div>

        </>
    )
}

export default VoiceAccount;