import { MdSettings } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { BiSolidBusiness } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { CgProfile } from "react-icons/cg";
import { VscRequestChanges } from "react-icons/vsc";
import { FaRobot } from "react-icons/fa";
import {useState} from 'react';
import './index.css';

const NewSidebar2=()=>{
    const navigate=useNavigate();
      const sectionData=[{
        header:'Business',path:"/businesses"
    },{header:"BDA",path:""},{header:"Settings",path:''},{header:"New Requests",path:"/business-requests"},
    {header:'Chat Demo',path:"/business-chats"},{header:"Account",path:"/businesses-account"}]
    const listOfTitles=sectionData.length;
    const [activeTab,setActiveTab]=useState("");
    const [sectionVisibilities, setSectionVisibilities] = useState(Array(listOfTitles.length).fill(false));
    const toggleVisibility = (index) => {
        setSectionVisibilities((prevVisibilities) => {
          const updatedVisibilities = [...prevVisibilities];
          updatedVisibilities[index] = !prevVisibilities[index]; // Toggle state for clicked list
          return updatedVisibilities;
        });
      };
      const icons=[<IoMdHome />,<BiSolidBusiness />,<MdSettings/>,<VscRequestChanges />,<FaRobot />,<CgProfile />] 
    return(
        <>
            <div className="new-sidebar-container">
        {/* <div className=''> */}
        {sectionVisibilities&&
        sectionData.map((section,index)=>{
            return(
                <>
                <span className='new-sidebar-options' onClick={()=>toggleVisibility(index)}>
                    <div className='sider'>
                        {icons[index]}
                        <h4 style={{cursor:"pointer"}} onClick={()=>{navigate(section.path)}}>{section.header}</h4>
                    </div>
                        {/* {!sectionVisibilities[index]?<RiArrowDropDownLine className='arrowDrop' />:
                        <RiArrowDropUpLine className='arrowUp'/>} */}
                </span>
                {sectionVisibilities[index]&& section.subHeaders && (<span className="section-data" >
                    {section.subHeaders.map((subheader,inde)=>
                    <>
                        <h3 className={`${section-subheader} ${activeTab===subheader.path && 'showHigh'}`} onClick={()=>{navigate(`${subheader.path || '/'}`);setActiveTab(`${subheader.path}`)}}>{subheader?.title}</h3>
                    </>)}
                </span>)
                }
                </>
            )})
    }
    </div>
        </>
    )
}

export default NewSidebar2;