import { useEffect, useState } from "react";
import { cashfree } from "./util";
import { useParams } from "react-router-dom";
import { get } from "mongoose";

const CashFree=()=>{
    const [sessionId,setSessionId]=useState();
    const {isSessionId}=useParams();

    const getSessionId=async(e)=>{
        e.preventDefault();
        const response =await fetch("api/payment",{
            method:"POST",
        });
        if(response.ok){
            const responseFormat= await response.json();
            console.log(responseFormat);
            setSessionId(responseFormat.data);
        }
      
    }

    const handlePayment=()=>{
        let checkoutOptions = {
            paymentSessionId: sessionId,
            returnUrl: "http:localhost:8084/api/status/{order_id}",
            
        }
        cashfree.checkout(checkoutOptions).then(function(result){
            if(result.error){
                alert(result.error.message)
            }
            if(result.redirect){
                console.log("Redirection")
                console.log(result);
            }
        });
    }
    useEffect(()=>{
        setSessionId(isSessionId);
    },[isSessionId])

    return(
        <div className="">
            <form onSubmit={()=>{getSessionId()}}>
                <h1>get session id</h1>
                <input type="text" value={sessionId} onChange={(e)=>{setSessionId(e.target.value)}} />
                <button type="submit">get session id btn</button>
            </form>
            <button onClick={()=>{handlePayment()}}>Pay Now</button>
        </div>
    )
}

export default CashFree;