import React, { useContext } from 'react';
import { Navigate,Outlet, useNavigate } from 'react-router-dom';
// import Cookies from 'js-cookie';
import { dataContext } from '../../Context/data';

const ProtectedRoute2 = ({ children, ...rest }) => {
  const {isAdmin}=useContext(dataContext);
  // const navigate=useNavigate();
  // if(! isAdmin){
  //   navigate('/');
  // }
//   const businessData= Cookies.get('userData');
//  return businessData? <Outlet/>:<Navigate to="/login" />
return isAdmin? <Outlet/>:<Navigate to="/" />

};

export default ProtectedRoute2;
