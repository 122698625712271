import NewSidebar from "../NewSidebar";
import "./index.css";

const ChatbotIntegration=()=>{

    return(
        <>
        <NewSidebar/>
        <div className='recordingusers-container'>
            <div className='voicecampaign-action-bar'>
                <span className="action-gap">
                    <h3 className="action-title">Chat Bot{` > Integration`}</h3>
                    <p className="action-text">Total Chat Bots : </p>
                </span>
            
                {/* <button className="voicecampaignAddBtn"><h3>Add</h3> <FaPlus className="plus-icon" /></button> */}
            </div>
        </div>
        </>
    )
}

export default ChatbotIntegration;