import { FaRegEdit } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { AiFillDelete } from "react-icons/ai";
import { addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../../Config-firebase/firebase';
import './index.css'
import { useState,useEffect, useContext } from "react";
import axios from 'axios';
// import Cookies from 'js-cookie';
import DeleteItem from "../DeleteItem";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
// import cloudinary from'../../Config-firebase/cloudinary'
import NewSidebar from "../NewSidebar";
import { base_url } from "../../utils/new";
import { dataContext } from "../../Context/data";

const Recording=()=>{
    const [showModal,setShowModal]=useState('');
    const [recordingsData,setRecordingsData]=useState(null);
    const [val,setVal]=useState(0);
    const [voiceName,setVoiceName]=useState('');
    const [voiceId,setVoiceId]=useState('');
    const [description,setDescription]=useState('');
    const [myFile,setMyFile]=useState('');
    const [currentEditingId,setCurrentEditingId]=useState("");
    const {runningBusiness}=useContext(dataContext);
    // const [req,setReq]=useState("");

     // Function to calculate audio duration
  const getAudioDuration = async (file) => {
    return new Promise((resolve, reject) => {
      const audioElement = document.createElement('audio');
      const objectUrl = URL.createObjectURL(file);
      audioElement.src = objectUrl;

      audioElement.onloadedmetadata = () => {
        URL.revokeObjectURL(objectUrl);
        resolve(audioElement.duration);
      };

      audioElement.onerror = (error) => {
        reject(error);
      };
    });
  };
    
    const uploadAudioFile = async (file) => {
        console.log(file.name, ': Uploading to AWS...');
        
        const formData = new FormData();
        formData.append('audioFile', file);
        let duration= await getAudioDuration(file);
        console.log(duration);
        const response = await axios.post(base_url + 'api/getaudiourl', formData, {
            headers: {
            "Content-Type": "multipart/form-data"
            }
        });

        console.log(response,"response from frontend");
        const result=response.data;
        console.log(result,"result from frontend");
        if (response.status===200) {
            return {reqUrl:result.objectUrl,duration};
        }
        else{
            console.log(result.msg)
            return "";
        }
        
      };

    const handleAddItem=async()=>{
        const campaignDetailsRef= await collection(db,"voice-recording");

        if(!voiceName){
            alert("enter a valid voice name");
            return ;
        }
        else if(! voiceId){
            alert("enter a valid clipId");
            return;
        }   
        else if(! description){
            alert("enter a valid duration");
            return;
        }
        else if(!myFile){
            alert("enter a valid file");
            return;
        }

        const validTypes = /^audio\//; // Regular expression to match any audio type

        const fileType = myFile.type;

        if (!validTypes.test(fileType)) {
            alert(`Please upload a valid audio file (MP3, WAV, etc.)`);
            return;
        }

        const {reqUrl,duration}=await uploadAudioFile(myFile) ;

        console.log(reqUrl)
        if(! reqUrl){
            return;
        }
        if(duration>30){
            alert("file duration is greater than 30 seconds");
            return;
        }
        // let reqUrl="boom";
        console.log(reqUrl,"ippppppppp");
        const todayDate = new Date();
        const yyyy = todayDate.getFullYear();
        let mm = todayDate.getMonth() + 1; 
        let dd = todayDate.getDate();
        const hour = todayDate.getHours();
        const minute = todayDate.getMinutes();
        const second = todayDate.getSeconds();

        const hourString = hour.toString().padStart(2, "0");
        const minuteString = minute.toString().padStart(2, "0");
        const secondString = second.toString().padStart(2, "0");

        mm = String(mm).padStart(2, '0'); 
        dd = String(dd).padStart(2, '0');

        const formattedDate = dd + '-' + mm + '-' + yyyy+"  "+hourString+":" +minuteString+ ":"+secondString;
        const newCampaign={date:formattedDate,voiceName,voiceId,description,audioUrl:reqUrl,'businessName':runningBusiness.businessName,duration}
        await addDoc(campaignDetailsRef,newCampaign);
        setVoiceName("");
        setVoiceId("");
        setDescription("");
        setMyFile("");
        setVal(e=>e+1);
        setShowModal('');
    }

    const editRecord=async(props)=>{

        const {name,description,voiceId,id}=props;
      
        setCurrentEditingId(id);
        setVoiceName(name);
        setDescription(description);
        setVoiceId(voiceId);
        setShowModal('edit');
    }
    const deleteRecord=async(id)=>{
        const reqDoc= doc(db,"voice-recording",id);
        const response=await deleteDoc(reqDoc);
        console.log(response);
        setVal(e=>e+1);
    }
    const handleEditItem=async()=>{
        const userRef= doc(db,"voice-recording",currentEditingId);

        if(!voiceName){
            alert("enter voice name");
            return ;
        }
        else if(! voiceId){
            alert("enter a valid clipId");
            return;
        }   
        else if(!description){
            alert("enter description");
            return;
        }
        const updatedGroup={voiceId,voiceName,description}
        await updateDoc(userRef,updatedGroup);
        setVoiceName("");
        setDescription("");
        setVoiceId("");
        setCurrentEditingId("");
        setVal(e=>e+1);
        setShowModal('');
    }
    useEffect(()=>{
        // const businesData=Cookies.get('currentBusiness');
        // setReq(JSON.parse(businesData));
        // console.log(req);   
       
        // if(!req?.businessName){
        //     const businesData=Cookies.get('currentBusiness');
        // setReq(JSON.parse(businesData));
        // console.log(req);  
        // }

        const getRecordingData=async()=>{
            // if (!req?.businessName) {
            //     console.warn('Business name not available yet. Skipping data fetching.');
            //     return; // Exit early if businessName is undefined
            //   }

            const usersRef= collection(db,"voice-recording");
           const dataRef=query(usersRef,where('businessName','==',runningBusiness?.businessName))
            const response= await getDocs(dataRef);
            const reponseFormat= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            setRecordingsData(reponseFormat);
        }
        getRecordingData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[val])
    const [deletingId,setDeletingId]=useState("");

    return(
        <>
        <NewSidebar/>
        <div className='recordingusers-container'>
            <div className='voicecampaign-action-bar'>
                <span className="action-gap">
                    <h3 className="action-title">Voice( IVR ){` > Recording`}</h3>
                    <p className="action-text">Total Recordings : {recordingsData?.length}</p>
                </span>
            
            <button className="voicecampaignAddBtn" onClick={()=>{setShowModal('add')}}><h3>Add</h3> <FaPlus className="plus-icon" /></button>
        </div>
            <table className='table-style'>
                <thead>
                    <tr>
                        <th className='slim'>S.no</th>
                        <th className='slim'>Uploaded Date</th>
                        <th className='slim'>Voice Name</th>
                        <th className="slim">Voice ID</th>
                        <th className='slim-2'>Uploaded Voice</th>
                        <th className='slim'>Duration</th>
                        <th className="">Description</th>
                        <th className='slim'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {recordingsData && 
                    recordingsData.map((record,index)=>{
                        // console.log(record,"hi");
                        const name = record.voiceName;
                        const voiceId=record.voiceId;
                        const description = record.description;
                        const id=record.id;
                        const audioUrl=record.audioUrl;
                        return (
                            <tr key={index}>
                                <td className=''>{index+1}</td>
                                <td className=''>{record.date}</td>
                                <td className=''>{record.voiceName}</td>
                                <td className="">{record.voiceId}</td>
                                <td className=''><AudioPlayer
                                                src={audioUrl}
                                                onPlay={e => console.log("onPlay")}
                                                loop='false'
                                            />
                                            {/* {record.link || "https://google.com"} */}
                                </td>
                                <td className=''>{record.duration || " < 30"} (sec)</td>
                                <td className="">{record.description}</td>
                                <td className='row-update'>
                                    <FaRegEdit className="update-row" onClick={()=>{editRecord({name,voiceId,description,id})}} />
                                    <AiFillDelete className="delete-row" onClick={()=>{setDeletingId(record.id)}} />
                                </td>
                            </tr>
                        )})}
                    
                </tbody>
            </table>
            {showModal && 
        <div className='campaignModalContainer'>

            <div className='campaignForm3'>
                <div className='modal-header'>
                    <h2 style={{color:"#363534",marginLeft:'17px'}}>{showModal[0].toUpperCase()+showModal.slice(1)} Recording</h2>
                    <ImCross style={{cursor:"pointer"}} onClick={()=>{setShowModal('')}} />
                </div>
                <hr/>
                <label className="labels">Voice Name</label>
                <input type="text" className="campaignInput" placeholder="Enter Voice name" value={voiceName} onChange={(e)=>{setVoiceName(e.target.value)}} />
                <label className="labels">Voice Id</label>
                <input type="text" className="campaignInput" placeholder="Enter Voice id" value={voiceId} onChange={(e)=>{setVoiceId(e.target.value)}} />
                {showModal==="add"&&<><label className="labels">Upload Audio</label>
                <input type="file" onChange={(e)=>{setMyFile(e.target.files[0])}} style={{marginBottom:"10px"}} /></>}
                <label className="labels">Description</label>
                <input type="text" className="categoryInput" placeholder="Enter description" value={description} onChange={(e)=>setDescription(e.target.value)} />
                <button className="modal-submit" onClick={()=>{showModal==="add"?handleAddItem():handleEditItem()}}>Submit</button>
            </div>

        </div>}
        {deletingId && <DeleteItem id={deletingId} handleClose={setDeletingId} handleDelete={deleteRecord} />}
        </div>
        </>
    )
}

export default Recording;