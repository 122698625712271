import { useEffect, useState } from 'react';
import NewSidebar from '../NewSidebar';
import './index.css';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../../Config-firebase/firebase';

const ProviderOptions=()=>{
    const [providersData,setProvidersData]=useState(null);
    
    const fetchProviders=async()=>{
        const providersRef= collection(db,"service-providers");
        const response=await getDocs(providersRef);
        const responseData=response.docs.map((doc)=>({...doc.data(),id:doc.id}));
        console.log(responseData,"service-providers-fetched");
        setProvidersData(responseData[0]);
    };
    const handleServiceProvider = (e,service) => {
        console.log(e.target.value,service,"heree");
        setProvidersData({
            ...providersData,
            activeProviders: {
                ...providersData.activeProviders,
                [service]: e.target.value
            }
        });
    };
    const handleProviderChanges = async()=>{
        const providersRef= doc(db,"service-providers","LX7Vp5RvFLz5wiMZwSXC");//chnage as per firestoreid
        await updateDoc(providersRef,providersData);
        alert("service providers updated refresh once");
    }
    useEffect(()=>{
        if(providersData===null){
            fetchProviders();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
    <>
    <NewSidebar/>
    <div className='recordingusers-container'>
            <div className='voicecampaign-action-bar'>
                <span className="action-gap">
                    <h3 className="action-title">Admin{` > Manage Service Providers`}</h3>
                    <p className="action-text">Total</p>
                </span>
            </div>

        <div className="provider-container">
            <div className='available'>
            <h1>Available Service Providers</h1>
            <div className='provider-format'>
                <h3 className=''>IVR Service Providers</h3>
                <select  onChange={(e) => handleServiceProvider(e, "ivr")} value={providersData?.activeProviders?.ivr}>
                    {providersData?.ivr?.map((service,idx)=>
                    <option value={service.company} key={idx}>{service.company}</option>
                )}
                </select>

            </div>
            <div className='provider-format'>
                <h3 className=''>SMS Service Providers</h3>
                <select onChange={(e) => handleServiceProvider(e, "sms")}value={providersData?.activeProviders?.sms}>
                    {providersData?.sms?.map((service,idx)=>
                    <option value={service.company} key={idx}>{service.company}</option>
                )}
                </select>

            </div>
            <div className='provider-format'>
                <h3 className=''>Email Service Providers</h3>
                <select onChange={(e) => handleServiceProvider(e, "email")} value={providersData?.activeProviders?.email}>
                    {providersData?.email?.map((service,idx)=>
                    <option value={service.company} key={idx}>{service.company}</option>
                )}
                </select>

            </div>
            <div className='provider-format'>
                <h3 className=''>Whatsapp Service Providers</h3>
                <select onChange={(e) => handleServiceProvider(e, "whatsapp")} value={providersData?.activeProviders?.whatsapp}>
                    {providersData?.whatsapp?.map((service,idx)=>
                    <option value={service.company} key={idx}>{service.company}</option>
                )}
                </select>
            
            </div>
            <div className='provider-format'>
                <h3 className=''>Reels Service Providers</h3>
                <select onChange={(e) => handleServiceProvider(e, "reels")} value={providersData?.activeProviders?.reels} className="">
                    {providersData?.reels?.map((service,idx)=>
                    <option value={service.company} key={idx}>{service.company}</option>
                )}
                </select>

            </div>
            <div className='provider-format'>
                <h3 className=''>Posts Service Providers</h3>
                <select onChange={(e) => handleServiceProvider(e, "posts")} value={providersData?.activeProviders?.posts}>
                    {providersData?.posts?.map((service,idx)=>
                    <option value={service.company} key={idx}>{service.company}</option>
                )}
                </select>

            </div>
            <div className='provider-format'>
                <h3 className=''>Payment Service Providers</h3>
                <select onChange={(e) => handleServiceProvider(e, "payment")} value={providersData?.activeProviders?.payment}>
                    {providersData?.payment?.map((service,idx)=>
                    <option value={service.company} key={idx}>{service.company}</option>
                )}
                </select>

            </div>
            </div>

            <div className='active'>
                <h1>Active Service Providers</h1>
                <div className=""><h3>IVR</h3><p>{providersData?.activeProviders?.ivr}</p></div>
                <div className=""><h3>SMS</h3><p>{providersData?.activeProviders?.sms}</p></div>
                <div className=""><h3>Email</h3><p>{providersData?.activeProviders?.email}</p></div>
                <div className=""><h3>Whatsapp</h3><p>{providersData?.activeProviders?.whatsapp}</p></div>
                <div className=""><h3>Posts</h3><p>{providersData?.activeProviders?.posts}</p></div>
                <div className=""><h3>Reels</h3><p>{providersData?.activeProviders?.reels}</p></div>
                <div className=""><h3>Payment</h3><p>{providersData?.activeProviders?.payment}</p></div>
            </div>
        </div>
        <button className='provider-save' style={{marginLeft:'80%',paddingLeft:"17px",paddingRight:"17px"}} onClick={()=>{handleProviderChanges()}}>Save</button>
    </div>
    </>
    )
}

export default ProviderOptions;