import { ImCross } from "react-icons/im";
import './index.css';

const DeleteItem=(props)=>{
    const {id,handleDelete,handleClose}=props;
    const confirmDelete=(id)=>{
        handleDelete(id);
        handleClose("");
    }
    
    return(
        <div className="deleteModalContainer">
            <div className='deleteForm'>
            <div className='delete-cross'>
                    <ImCross style={{cursor:"pointer"}} onClick={()=>{handleClose('')}} />
                </div>
                <hr/>
                <h3 className=''>Are you sure do you want to delete? </h3>
                <div className="handlingBtns">
                    <button className='confirmDelete' onClick={()=>{confirmDelete(id)}}> Confirm</button>
                    <button className='cancelDelete' onClick={()=>{handleClose("")}}>Cancel</button>
                </div>
                
            </div>
        </div>
    )

}

export default DeleteItem;