import {useCallback, useContext, useEffect, useState} from 'react'; 
import { MdDownloadForOffline } from "react-icons/md";
import './index.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import AudioPlayer from 'react-h5-audio-player';
import { addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../../Config-firebase/firebase';
import NewSidebar from '../NewSidebar';
import { dataContext } from "../../Context/data";
import Cookies from 'js-cookie';

const VoiceReport=()=>{
    const [usersCount,setUsersCount]=useState([]);
    const [reportsData,setReportsData]=useState(null);
    let {runningBusiness}=useContext(dataContext);
    // runningBusiness=JSON.parse(runningBusiness);
    // const reqbusiness=runningBusiness.businessName;
    const reqbusiness2=Cookies.get('currentBusiness');
    useEffect(()=>{
        
        const getReportsData=async()=>{

            const campaignsRef= collection(db,"campaign-callback");
            const reportQuery=query(campaignsRef,where('business_name','==',runningBusiness?.businessName));
            console.log('business_name',reqbusiness2?.businessName);
            const response= await getDocs(reportQuery);
            const reponseFormat= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            reponseFormat.sort((a,b)=>{
                const dateA = new Date(a.campaign_time); // Convert strings to Date objects
                const dateB = new Date(b.campaign_time);
                return dateB - dateA; // Descending order (recent first)
            });
            // setUsersCount(reponseFormat.length);
            setReportsData(reponseFormat);
        }
        // if(reqbusiness2 && reportsData.length()===0){
            getReportsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    const generatePDF = async (data) => {
        const {users,campaignId,campaignName,campaignTime}=data;
        let requiredData=users.map(dat=>({number:dat.number,status:dat.status,extention:dat.extention}))
        const doc = new jsPDF();
        const docWidth = doc.internal.pageSize.getWidth();
        const margin = 20;
        const normalFontSize = 12;
        const lineHeight = 10;
        const headerFontSize = 17;
        const headerHeight = margin + headerFontSize + lineHeight * 4;
        // const rowHeight = 10;
    
        // Header
        doc.setFontSize(headerFontSize);
        doc.text('IVR Delivery Report', margin, margin + headerFontSize);
        // doc.text(`Campaign Id:${campaignId},Campaign Name:${campaignName},Campaign Time:${campaignTime}`)
        

        doc.setFontSize(normalFontSize);
        doc.text(`Campaign ID: ${campaignId}`, margin, margin + headerFontSize + lineHeight);
        doc.text(`Campaign Name: ${campaignName}`, margin, margin + headerFontSize + lineHeight * 2);
        doc.text(`Campaign Time: ${campaignTime}`, margin, margin + headerFontSize + lineHeight * 3);
        doc.line(margin, margin + headerFontSize + 5, docWidth - margin, margin + headerFontSize + 5);
      
      
    
        // Column definitions (adjust column widths as needed)
        const columns = [
          { title: 'Number', dataKey: 'number', width: docWidth / 3 },
          { title: 'Status', dataKey: 'status', width: docWidth / 3 },
          { title: 'Extension', dataKey: 'extention', width: docWidth / 3 },
        ];
    
        // Table generation using jsPDF-autotable
        const tableProps = {
          startY: headerHeight + 4,
          theme: 'grid',
          columnStyles: { cellPadding: 5 },
          columns,
        };
        doc.autoTable(columns, requiredData, tableProps);
        doc.save('IVRVoicesDeliveryReport.pdf');
       };

       const getSuccess=(users)=>{
        console.log(users,"report");
            let data=users.filter(user=>user.status==='ANSWERED');
            return data.length;
       }
    return(
        <>
        <NewSidebar/>
        <div className='recordingusers-container'>
            <div className='voicecampaign-action-bar'>
                <span className="action-gap">
                    <h3 className="action-title">Voice( IVR ) {`> Reports` }</h3>
                    <p className="action-text">Total Reports : {reportsData?.length}</p>
                </span>
            
        </div>
            <table className='table-style'>
                <thead>
                    <tr>
                        <th className='slim'>S.no</th>
                        <th className='slim'>Campaign Name</th>
                        <th className='slim'>Campaign Time</th>
                        <th className=''>Voice Clip</th>
                        <th className=''>Voice Id</th>
                        <th className='slim'>Total Users</th>
                        <th className='slim'>Status</th>
                        <th className='slim'>Download Report</th>
                    </tr>
                </thead>
                <tbody>
                    {reportsData &&
                    reportsData.map((report,index) =>{
                        return(
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{report.campaign_name}</td>
                                <td>{report.campaign_time}</td>
                                <td>
                                <AudioPlayer
                                    src={`${report.audio_url}`}
                                    onPlay={e => console.log("onPlay")}
                                    loop='false'
                                />
                                </td>
                                <td>{report.voice_id}</td>
                                <td>{report.users.length}</td>
                                <td><span style={{color:"green"}}>{getSuccess(report.users)}</span>{' | '}<span style={{color:"red"}}>{report.users.length-getSuccess(report.users)}</span></td>
                                <td >
                                    <MdDownloadForOffline className='download-icon' 
                                    onClick={()=>{generatePDF({users:report.users,campaignName:report.campaign_name,campaignTime:report.campaign_time,campaignId:report.campaign_id})}} 
                                    style={{cursor:"pointer",height:"24px",width:"28px"}} />
                                </td>
                            </tr>
                        )})}
                    
                </tbody>
            </table>
       
        </div></>
    )
}   

export default VoiceReport;