import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { ImCross } from "react-icons/im";
import { IoCloseSharp } from "react-icons/io5";

import './index.css'; 
import { useContext, useEffect, useState} from 'react';
import { auth, db, googleAuth } from '../../Config-firebase/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { MdMenu } from "react-icons/md";

import { dataContext } from '../../Context/data';

const FooterOutlet=()=>{
    const navigate=useNavigate();
    const [authUsers,setAuthUsers]=useState(null);
    const [authSubadmins,setAuthSubadmins]=useState(null);
    const [showModal,setShowModal]=useState(false);
    // const [name,setName]=useState("");
    const [number,setNumber]=useState("");
    // const [category,setCategory]=useState("");
    const [address,setAddress]=useState("");
    // const [email,setEmail]=useState("");
    const [businessName,setBusinessName]=useState("");
    const [signInModal,setSignInModal]=useState(false);
    const [isPlaying,setIsPlaying]=useState(false);
    const [showDrawer,setShowDrawer]=useState(false);
    // const videoRef=useRef();
    const footerItems=[
        {header:"Wizzmedia",subHeaders:[{title:"About Us",path:""}]},
        {header:"Office",subHeaders:[{title:"Head Office",path:""},{title:"Branch Office",path:""},]},
        {header:"Quick Links",subHeaders:[{title:"Blog",path:""},{title:"Admin",path:""},{title:"Careers",path:""}]},
        {header:"Legal Stuff",subHeaders:[{title:"Privacy Policy",path:""},{title:"Terms of Service",path:""},{title:"Refunds",path:""},{title:"Disclaimer",path:""},]},
    ]

    const {setCurrentUser,currentUser,setRunningBusiness,setIsAdmin}=useContext(dataContext);
    useEffect(()=>{
        const getAuthorizedUsers=async()=>{
            const authorizedUsersRef= collection(db,"authorized-users");
           const response=await getDocs(authorizedUsersRef);
           const reponseFormate= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
           const emails=reponseFormate.map(r=>r.email);
          //  console.log(emails);
           setAuthUsers(emails);
        }
        getAuthorizedUsers();
        const getAuthorizedSubAdmins=async()=>{
          const authorizedSubAdminsRef= collection(db,"authorized-subadmins");
           const response=await getDocs(authorizedSubAdminsRef);
           const reponseFormate= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
           const emails=reponseFormate.map(r=>r.email);
          //  console.log(emails);
           setAuthSubadmins(emails);
        }
        getAuthorizedSubAdmins();
        if(currentUser!=="" && authSubadmins!==null && authUsers!==null){
            if(authSubadmins.includes(currentUser.email)|| authUsers.includes(currentUser.email)){
                setIsAdmin(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentUser])

    const businessLogin=async(bUser)=>{
            console.log("entered business");
            const businessCollectionRef=collection(db,"business-users");
            const response= await getDocs(businessCollectionRef);
            const reponseFormat= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            console.log(reponseFormat);
            const requiredBusinessUsers=reponseFormat.filter(b=>b.email===currentUser.email);
            console.log(requiredBusinessUsers);
            if(requiredBusinessUsers.length===0){
                alert("no businesses found create one");
                return;
            }
            else{
                console.log("entered business2");
                const businessRef=collection(db,"registered-businesses");
                console.log('id',requiredBusinessUsers[0].businessId,requiredBusinessUsers[0]);
                const businessQuery=query(businessRef,where('businessName','==',requiredBusinessUsers[0].businessName));
                const businessDat=await getDocs(businessQuery);
                const businessData= businessDat.docs.map((doc)=>({...doc.data(),id:doc.id}));
                console.log(businessData);
                console.log("entered business3");
                if(businessData.length!==0){
                    console.log(businessData);
                    Cookies.set('currentBusiness', JSON.stringify(businessData[0] ));
                    setRunningBusiness(businessData[0]);
                    console.log("success");
                    navigate('/about',{replace:true});
                }
                else{
                    console.log(businessData);
                    alert("error124355");
                }  
            }
                  console.log("closed");
              }

    const handleGoogleLogin = async () => {
    
        try {
          const result = await signInWithPopup(auth, googleAuth);
         
          const credential =GoogleAuthProvider.credentialFromResult(result);
        //   setGoogleUser({name:result.user.displayName,email:result.user.email,uid:result.user.uid})
          console.log(credential,credential.accessToken);
          console.log(result.user,"user");
          
          if (result.user) {
          
            console.log(result.user);
                Cookies.set('userData', JSON.stringify(result.user),{expires:10});
                setCurrentUser(result.user);
                console.log("success");
          }
          else{
            alert("some issues")
            return;
          }
        } catch (error) {
          alert("not a valid user");
          
          console.log(error);
          return;
        }
      };
    
    const handleBusiness=async()=>{
        const useData=Cookies.get("userData");
        const businessData=Cookies.get("currentBusiness");
        if(businessData){
            navigate('/about',{replace:true});
            return;
        }
        if(useData){
            await businessLogin(useData);
            return;
            // Cookies.remove("userData")
        }
        await handleGoogleLogin();
        if(useData){
            await businessLogin(useData);
            return;
            // Cookies.remove("userData")
        }
    }
    const h3Style = {
        fontSize: '1.31em', /* Typical font-size for h3 */
        fontWeight: 'bold', /* h3 is typically bold */
        marginTop: '1em', /* Top margin for spacing */
        marginBottom: '1em', /* Bottom margin for spacing */
        lineHeight: '1.25', /* Adjust line-height for readability */
        cursor: 'pointer', /* Add cursor pointer to indicate clickability */
    };

    
    return(
        <>
        
            {<div className="navbar-landing">
                <div className='navbar-left'>
                    <p onClick={()=>{navigate('/')}} style={h3Style}>Wizzmedia</p>
                    <p onClick={()=>{navigate('/')}}>Home</p>
                    <p>About</p>
                    <p onClick={()=>{setIsPlaying(p=>!p)}}>Business</p>
                </div>
                <div  className='navbar-right'>
                    <p>Help</p>
                    <button className='landing-signup-btn' onClick={()=>{setShowModal(true)}}>Sign Up</button>
                </div>
            </div>}
            <div className="navbar-landing-mobile">
                <div className='navbar-left'>
                <p onClick={()=>{navigate('/')}} style={h3Style}>Wizzmedia</p>
                </div>
                <div  className='navbar-right'>
                    {/* <p>Help</p> */}
                    <button className='landing-signup-btn' onClick={()=>{setShowModal(true)}}>Sign Up</button>
                    {showDrawer?<ImCross onClick={()=>{setShowDrawer(false)}} className='navbar-mobile-menu-icon' />:<MdMenu onClick={()=>{setShowDrawer(true)}} className='navbar-mobile-menu-icon' />}
                </div>
            </div>
            
       
        {showModal&&
            <div className='signUpForm'>
                <div className='signUpInner'>
                    <div className='modal-header'>
                        <h2 style={{color:"#363534",marginLeft:'0px',fontSize:"33px"}}>Sign Up</h2>
                        <ImCross style={{cursor:"pointer"}} onClick={()=>{setShowModal('')}} />
                    </div>
                    <hr/>
                    {/* <label className="labels"> Name</label>
                    <input type="text" className="landingInput" placeholder="Enter your name" value={name} onChange={(e)=>{setName(e.target.value)}} /> */}
                     {/* <label className="labels">Business Name</label> */}
                    <input type="text" className="landingInput" placeholder="Enter Business name" value={businessName} onChange={(e)=>{setBusinessName(e.target.value)}} />
                    
                    {/* <label className="labels">Mobile Number</label> */}
                    <input type="text" className="landingInput" placeholder="Enter Business number" value={number} onChange={(e)=>setNumber(e.target.value)} />
                    {/* <label className="labels">City</label> */}
                    <input type="text" className="landingInput" placeholder="Enter City" value={address} onChange={(e)=>setAddress(e.target.value)} />
                    {/* <label className="labels">Google Login</label> */}
                    <div className='google-signup-container'>
                        <img src="google.png" alt="google" className='signup-google-image'/>
                        <p>Continue With Google</p>
                    </div>
                    <a href={`https://web.whatsapp.com/send/?phone=9972968390&text=I would like to learn more about Wizzmedia Marketing Services. My name is ${businessName}`} target="_blank_" className='talk-wrapper google-signup-container'  style={{marginTop:'7px'}}>
                            <img src="whatsapp-icon.png" alt="google" className='signup-google-image'/>
                            <p>Talk to Us</p>
                    </a>

                </div>
            </div>
        }
        {signInModal&&
            <div className='signInForm'>
                <div className='signInInner'>
                    <div className='modal-header'>
                        <h2 style={{color:"#363534",marginLeft:'0px',fontSize:"33px"}}>Sign In</h2>
                        <ImCross style={{cursor:"pointer"}} onClick={()=>{setSignInModal('')}} />
                    </div>
                    <hr/>
                    <div className='google-signIn-container' onClick={()=>{handleBusiness()}}>
                        <img src="google.png" alt="google" className='signup-google-image'/>
                        <p>Continue With Google</p>
                    </div>
                    <a href="https://web.whatsapp.com/send/?phone=9972968390&text=there is a problem with my sign in my business name is " target="_blank_" className='talk-wrapper google-signIn-container' style={{marginTop:'8px'}}>
                            <img src="whatsapp-icon.png" alt="google" className='signup-google-image'/>
                            <p>Talk to Us</p>
                    </a>
                </div>
            </div>
        }
        {isPlaying &&
            <div className="landingbusiness-video-container">
                <div className='video-container'>
                    <div className='video-top'>
                        <IoCloseSharp className='close-video-icon' onClick={()=>{setIsPlaying(false)}} />
                    </div>
                    <video controls className='wiz-video'autoPlay={isPlaying}>
                    <source src="https://res.cloudinary.com/dswjg0rjx/video/upload/v1720031516/Wizmedia-video_sb0cmi.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                </div>
          </div>
        }
        {
            showDrawer &&
            <div className={`drawer-container ${showDrawer ? 'show-drawer' : ''}`}>
                <div className='drawer-items'>
                    <p>Home</p>
                    <p>About</p>
                    <p onClick={()=>{setShowDrawer(false);setIsPlaying(p=>!p)}}>Business</p>

                </div>
            </div>
        }
        </>
    )
}

export default FooterOutlet;