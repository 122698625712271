import {getDocs,collection,query,where, addDoc, doc,  getDoc, deleteDoc, updateDoc} from 'firebase/firestore';
import { FaRegEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { FaPlus } from "react-icons/fa6";
import { useParams } from 'react-router-dom';
import './index.css';
import { useContext, useEffect, useState } from "react";
import { db } from "../../Config-firebase/firebase";
import DeleteItem from '../DeleteItem';
import NewSidebar from '../NewSidebar';
import AudioPlayer from 'react-h5-audio-player';
import { base_url } from '../../utils/new';
import { dataContext } from '../../Context/data';

const VoiceCampaignDetails=()=>{
    const [campaignData,setCampaignData]=useState([])
    const [currentCampaign,setCurrentCampaign]=useState(null);
    const [showModal,setShowModal]=useState('');
    const [currentVoice,setCurrentVoice]=useState("");
    const [currentAudio,setCurrentAudio]=useState('');
    const [currentGroup,setCurrentGroup]=useState("");
    const [recordingsData,setRecordingsData]=useState(null);
    const [groupsData,setGroupsData]=useState(null);
    const { campaignId } = useParams();
    const [currentEditingId,setCurrentEditingId]=useState("");
    const {runningBusiness}=useContext(dataContext);
    
    // const [currentCampaignId,setCurrentCampaignId]=useState('');
    const [val,setVal]=useState(0);

    useEffect(()=>{

        const getRecordingData=async()=>{

            const recordsRef= collection(db,"voice-recording");
            const recordQuery= query(recordsRef,where("businessName","==",runningBusiness.businessName));
            const response= await getDocs(recordQuery);
            const reponseFormat= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            setRecordingsData(reponseFormat);
        }
        getRecordingData();

        const getGroupsData=async()=>{

            const groupsRef= collection(db,"voice-recording-groups");
            const groupsQuery= query(groupsRef,where("businessName","==",runningBusiness.businessName));
            const response= await getDocs(groupsQuery);
            const reponseFormat= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            // setUsersCount(reponseFormat.length);
            setGroupsData(reponseFormat);
        }
        getGroupsData();
        
        const getCampaingnData=async()=>{
            const campaignRef3 = doc(collection(db, "voice-campaign"), campaignId); 
            const campaignSnapshot = await getDoc(campaignRef3);
            if (campaignSnapshot.exists) {
            const campaignData = campaignSnapshot.data();
            console.log(campaignData,"esgthyuk")
            campaignData.id = campaignSnapshot.id; 
            setCurrentCampaign(campaignData);
            } 

            const campaignDetailsRef= collection(db,"voice-campaign-members");
            const campaignQuery= query(campaignDetailsRef,where('campaignId','==',campaignId));
            const response= await getDocs(campaignQuery);
            const reponseFormat= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            setCampaignData(reponseFormat);
        }
        getCampaingnData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[val])
    useEffect(()=>{
        if(recordingsData){
            // console.log(recordingsData,"recordings dtaa");
            // console.log(currentVoice);
            const reqRecord=recordingsData.filter(r=>r.voiceName.trim() ===currentVoice.trim());
        setCurrentAudio(reqRecord[0]?.audioUrl);
        // console.log(reqRecord);
        // console.log(reqRecord[0]?.audioUrl);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentVoice])

    
    const [name,setName]=useState("");
    const [number,setNumber]=useState("");
    const [group,setGroup]=useState("");

    const handleAddItem=async()=>{
        const campaignDetailsRef= collection(db,"voice-campaign-members");

        if(!name){
            return ;
        }
        else if(! group){
            return;
        }   
        else if(! number){
            return;
        }
        const newCampaign={campaignId,name:name,number,group}
        await addDoc(campaignDetailsRef,newCampaign);
        setName("");
        setGroup("");
        setNumber("");
        setVal(e=>e+1);
        setShowModal('');
    }

    const handleAddGroup=async()=>{
        const reqGroup=groupsData.filter(g=>g.groupName ===currentGroup)
        console.log(reqGroup,"required group");

        const usersRef=collection(db,"voice-recording-users");
        const usersQuery=query(usersRef,where('groupId','==',reqGroup[0]?.id));
        const response= await getDocs(usersQuery);
        const reponseFormat= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
        console.log(reponseFormat);

        const reqResponse=reponseFormat.map((dat,inde)=>({campaignId,"name":dat.name,"number":dat.number,"group":currentGroup}))


        const campaignDetailsRef= await collection(db,"voice-campaign-members");
        const addMultipleObjects=async()=>{
            for(let obj of reqResponse){
                await addDoc(campaignDetailsRef,obj);
            }
        }
        addMultipleObjects();
        setCurrentGroup("");
        setVal(e=>e+1);
        setShowModal("");
    }

    const sendVoiceMessage=()=>{
        if(!currentVoice){
            alert("choose a valid audio clip");
            return;
        }
        const numbersArray=[];
        for(let i=0;i<campaignData.length;i++){
            numbersArray.push(campaignData[i].number)
        }
        console.log(numbersArray);
        const numbersString = numbersArray.join(','); 
        console.log(numbersString);
        const reqRecord=recordingsData.filter(r=>r.voiceName ===currentVoice)
        const url = "https://panelv2.cloudshope.com/api/voice_call";
        const data = {
        voice_file_id: reqRecord[0]?.voiceId,
        numbers: numbersString,
        credit_type_id: 23,
        campaign_name: `${currentCampaign?.campaignName}`,
        callback_url:base_url+`api/campaigncallbac/?campaign_name=${currentCampaign?.campaignName}&voice_id=${reqRecord[0]?.voiceId}&audio_url=${currentCampaign.audioUrl}&business_name=${currentCampaign.businessName}&`
        };
        console.log(data,"frontenddd");

        const options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer 365716|UdDTCK63bNiupCZWOdWhqi5HGwIJDiJLEKlDNERc',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
        };

        fetch(url, options)
        .then(response => response.json())
        .then(data => {
            console.log(data);
            alert("successfully sent the voice message");
        })
        .catch(error => {
            console.error('Error:', error);
            alert("failed to sent the voice message");
        });
    }

    const deleteCampaign=async(id)=>{
        const reqDoc= doc(db,"voice-campaign-members",id);
        const response=await deleteDoc(reqDoc);
        console.log(response);
        setVal(e=>e+1);
    }
    const handleEditItem=async()=>{
        const campaignRef= doc(db,"voice-campaign-members",currentEditingId);

        if(!name){
            return ;
        }
        else if(! group){
            return;
        }   
        else if(! number){
            return;
        }
        const updatedCampaign={name:name,number,group}
        await updateDoc(campaignRef,updatedCampaign);
        setName("");
        setGroup("");
        setNumber("");
        setCurrentEditingId("");
        setVal(e=>e+1);
        setShowModal('');
    }

    const updateCampaign=(props)=>{
        const{id,name,number,group}=props;
        setCurrentEditingId(id);
        setName(name);
        setGroup(group);
        setNumber(number);
        setShowModal('edit');
       
    }

    const [deletingId,setDeletingId]=useState("");

    return(
        <>
        <NewSidebar/>
    <div className="voicecampaign-container">
        <div className='voicecampaign-action-bar'>
                <span className="action-gap">
                <h3 className="action-title">Voice( IVR ) {`> Campaign - ${currentCampaign?.campaignName}`} </h3>
                    <p className="action-text">Total Users : {campaignData.length}</p>
                </span>
            
            <span className='action-right'>  
                <button className='sendVoiceBtn' onClick={()=>{sendVoiceMessage()}}><h3>Send</h3></button>
                <button className="voicecampaignAddBtn2" onClick={()=>{setShowModal('add')}}><h3>Add</h3> <FaPlus className="plus-icon" /></button>
            </span>
          
        </div>
        <div className='voice-container'>
            <span className='choose-file'>
                <h3>Voice Message</h3>
                {/* <input type="file" /> */}
                <hr/>
                {/* <p>Choose from Library</p> */}
                <div className='' style={{display:"flex",alignItems:"center",gap:"25px"}}>
                    <select value={currentVoice} onChange={e=>{setCurrentVoice(e.target.value)}} style={{height:"19px"}}>
                        <option value="" disabled selected>Choose from Library</option>
                        {recordingsData && recordingsData.map((record,index)=>{return(<option key={index}>{record?.voiceName}</option>)})}
                    </select>
                    {currentVoice&& <AudioPlayer
                        src={currentAudio}
                        onPlay={e => console.log("onPlay")}
                        loop='false'
                        style={{width:'20vw'}}
                    />}
                </div>
               
            </span>
            
        </div>
        <div className=''>
            <table className='table-style'>
                    <thead>
                        <tr>
                            <th className='slim'>S.no</th>
                            <th className='slim'>Name</th>
                            <th className='slim-2'>Number</th>
                            <th className='slim'>Group</th>
                            <th className='slim'><input type="checkbox" />{" "}Select</th>
                            <th className='slim'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {campaignData && campaignData.map((campaign,index)=>{
                            const id=campaign.id;
                            const name=campaign.name;
                            const number=campaign.number;
                            const group=campaign.group;
                            return(
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{campaign.name}</td>
                                <td>{campaign.number}</td>
                                <td>{campaign.group}</td>
                                <td><input type="checkbox"/></td>
                                <td className='row-update'>
                                    <FaRegEdit className="update-row" onClick={()=>{updateCampaign({id,name,number,group})}} />
                                    <AiFillDelete className="delete-row" onClick={()=>{setDeletingId(campaign.id)}} />
                                </td>
                            </tr>)
                        })}
                    </tbody>
                </table>
        </div>
        {showModal && 
        <div className='campaignModalContainer'>

            <div className='campaignForm3'>
                <div className='modal-header'>
                    <h2 style={{color:"#363534",marginLeft:'17px'}}>{showModal[0].toUpperCase()+showModal.slice(1)} User</h2>
                    <ImCross style={{cursor:"pointer"}} onClick={()=>{setShowModal('')}} />
                </div>
                <hr/>
                <label className="labels">Name</label>
                <input type="text" className="campaignInput" placeholder="Enter User name" value={name} onChange={(e)=>{setName(e.target.value)}} />
                <label className="labels">Number</label>
                <input type="text" className="categoryInput" placeholder="Enter Mobile number" value={number} onChange={(e)=>setNumber(e.target.value)} />
                <label className="labels">Group</label>
                <input type="text" className="categoryInput" placeholder="Group Name" value={group} onChange={(e)=>setGroup(e.target.value)} />
                <button className="modal-submit" onClick={()=>{showModal==="add"?handleAddItem():handleEditItem()}}>Submit</button>
                
                {showModal==="add" && <><hr/>
                <h2>Add a Group</h2>
                <select value={currentGroup} onChange={e=>{setCurrentGroup(e.target.value)}}>
                    <option value="" disabled selected>Choose from Library</option>
                    {groupsData && groupsData.map((group,index)=>{return(<option key={index}>{group?.groupName}</option>)})}
                </select>
                <button className='addingGroupBtn' onClick={()=>{handleAddGroup()}}>Add Group</button></>}
            </div>

        </div>}
            {deletingId && <DeleteItem id={deletingId} handleClose={setDeletingId} handleDelete={deleteCampaign} />}
    </div></>)
}

export default VoiceCampaignDetails;