import { useEffect, useState } from "react";
import NewSidebar2 from "../NewSideBar2";
import { FaWhatsapp } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineMail } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import "./index.css";
// import { fetchSignInMethodsForEmail } from "firebase/auth";
// import { base_url } from "../../utils/new";
import { db } from "../../Config-firebase/firebase";
import { collection, getDocs } from "firebase/firestore";

const BusinessRequests=()=>{
    const [newRequests,setNewRequests]=useState([
        {businessName:"Flipkart",businessOwnerNumber:"9989786348",businessAddress:"Tenali",businessEmail:"charanpraveen9989@gmail.com",businessOwnerName:"charan charan"}]);
    
        useEffect(()=>{
            const fetchBusinessRequests=async()=>{

                const BusinessRequestsRef= collection(db,"business-requests");
                const response= await getDocs(BusinessRequestsRef);
                const businessRequestsData= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
                setNewRequests(businessRequestsData);
                
            }
            fetchBusinessRequests();
        },[])
    return(
        <>
        <NewSidebar2/>
        <div className='requests-container'>
             {newRequests.length>0 && newRequests.map((details,index)=>{
                const {businessName,businessOwnerNumber,businessAddress,businessOwnerName,businessEmail}=details;
             return(
                <div className='business-request-card' key={index} onClick={()=>{}}>
                    <h2 style={{}}>{businessName}</h2>
                    <h4 className='with-icon'><FaWhatsapp className='business-icoo' />{businessOwnerNumber}</h4>
                    <h4 className='with-icon'><FaLocationDot className='business-icoo' />{businessAddress}</h4>
                    <h4>{businessOwnerName}</h4>
                    <h4 className='with-icon'><MdOutlineMail className='business-icoo' />{businessEmail}</h4>
                    <AiFillDelete className="delete-row" onClick={()=>{}} />
                </div>)}
            )}
            {/* <FaCirclePlus className='addBusiness-icon' onClick={ handleAddBusiness} /> */}
        </div>
        </>
    )
}

export default BusinessRequests;