import FooterOutlet from '../FooterOutlet';
import './index.css';

const FooterPolicy = () => {
    return (
        <div className="landing-container">
            <FooterOutlet />
            <div className="policy-container">
                <h1>Privacy Policy</h1>
                <div className="policy-content">
                    <h2>1. Introduction</h2>
                    <p>This Privacy Policy outlines how Wizzmedia, a unit of Diin Technologies Pvt. Ltd., collects, uses, and protects your personal information.</p>

                    <h2>2. Information We Collect</h2>
                    <ul>
                        <li><strong>Personal Information:</strong> We collect personal information that you provide to us, such as your name, email address, and payment details.</li>
                        <li><strong>Usage Data:</strong> We collect data on how you use our services, including your IP address, browser type, and access times.</li>
                    </ul>

                    <h2>3. Use of Information</h2>
                    <ul>
                        <li><strong>Service Provision:</strong> We use your personal information to provide and improve our services.</li>
                        <li><strong>Communication:</strong> We may use your contact information to send you updates, newsletters, and other relevant communications.</li>
                        <li><strong>Analytics:</strong> We use usage data to analyze and improve the performance of our services.</li>
                    </ul>

                    <h2>4. Sharing of Information</h2>
                    <p>We do not sell or rent your personal information to third parties. We may share your information with trusted partners who assist us in operating our services, provided they agree to keep this information confidential.</p>

                    <h2>5. Data Security</h2>
                    <p>We implement a variety of security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.</p>

                    <h2>6. Your Rights</h2>
                    <p>You have the right to access, correct, or delete your personal information. You can also object to the processing of your data and request data portability.</p>

                    <h2>7. Cookies</h2>
                    <p>We use cookies to enhance your experience on our website. You can choose to disable cookies through your browser settings, but this may affect the functionality of our services.</p>

                    <h2>8. Third-Party Links</h2>
                    <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites.</p>

                    <h2>9. Changes to This Policy</h2>
                    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website.</p>

                    <h2>10. Contact Information</h2>
                    <p>For any questions about this Privacy Policy, please contact us at <a href="mailto:privacy@wizzmedia.in">privacy@wizzmedia.in</a>.</p>
                </div>
            </div>
        </div>
    );
}

export default FooterPolicy;
