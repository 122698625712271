import Cookies from 'js-cookie';
import NewSidebar from '../NewSidebar';
import './index.css';
import { useEffect, useState } from 'react';

const About=()=>{
    const [businessData,setBusinessData]=useState("");
    // const businesData=Cookies.get('currentBusiness');
    //     if(businessData){
    //         setBusinessData(JSON.parse(businesData));
    //     }
    useEffect(()=>{
        if(businessData===""){
            const businesData=Cookies.get('currentBusiness');
        if(businesData){
            const parsed=JSON.parse(businesData);
            setBusinessData(parsed);
        }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[businessData])
    return(
        <>
        <NewSidebar/>
        <div className='about-container'>
            <div className='about-message'>
            <h3>Welcome to Wizzmedia Buzz!<br/> We enhance your brand by creating engaging content and managing powerful <br/>campaigns through<br/>
                 <span className='about-media'>WhatsApp, IVR, SMS, Email, Reels, and Posts.</span> <br/>
                Start your journey with us today and watch your business thrive in the digital landscape!
            </h3>
            </div>
        </div>
        </>
    )
}

export default About;