import Cookies from 'js-cookie';
import NewSidebar from '../NewSidebar';
import './index.css';
import { useEffect, useState } from 'react';

const AboutVision=()=>{
    const [businessData,setBusinessData]=useState("");
    // const businesData=Cookies.get('currentBusiness');
    //     if(businessData){
    //         setBusinessData(JSON.parse(businesData));
    //     }
    useEffect(()=>{
        if(businessData===""){
            const businesData=Cookies.get('currentBusiness');
        if(businesData){
            const parsed=JSON.parse(businesData);
            setBusinessData(parsed);
        }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[businessData])
    return(
        <>
        <NewSidebar/>
        <div className='about-vision-container'>
            <div className='about-vision-message'>
            <h3>Our vision is to become the leading provider of AI-driven marketing solutions, <br/>
                transforming the way businesses engage with their customers across digital and <br/> social platforms,
                and setting new standards for customer experience<br/> and brand presence.
            </h3>
            </div>
        </div>
        </>
    )
}

export default AboutVision;