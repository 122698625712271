import FooterOutlet from '../FooterOutlet';
import './index.css';

const FooterTermsConditions=()=>{

    return(
        <div className="landing-container">
        <FooterOutlet />
        <div className="policy-container">
            <h1>Terms of Services</h1>
            <div className="policy-content">
                <h2>1. Acceptance of Terms</h2>
                <p>By accessing and using Wizzmedia, a unit of Diin Technologies Pvt. Ltd., you agree to comply with and be bound by these Terms of Service. If you do not agree with these terms, you should not use the service.</p>

                <h2>2. Description of Service</h2>
                <p>Wizzmedia provides digital media solutions and services as outlined on our website (wizzmedia.in). We reserve the right to modify or discontinue the service at any time without prior notice.</p>

                <h2>3. User Obligations</h2>
                <p>Users must provide accurate and complete information when registering for our services. Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.</p>

                <h2>4. Prohibited Activities</h2>
                <p>Users agree not to use the service for any illegal or unauthorized purpose. This includes, but is not limited to, the transmission of any harmful or malicious software, or the violation of any applicable laws or regulations.</p>

                <h2>5. Intellectual Property</h2>
                <p>All content provided on Wizzmedia, including text, graphics, logos, and software, is the property of Diin Technologies Pvt. Ltd. or its content suppliers and is protected by intellectual property laws.</p>

                <h2>6. Limitation of Liability</h2>
                <p>Wizzmedia and Diin Technologies Pvt. Ltd. shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or the inability to use the service, even if we have been advised of the possibility of such damages.</p>

                <h2>7. Termination</h2>
                <p>We reserve the right to terminate or suspend your account and access to the service at our sole discretion, without prior notice, for conduct that we believe violates these Terms of Service or is harmful to other users of the service, us, or third parties.</p>

                <h2>8. Governing Law</h2>
                <p>These Terms of Service shall be governed by and construed in accordance with the laws of the jurisdiction where Diin Technologies Pvt. Ltd. is headquartered, without regard to its conflict of law principles.</p>

                <h2>9. Changes to Terms</h2>
                <p>We reserve the right to modify these Terms of Service at any time. Your continued use of the service after any such changes constitutes your acceptance of the new Terms of Service.</p>

                <h2>10. Contact Information</h2>
                <p>For any questions about these Terms of Service, please contact us at <a href="mailto:contact@wizzmedia.in">contact@wizzmedia.in</a>.</p>
            </div>
        </div>
    </div>
    )
}

export default FooterTermsConditions;