import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import './index.css';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../Config-firebase/firebase';

// import { DataContext } from '../../context/data';

const BusinessUserRegister =()=>{
    const [name,setName]=useState("");
    const [number,setNumber]=useState("");
    const [email,setEmail]=useState("");
    const [details,setDetails]=useState("");
    const [businessArray,setBusinessArray]=useState("");
    
    const [error,setError]=useState(false);
    // const {currentBusinessDetails}=useContext(DataContext);

 
  const [searchParams]=useSearchParams();
   // Get specific query parameter value
   const requiredBusinessName =searchParams.get("businessname"); 

    console.log(requiredBusinessName);
    if(requiredBusinessName && businessArray===""){
        const getBusinessData=async()=>{
            const name=requiredBusinessName
            console.log(name)
            const businessCollectionRef=collection(db,"registered-businesses");
            const allDocsQuery=query(businessCollectionRef,where('businessName','==',name));
            const response= await getDocs(allDocsQuery);
            const businessArra= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            setBusinessArray(businessArra[0]);
            }
            getBusinessData();
    }
    const navigate = useNavigate();

    const handleUserDetails = async(event)=>{
        event.preventDefault();
        const customerCollection = collection(db,"business-customers");
        if(!name || !number || !email || !details){
            setError(true);
            return;
        }
        else{
            const todayDate = new Date();
            const yyyy = todayDate.getFullYear();
            let mm = todayDate.getMonth() + 1; 
            let dd = todayDate.getDate();
            const hour = todayDate.getHours();
            const minute = todayDate.getMinutes();
            const second = todayDate.getSeconds();

            const hourString = hour.toString().padStart(2, "0");
            const minuteString = minute.toString().padStart(2, "0");
            const secondString = second.toString().padStart(2, "0");

            mm = String(mm).padStart(2, '0'); 
            dd = String(dd).padStart(2, '0');

            const formattedDate = dd + '-' + mm + '-' + yyyy+"  "+hourString+":" +minuteString+ ":"+secondString;
            const requiredFormat={"name":name,"number":number,"businessName":requiredBusinessName,date:formattedDate,details,email,"businessId":businessArray.id}
            console.log(requiredFormat,"here");
            const newBusiness= await addDoc(customerCollection,requiredFormat);
            console.log(newBusiness);
            navigate(`/user-confirmation/?businessname=${businessArray.businessName}`);
        }
    }
    return(
        <div className="registerUserContainer">
            <div className='navbar-container'>
                <img src={businessArray.businessLogo} alt={requiredBusinessName} className='navbar-register-user-logo'/>
                <h2 className='' >{requiredBusinessName}</h2>
            </div>
            {error&& <p style={{color:"red"}}>*some fields are empty</p>}
            <form onSubmit={handleUserDetails} className='user-registration-form'>
                <input type="text" placeholder="Your Name" className="user-register-input" onChange={(e)=>{setName(e.target.value)}} value={name} />
                <input type="text" placeholder="Your Whatsapp Number" className="user-register-input" onChange={(e)=>{setNumber(e.target.value)}} value={number} />
                <input type="text" placeholder="Your Email" className="user-register-input" onChange={(e)=>{setEmail(e.target.value)}} value={email} />
                <input type="text" placeholder="Your Enqueries" className="user-register-input" onChange={(e)=>{setDetails(e.target.value)}} value={details} />
                <button className='user-register-submit' type='submit'>Submit</button>
            </form>
        </div>
    )
}

export default BusinessUserRegister;