
import Cookies from 'js-cookie';

import './index.css';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import NewSidebar from '../NewSidebar';
import { dataContext } from '../../Context/data';
import NewSidebar2 from '../NewSideBar2';
const BusinessesAccount=()=>{

    const {setCurrentUser,setIsAdmin,setRunningBusiness}=useContext(dataContext);

    const navigate=useNavigate();
    const handleLogout=()=>{
        Cookies.remove('userData')
        Cookies.remove('currentBusiness');
        setCurrentUser("");
        setIsAdmin(false);
        setRunningBusiness("");
        navigate('/');
    }
    const [userData,setUserData]=useState("");
    
    useEffect(()=>{
        const usersData=Cookies.get('userData');
    if(usersData){
        setUserData(JSON.parse(usersData));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    console.log("buinsessaccount ntered")

    return(
        <>
        <NewSidebar2/>
        <div className="">
            <div className='user-profile'>
                <p>Name:  {userData.displayName} </p>
                <p>Gmail: {userData.email} </p>
                
            </div>
            
            <button onClick={()=>{handleLogout()}}>Logout</button>
        </div>
        </>
    )
}

export default BusinessesAccount;