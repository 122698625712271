import { FaRegEdit } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { AiFillDelete } from "react-icons/ai";
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../../Config-firebase/firebase';
import './index.css';
import { useEffect, useState } from "react";
import DeleteItem from "../DeleteItem";
import NewSidebar from "../NewSidebar";

const ReelTemplate=()=>{
    const [showModal,setShowModal]=useState('');
    const [reelsData,setReelsData]=useState(null);
    const [val,setVal]=useState(0);
    const [youtubeUrl,setYoutubeUrl]=useState('');
    const [reelDuration,setReelDuration]=useState('');
    const [reelsCnt,setReelsCnt]=useState(0);
    const [aspectRatio,setAspectRatio]=useState('');
    // const [currentEditingId,setCurrentEditingId]=useState("");

    const availableReelDurations=[{duration:15},{duration:30},{duration:45}];
    const availableRatios=[{ratio:"16:9"},{ratio:"9:16"}];

    const handleAddItem=async()=>{
        const campaignDetailsRef= collection(db,"authorized-subadmins");

        if(!youtubeUrl){
            alert("Youtube URL is missing");
            return ;
        }
        else if(reelsCnt===0){
            alert("enter a valid email");
            return;
        }  
        if(true){
            return;
        }
        

        const todayDate = new Date();
        const yyyy = todayDate.getFullYear();
        let mm = todayDate.getMonth() + 1; 
        let dd = todayDate.getDate();
        const hour = todayDate.getHours();
        const minute = todayDate.getMinutes();
        const second = todayDate.getSeconds();

        const hourString = hour.toString().padStart(2, "0");
        const minuteString = minute.toString().padStart(2, "0");
        const secondString = second.toString().padStart(2, "0");

        mm = String(mm).padStart(2, '0'); 
        dd = String(dd).padStart(2, '0');

        const formattedDate = dd + '-' + mm + '-' + yyyy+"  "+hourString+":" +minuteString+ ":"+secondString;
        const newReel={date:formattedDate,}
        await addDoc(campaignDetailsRef,newReel);
        setYoutubeUrl("");
        setReelsCnt(0);
        setVal(e=>e+1);
        setShowModal('');
    }
    // const handleEditItem=async()=>{
    //     const subAdminRef= doc(db,"authorized-subadmins",currentEditingId);

    //     if(!name){
    //         alert("enter a valid name");
    //         return ;
    //     }
    //     else if(! email){
    //         alert("enter a valid email");
    //         return;
    //     }  
    //     const updatedGroup={name,email}
    //     await updateDoc(subAdminRef,updatedGroup);
    //     setName("");
    //     setEmail("");
    //     setCurrentEditingId("");
    //     setVal(e=>e+1);
    //     setShowModal('');
    // }
    const deleteRecord=async(id)=>{
        const reqDoc= doc(db,"authorized-subadmins",id);
        const response=await deleteDoc(reqDoc);
        console.log(response);
        setVal(e=>e+1);
    }
   
    useEffect(()=>{
        
        const getReelsData=async()=>{

            const reelsRef= collection(db,"reels");
           
            const response= await getDocs(reelsRef);
            const reponseFormat= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            reponseFormat.sort((a,b)=>{
                const datea=new Date(a.date);
                const dateb=new Date(b.date);
                return dateb-datea;
            })
            setReelsData(reponseFormat);
        }
        getReelsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[val])
    const [deletingId,setDeletingId]=useState("");
    
    return(
        <>
            <NewSidebar/>
            <div className='recordingusers-container'>
            <div className='voicecampaign-action-bar'>
                <span className="action-gap">
                    <h3 className="action-title">Reels{` > Templates`}</h3>
                    <p className="action-text">Total Reels : {reelsData?.length}</p>
                </span>
            
            <button className="voicecampaignAddBtn" onClick={()=>{setShowModal('add')}}><h3>Create Reel</h3> <FaPlus className="plus-icon" /></button>
        </div>
            <table className='table-style'>
                <thead>
                    <tr>
                        <th className='slim'>S.no</th>
                        <th className='slim'>Date</th>
                        <th className='slim3'>Youtube URL</th>
                        <th className="slim">Reel </th>
                        <th className='slim'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {reelsData && 
                    reelsData.map((reel,index)=>{
                        const id=record.id;
                        const name=record.name;
                        const email=record.email;
                        return (
                            <tr key={index}>
                                <td className=''>{index+1}</td>
                                <td className=''>{record.date}</td>
                                <td className=''>{record.name}</td>
                                <td className="">{record.email}</td>
                                <td className='row-update'>
                                    <FaRegEdit className="update-row" onClick={()=>{editSubAdmin({name,email,id})}} />
                                    <AiFillDelete className="delete-row" onClick={()=>{setDeletingId(record.id)}} />
                                </td>
                            </tr>
                        )})} */}
                    
                </tbody>
            </table>
            {showModal && 
        <div className='campaignModalContainer'>

            <div className='campaignForm3'>
                <div className='modal-header'>
                    <h2 style={{color:"#363534",marginLeft:'17px'}}>Reel Generator</h2>
                    <ImCross style={{cursor:"pointer"}} onClick={()=>{setShowModal('')}} />
                </div>
                <hr/>
                <label className="labels">Youtube URL:</label>
                <input type="text" className="campaignInput" placeholder="Enter youtube url" value={youtubeUrl} onChange={(e)=>{setYoutubeUrl(e.target.value)}} />
                <label className="labels">Reel Duration (seconds):</label>
                <select value={reelDuration} onChange={e=>{setReelDuration(e.target.value)}}
                className="campaignInput" >
                    {/* <option value="" disabled selected>Select Business</option> */}
                    {availableReelDurations && availableReelDurations.map((reel,index)=>{return(<option key={index}>{reel?.duration}</option>)})}
                </select>
                {/* <input type="text" className="campaignInput" placeholder="Enter email" value={reelDuration} onChange={(e)=>{setReelDuration(e.target.value)}} /> */}
                <label className="labels">Number of Reels:</label>
                <input type="number" className="campaignInput" placeholder="" value={reelsCnt} onChange={(e)=>{setReelsCnt(e.target.value)}} />
                <label className="labels">Aspect Ratio:</label>
                <select value={aspectRatio} onChange={e=>{setAspectRatio(e.target.value)}}
                className="campaignInput" >
                    {/* <option value="" disabled selected>Select Business</option> */}
                    {availableRatios && availableRatios.map((reel,index)=>{return(<option key={index}>{reel?.ratio}</option>)})}
                </select>
                {/* <input type="text" className="campaignInput" placeholder="Enter email" value={aspectRatio} onChange={(e)=>{setAspectRatio(e.target.value)}} /> */}
                
                <button className="modal-submit"  onClick={()=>{handleAddItem()}}>Submit</button>
            </div>

        </div>}
        {deletingId && <DeleteItem id={deletingId} handleClose={setDeletingId} handleDelete={deleteRecord} />}
        </div>
        </>
    )
}

export default ReelTemplate;