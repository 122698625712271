
import FooterOutlet from '../FooterOutlet';
import './index.css';

const FooterDiscalimer=()=>{

    return(
      <>
      <div className="landing-container" >
      <FooterOutlet/>
      <div className="disclaimer-container">
                <h1>Disclaimer</h1>
                <div className="disclaimer-content">
                    <h2>1. General Information</h2>
                    <p>
                        The information provided by Wizzmedia, a unit of Diin Technologies Pvt Ltd ("we," "our," "us"), on <a href="http://www.wizzmedia.in">www.wizzmedia.in</a> (the "Site") is for general informational purposes only. All information on the Site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.
                    </p>

                    <h2>2. External Links Disclaimer</h2>
                    <p>
                        The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the Site or any website or feature linked in any banner or other advertising.
                    </p>

                    <h2>3. Professional Disclaimer</h2>
                    <p>
                        The Site cannot and does not contain [Insert Specific Type] advice. The [Insert Specific Type] information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals.
                    </p>

                    <h2>4. Limitation of Liability</h2>
                    <p>
                        Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the Site or reliance on any information provided on the Site. Your use of the Site and your reliance on any information on the Site is solely at your own risk.
                    </p>

                    <h2>5. Contact Us</h2>
                    <p>
                        If you have any questions about this Disclaimer, please contact us at:
                        <br />
                        Email: <a href="mailto:contact@wizzmedia.in">contact@wizzmedia.in</a>
                    </p>
                </div>
            </div>
            </div>
            </>
    )
}

export default FooterDiscalimer;