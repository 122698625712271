import {useEffect, useState} from 'react'; 
import { FaPlus } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { useParams } from 'react-router-dom';
import './index.css'
import { addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../../Config-firebase/firebase';
import DeleteItem from '../DeleteItem';
import { FaRegEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import NewSidebar from '../NewSidebar';

const RecordingUsers=()=>{
    const [showModal,setShowModal]=useState('');
    const [val,setVal]=useState(0);
    const [name,setName]=useState("");
    const [number,setNumber]=useState("");
    const [deletingId,setDeletingId]=useState("");
    const [detail,setDetail]=useState("");
    // const [group,setGroup]=useState("");
    const [usersData,setUsersData]=useState(null);
    const [currentEditingId,setCurrentEditingId]=useState("");
    const { groupId } = useParams();

    const handleAddItem=async()=>{

        const campaignDetailsRef= await collection(db,"voice-recording-users");

        if(!name){
            return ;
        }
        else if(! number){
            return;
        }
        else if(!detail){
            alert("details are missing");
            return;
        }
        const todayDate = new Date();
        const yyyy = todayDate.getFullYear();
        let mm = todayDate.getMonth() + 1; 
        let dd = todayDate.getDate();
        const hour = todayDate.getHours();
        const minute = todayDate.getMinutes();
        const second = todayDate.getSeconds();

        const hourString = hour.toString().padStart(2, "0");
        const minuteString = minute.toString().padStart(2, "0");
        const secondString = second.toString().padStart(2, "0");

        mm = String(mm).padStart(2, '0'); 
        dd = String(dd).padStart(2, '0');

        const formattedDate = dd + '-' + mm + '-' + yyyy+"  "+hourString+":" +minuteString+ ":"+secondString;
        const newCampaign={date:formattedDate,name:name,number,groupId,detail}
        await addDoc(campaignDetailsRef,newCampaign);
        setName("");
        setDetail("");
        setNumber("");
        setVal(e=>e+1);
        setShowModal('');
    }
    const handleEditItem=async()=>{
        const userRef= doc(db,"voice-recording-users",currentEditingId);

        if(!name){
            return ;
        }
        else if(!number){
            return;
        }
        else if(!detail){
            alert("details are missing");
            return;
        }
        const updatedGroup={name,number,detail}
        await updateDoc(userRef,updatedGroup);
        setName("");
        setNumber("");
        setDetail("");
        setCurrentEditingId("");
        setVal(e=>e+1);
        setShowModal('');
    }

    const deleteUser=async(id)=>{
        const reqDoc= doc(db,"voice-recording-users",id);
        const response=await deleteDoc(reqDoc);
        console.log(response);
        setVal(e=>e+1);
    }
    const editUser=(props)=>{
        const {name,number,details,id}=props;
      
        setCurrentEditingId(id);
        setNumber(number);
        setName(name);
        setDetail(details);
        setShowModal('edit');
    }

    useEffect(()=>{
        
        const getCampaingnData=async()=>{

            const usersRef= collection(db,"voice-recording-users");
           const usersQuery=query(usersRef,where('groupId','==',groupId))
            const response= await getDocs(usersQuery);
            const reponseFormat= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            reponseFormat.sort((a, b) => {
                const dateA = new Date(a.date); // Convert strings to Date objects
                const dateB = new Date(b.date);
                return dateB - dateA; // Descending order (recent first)
              });
            setUsersData(reponseFormat);
        }
        getCampaingnData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[val])

    return(
        <>
        <NewSidebar/>
        <div className='recordingusers-container'>
            <div className='voicecampaign-action-bar'>
            <span className="action-gap">
            <h3 className="action-title">Voice( IVR ) {`> Users` }</h3>
                    <p className="action-text">Total Users : {usersData?.length}</p>
                </span>
            
            <button className="voicecampaignAddBtn" onClick={()=>{setShowModal('add')}}><h3>Add</h3> <FaPlus className="plus-icon" /></button>
        </div>
            <table className='table-style'>
                <thead>
                    <tr>
                        <th className='slim'>S.NO</th>
                        <th className='slim'>Date</th>
                        <th className='slim'>Name</th>
                        <th className='slim'>Number</th>
                        <th className='slim'>Details</th>
                        <th className='slim'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {usersData &&
                    usersData.map((user,index) =>{
                        const id=user.id;
                        const name=user.name;
                        const number=user.number;
                        const details=user.details;
                        return(
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{user.date}</td>
                                <td>{user.name}</td>
                                <td>{user.number}</td>
                                <td>{user.detail|| "None"}</td>
                                <td className='row-update'>
                                    <FaRegEdit className="update-row" onClick={()=>{editUser({name,number,details,id})}} />
                                    <AiFillDelete className="delete-row" onClick={()=>{setDeletingId(user.id)}} />
                                </td>
                            </tr>
                        )})}
                    
                </tbody>
            </table>
            {showModal && 
        <div className='campaignModalContainer'>

            <div className='campaignForm2'>
                <div className='modal-header'>
                    <h2 style={{color:"#363534",marginLeft:'17px'}}>{showModal[0].toUpperCase()+showModal.slice(1)} User</h2>
                    <ImCross style={{cursor:"pointer"}} onClick={()=>{setShowModal('')}} />
                </div>
                <hr/>
                <label className="labels">Name</label>
                <input type="text" className="campaignInput" placeholder="Enter User name" value={name} onChange={(e)=>{setName(e.target.value)}} />
                <label className="labels">Number</label>
                <input type="text" className="categoryInput" placeholder="Enter Mobile number" value={number} onChange={(e)=>setNumber(e.target.value)} />
                <label>Details</label>
                <input type="text" className='campaignInput' placeholder="Enter details" value={detail} onChange={(e)=>{setDetail(e.target.value)}} />
                <button className="modal-submit" onClick={()=>{showModal==="add"?handleAddItem():handleEditItem()}}>Submit</button>
            </div>

        </div>}
        {deletingId && <DeleteItem id={deletingId} handleClose={setDeletingId} handleDelete={deleteUser} />}
        </div></>
    )
}

export default RecordingUsers;