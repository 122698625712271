import FooterOutlet from '../FooterOutlet';
import './index.css';

const FooterRefunds=()=>{
    
    return(
        <>
         <div className="landing-container" >
            <FooterOutlet/>
        
             <div className="refund-policy">
                <h1>Refund Policy</h1>
                <div className="refund-content">
                    <h2>1. Introduction</h2>
                    <p>
                        Wizzmedia, a unit of Diin Technologies Pvt Ltd, is committed to providing quality services to our customers. We understand that sometimes there may be a need to request a refund. This Refund Policy outlines the conditions under which refunds will be issued.
                    </p>

                    <h2>2. Refund Eligibility</h2>
                    <ul>
                        <li>Refunds are eligible only for services that are canceled within 24 hours of purchase.</li>
                        <li>To be eligible for a refund, you must provide a valid proof of purchase.</li>
                    </ul>

                    <h2>3. Refund Process</h2>
                    <ol>
                        <li><strong>Request Submission:</strong> To request a refund, contact our customer support team at <a href="mailto:contact@wizzmedia.in">contact@wizzmedia.in</a> within 24 hours of your purchase.</li>
                        <li><strong>Service Fee Deduction:</strong> A service fee will be deducted from the refund amount. The service fee is [Specify Amount or Percentage].</li>
                        <li><strong>Processing Time:</strong> Refunds will be processed within 7-10 business days from the date of approval.</li>
                    </ol>

                    <h2>4. Exceptions</h2>
                    <ul>
                        <li>Refunds will not be issued for services that have been fully rendered.</li>
                        <li>Custom services or special orders are non-refundable.</li>
                    </ul>

                    <h2>5. Contact Us</h2>
                    <p>
                        If you have any questions about our Refund Policy, please contact us at:
                    </p>
                    <p>
                        Email: <a href="mailto:contact@wizzmedia.in">contact@wizzmedia.in</a>
                    </p>
                </div>
            </div>
            </div>
            
        </>
    )
}

export default FooterRefunds;