import {useContext, useEffect, useState} from 'react'; 
import { FaPlus } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import './index.css'
import { addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../../Config-firebase/firebase';
import { AiFillDelete } from "react-icons/ai";
import {Link} from 'react-router-dom'
// import Cookies from 'js-cookie';
import DeleteItem from '../DeleteItem';
import NewSidebar from '../NewSidebar';
import { dataContext } from '../../Context/data';

const RecordingGroups=()=>{
    // const [req,setReq]=useState("");
    // console.log(req)
    const {runningBusiness}=useContext(dataContext);
    const [showModal,setShowModal]=useState('');
    const [val,setVal]=useState(0);
    const [groupName,setGroupName]=useState("");
    const [category,setCategory]=useState("");
    // const [group,setGroup]=useState("");
    const [usersCount,setUsersCount]=useState([]);
    const [groupsData,setGroupsData]=useState(null);
    const [currentEditingId,setCurrentEditingId]=useState("");
    const [deletingId,setDeletingId]=useState("");
    

    const handleAddItem=async()=>{

        const campaignDetailsRef= await collection(db,"voice-recording-groups");

        if(!groupName){
            return ;
        }
           
        else if(! category){
            return;
        }
        const newCampaign={groupName,category,'businessName':runningBusiness.businessName}
        await addDoc(campaignDetailsRef,newCampaign);
        setGroupName("");
        setCategory("");
        setVal(e=>e+1);
        setShowModal('');
    }
    const handleEditItem=async()=>{
        const groupRef= doc(db,"voice-recording-groups",currentEditingId);

        if(!groupName){
            return ;
        }
           
        else if(! category){
            return;
        }
        const updatedGroup={groupName,category}
        await updateDoc(groupRef,updatedGroup);
        setGroupName("");
        setCategory("");
        setCurrentEditingId("");
        setVal(e=>e+1);
        setShowModal('');
    }
    const deleteGroup=async(id)=>{
        const reqDoc= doc(db,"voice-recording-groups",id);
        const response=await deleteDoc(reqDoc);
        console.log(response);
        setVal(e=>e+1);
    }
    const editGroup=async(props)=>{
        // console.log(props,"hiiii");
        const {name,category,id}=props;
        // console.log(name,category,"edittttttttttttt");
        setCurrentEditingId(id);
        setCategory(category);
        setGroupName(name);
        setShowModal('edit');
    }

    useEffect(()=>{
        // if(!req?.businessName){
        //     const businesData=Cookies.get('currentBusiness');
        // setReq(JSON.parse(businesData));
        // console.log(req);  
        // }
        
        
        const getCampaingnData=async()=>{
            // const businessData = Cookies.get("currentBusiness");
            // const parsedBusinessData = JSON.parse(businessData); // Handle potential parsing errors
      
            // if (!parsedBusinessData.businessName) {
            //   console.warn('Business name not available yet. Skipping data fetching.');
            //   return; // Exit early if businessName is undefined
            // }
      
            // if (!req?.businessName) {
            //     console.warn('Business name not available yet. Skipping data fetching.');
            //     return; // Exit early if businessName is undefined
            //   }

            const groupsRef= collection(db,"voice-recording-groups");
           const groupsQuery=query(groupsRef,where('businessName','==',runningBusiness.businessName))
            const response= await getDocs(groupsQuery);
            const reponseFormat= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            console.log(reponseFormat,"groups");
            const req= new Array(reponseFormat.length).fill(0);
            reponseFormat.forEach(async(group,index) =>{
                const usersRef= collection(db,"voice-recording-users");
                const usersQuery=query(usersRef,where('groupId','==',group.id))
                const reponse= await getDocs(usersQuery);
                const response= reponse.docs.map((doc,index)=>({...doc.data(),id:doc.id}));
                console.log(response.length,"serert",group.id)
                req[index]=(response.length);
                setUsersCount(req);
            })
            console.log(usersCount);
            setGroupsData(reponseFormat);
        }
        getCampaingnData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[val])


    return(
        <>
        <NewSidebar/>
        <div className='recordingusers-container'>
            <div className='voicecampaign-action-bar'>
                <span className="action-gap">
                    <h3 className="action-title">Voice( IVR ) {`> Groups` }</h3>
                    <p className="action-text">Total Groups : {groupsData?.length}</p>
                </span>
            
            <button className="voicecampaignAddBtn" onClick={()=>{setShowModal('add')}}><h3>Add</h3> <FaPlus className="plus-icon" /></button>
        </div>
            <table className='table-style'>
                <thead>
                    <tr>
                        <th className='slim'>S.no</th>
                        <th className='slim-2'>Group Name</th>
                        <th className='slim'>Total Users</th>
                        <th className='slim-2'>Category</th>
                        <th className='slim'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {groupsData &&
                    groupsData.map((group,index) =>{
                        const id=group.id;
                        const name=group.groupName;
                        const category=group.category;
                        return(
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td><Link to={`/voice-users/${group.id}`}>{group.groupName}</Link></td>
                                <td>{usersCount[index]}</td>
                                <td>{group.category}</td>
                                <td className='row-update'>
                                    <FaRegEdit className="update-row" onClick={()=>{editGroup({id,name,category})}} />
                                    <AiFillDelete className="delete-row" onClick={()=>{setDeletingId(group.id)}} />
                                </td>
                            </tr>
                        )})}
                    
                </tbody>
            </table>
        {showModal && 
        <div className='campaignModalContainer'>

            <div className='campaignForm'>
                <div className='modal-header'>
                    <h2 style={{color:"#363534",marginLeft:'17px'}}>{showModal[0].toUpperCase()+showModal.slice(1)} Group</h2>
                    <ImCross style={{cursor:"pointer"}} onClick={()=>{setShowModal('')}} />
                </div>
                <hr/>
                <label className="labels">Group Name</label>
                <input type="text" className="campaignInput" placeholder="Enter Group name" value={groupName} onChange={(e)=>{setGroupName(e.target.value)}} />
                <label className="labels">Category</label>
                <input type="text" className="categoryInput" placeholder="Enter Category" value={category} onChange={(e)=>setCategory(e.target.value)} />
                
                <button className="modal-submit" onClick={()=>{showModal==="add"?handleAddItem():handleEditItem()}}>Submit</button>
            </div>

        </div>}
        {deletingId && <DeleteItem id={deletingId} handleClose={setDeletingId} handleDelete={deleteGroup} />}
        </div></>
    )
}

export default RecordingGroups;