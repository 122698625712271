
import { useState } from 'react';
import { MdSettings } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { BiSolidBusiness } from "react-icons/bi";
import { FaCirclePlus } from "react-icons/fa6";
import {useNavigate} from 'react-router-dom';
import './index.css';
const BusinessLogin=()=>{
    const navigate = useNavigate();

    const sectionData=[{
        header:'Business',
    },{header:"BDA"},{header:"Settings"}]
    const listOfTitles=sectionData.length;
    const [activeTab,setActiveTab]=useState("");
    const [sectionVisibilities, setSectionVisibilities] = useState(Array(listOfTitles.length).fill(false));
    const toggleVisibility = (index) => {
        setSectionVisibilities((prevVisibilities) => {
          const updatedVisibilities = [...prevVisibilities];
          updatedVisibilities[index] = !prevVisibilities[index]; // Toggle state for clicked list
          return updatedVisibilities;
        });
      };
    
    
const icons=[<IoMdHome />,<BiSolidBusiness />,<MdSettings/>] 

    return(
        <>
            <div className="sidebar-container">
                {/* <div className=''> */}
                {sectionVisibilities&&
                sectionData.map((section,index)=>{
                    return(
                        <>
                        <span className='sidebar-options' onClick={()=>toggleVisibility(index)}>
                            <div className='sider'>
                                {icons[index]}
                                <p style={{cursor:"pointer"}}>{section.header}</p>
                            </div>
                                {/* {!sectionVisibilities[index]?<RiArrowDropDownLine className='arrowDrop' />:
                                <RiArrowDropUpLine className='arrowUp'/>} */}
                        </span>
                        {sectionVisibilities[index]&& section.subHeaders && (<span className="section-data" >
                            {section.subHeaders.map((subheader,inde)=>
                            <>
                                <p className={`${section-subheader} ${activeTab===subheader.path && 'showHigh'}`} onClick={()=>{navigate(`${subheader.path || '/'}`);setActiveTab(`${subheader.path}`)}}>{subheader?.title}</p>
                            </>)}
                        </span>)
                        }
                        </>
                    )})
            }
            </div>
            <div className='businesslogin-container'>
                <div className='voicecampaign-action-bar'>
                    <span className="action-gap">
                        <h3 className="action-title">Business {`> Info` }</h3>
                        <p className="action-text">Total Reports : </p>
                    </span>
                </div>
                
                <div className=''>
                    
                </div>
            </div>
            
        </>
    )
}

export default BusinessLogin;