import Cookies from 'js-cookie';
import NewSidebar from '../NewSidebar';
import './index.css';
import { useEffect, useState } from 'react';

const AboutMission=()=>{
    const [businessData,setBusinessData]=useState("");
    // const businesData=Cookies.get('currentBusiness');
    //     if(businessData){
    //         setBusinessData(JSON.parse(businesData));
    //     }
    useEffect(()=>{
        if(businessData===""){
            const businesData=Cookies.get('currentBusiness');
        if(businesData){
            const parsed=JSON.parse(businesData);
            setBusinessData(parsed);
        }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[businessData])
    return(
        <>
        <NewSidebar/>
        <div className='about-mission-container'>
            <div className='about-mission-message'>
            <h3>Our mission at Wizzmedia Buzz is to ignite brands with AI innovation,<br/>
                empowering businesses to thrive in both digital and physical landscapes <br/>
                through personalized and targeted marketing solutions.
            </h3>
            </div>
        </div>
        </>
    )
}

export default AboutMission;