// import {useState} from 'react';
import {Link} from 'react-router-dom';
import { FaUserCircle } from "react-icons/fa";
import './index.css'
// import { dataContext } from '../../Context/data';
// import { baseUrl } from '../../utils/new';
import Cookies from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
// import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
// import { db } from '../../Config-firebase/firebase';
import { dataContext } from '../../Context/data';

const Navbar=()=>{
   
    // const {details,message,attachFile,setDetails}=useContext(dataContext);
    // const [userData,setUserData]=useState("");
    // const [businessData,setBusinessData]=useState("");
    // const [businessDetails,setBusinessDetails]=useState(null);
    // const [businessArray,setBusinessArray]=useState("");
    // const [req,setReq]=useState("");
    const {runningBusiness,currentUser}=useContext(dataContext);

    const businesData=Cookies.get('currentBusiness');
    console.log(businesData,"herrrrrr");
    // useEffect(()=>{
        // if(userData==="" || req===""){
        //     const usersData=Cookies.get('userData');
        // if(usersData){
        //     setUserData(JSON.parse(usersData));
        // }
        // const businesData=Cookies.get('currentBusiness');
        // if(businesData){
        //     const parsed=JSON.parse(businesData);
        //     setBusinessData(parsed);
        // }
        // if(businessData){
        //     console.log(businessData)
        //     setReq(JSON.parse(businesData));
        //     console.log(businessData);
        // }
       
        // }
        
        // const getBusinessData=async()=>{
        //     const name=businessData?.businessName
        //     console.log(name,businessData)
        //     console.log("Businessidddd",name,businessData,businesData);
        //     const businessCollectionRef=collection(db,"registered-businesses");
        //     const allDocsQuery=query(businessCollectionRef,where('businessName','==',name));
        //     const response= await getDocs(allDocsQuery);
        //     const businessArra= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
        //     setBusinessArray(businessArra[0]);
        //     }
        //     getBusinessData();
    // const getBusinessdetails=async()=>{
    //     const id=businessData.businessId;
    //     const businessCollectionRef=doc(db,"registered-businesses",id);
    //     const queryBusinessDetails= await getDoc(businessCollectionRef);
    //     const businessArray={...queryBusinessDetails.data(),id:queryBusinessDetails.id}
    //     console.log(businessArray,"navvvvvvvvvvvvvvv");
    //     setBusinessDetails(businessArray);
    // }
    // getBusinessdetails();
    //eslint-disable-next-line react-hooks/exhaustive-deps
    // },[])
    // console.log(req)
    
    return(
        <div className="navbar-container">
            <span className='navbar-title'>
            <Link to="/about"><img src={runningBusiness?.businessLogo || "https://res.cloudinary.com/dswjg0rjx/image/upload/v1717671939/Wizmeidia_Logo_i3bhvi.png"} alt="Wizzmedia Buzz" className='wiz-logo' /></Link>
            <h2 className='navbar-title'> {runningBusiness?.businessName || "Wizzmedia Buzz"}</h2>
            </span>
            <span className='navbar-right-content'>
                <h3 style={{}}>{currentUser?.displayName || "unknown"}</h3>
                <FaUserCircle className='navbar-user-icon' />
            </span>
         
        </div>
    )
}

export default Navbar;