import { GoogleLogin} from '@react-oauth/google';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { ImCross } from "react-icons/im";
import { IoCloseSharp } from "react-icons/io5";

import './index.css'; 
import { useContext, useEffect, useState} from 'react';
import { auth, db, googleAuth } from '../../Config-firebase/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { MdMenu } from "react-icons/md";

import { dataContext } from '../../Context/data';

const LandingPage=()=>{
    const navigate=useNavigate();
    const [authUsers,setAuthUsers]=useState(null);
    const [authSubadmins,setAuthSubadmins]=useState(null);
    const [showModal,setShowModal]=useState(false);
    const [name,setName]=useState("");
    const [number,setNumber]=useState("");
    const currentYear= new Date().getFullYear();
    // const [category,setCategory]=useState("");
    const [address,setAddress]=useState("");
    const [email,setEmail]=useState("");
    const [businessName,setBusinessName]=useState("");
    const [signInModal,setSignInModal]=useState(false);
    const [isPlaying,setIsPlaying]=useState(false);
    const [showDrawer,setShowDrawer]=useState(false);
    // const videoRef=useRef();
    const footerItems=[
        {header:"Wizzmedia",subHeaders:[{title:"About Us",path:""}]},
        {header:"Office",subHeaders:[{title:"Head Office",path:""},{title:"Branch Office",path:""},]},
        {header:"Quick Links",subHeaders:[{title:"Blog",path:""},{title:"Admin",path:""},{title:"Careers",path:""}]},
        {header:"Legal Stuff",subHeaders:[{title:"Privacy Policy",path:"/privacy-policy"},{title:"Terms of Service",path:"/terms-conditions"},{title:"Refunds",path:"/refunds"},{title:"Disclaimer",path:"/disclaimer"},]},
    ]

    const {setCurrentUser,currentUser,setRunningBusiness,setIsAdmin}=useContext(dataContext);
    useEffect(()=>{
        const getAuthorizedUsers=async()=>{
            const authorizedUsersRef= collection(db,"authorized-users");
           const response=await getDocs(authorizedUsersRef);
           const reponseFormate= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
           const emails=reponseFormate.map(r=>r.email);
          //  console.log(emails);
           setAuthUsers(emails);
        }
        getAuthorizedUsers();
        const getAuthorizedSubAdmins=async()=>{
          const authorizedSubAdminsRef= collection(db,"authorized-subadmins");
           const response=await getDocs(authorizedSubAdminsRef);
           const reponseFormate= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
           const emails=reponseFormate.map(r=>r.email);
          //  console.log(emails);
           setAuthSubadmins(emails);
        }
        getAuthorizedSubAdmins();
        if(currentUser!=="" && authSubadmins!==null && authUsers!==null){
            if(authSubadmins.includes(currentUser.email)|| authUsers.includes(currentUser.email)){
                setIsAdmin(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentUser])

    const businessLogin=async(bUser)=>{
            console.log("logging to your business");
            const businessRef=collection(db,"registered-businesses");
            const businessDat=await getDocs(businessRef);
            // const businessData= await businessDat.json();
            const responseFormat= businessDat.docs.map((doc)=>({...doc.data(),id:doc.id}));
            console.log(currentUser,responseFormat);
            const requiredBusiness=responseFormat.filter(business=>business.businessEmail===currentUser.email);
            console.log(requiredBusiness);
            if(requiredBusiness.length===0){
                alert("no businesses found create one");
                return;
            }
            else{
                Cookies.set('currentBusiness', JSON.stringify(requiredBusiness[0] ));
                setRunningBusiness(requiredBusiness[0]);
                console.log("success");
                navigate('/about',{replace:true});
            }
            // const businessCollectionRef=collection(db,"business-users");
            // const response= await getDocs(businessCollectionRef);
            // const reponseFormat= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            // console.log(reponseFormat);
            // const requiredBusinessUsers=reponseFormat.filter(b=>b.email===currentUser.email);
            // console.log(requiredBusinessUsers);
            // if(requiredBusinessUsers.length===0){
            //     alert("no businesses found create one");
            //     return;
            // }
            // else{
            //     console.log("entered business2");
            //     const businessRef=collection(db,"registered-businesses");
            //     console.log('id',requiredBusinessUsers[0].businessId,requiredBusinessUsers[0]);
            //     const businessQuery=query(businessRef,where('businessName','==',requiredBusinessUsers[0].businessName));
            //     const businessDat=await getDocs(businessQuery);
            //     const businessData= businessDat.docs.map((doc)=>({...doc.data(),id:doc.id}));
            //     console.log(businessData);
            //     console.log("entered business3");
            //     if(businessData.length!==0){
            //         console.log(businessData);
            //         Cookies.set('currentBusiness', JSON.stringify(businessData[0] ));
            //         setRunningBusiness(businessData[0]);
            //         console.log("success");
            //         navigate('/about',{replace:true});
            //     }
            //     else{
            //         console.log(businessData);
            //         alert("error124355");
            //     }  
            // }
            //       console.log("closed");
              }

    const handleGoogleLogin = async () => {
    
        try {
          const result = await signInWithPopup(auth, googleAuth);
         
          const credential =GoogleAuthProvider.credentialFromResult(result);
        //   setGoogleUser({name:result.user.displayName,email:result.user.email,uid:result.user.uid})
          console.log(credential,credential.accessToken);
          console.log(result.user,"user");
          
          if (result.user) {
          
            console.log(result.user);
            // console.log(authUsers,"sdfffffffffffffffffffffff")
            // const authCheck=authSubadmins.includes(result.user.email);
            // console.log(authCheck,"checking user")
            // if(authCheck){
                Cookies.set('userData', JSON.stringify(result.user),{expires:10});
                setCurrentUser(result.user);
                console.log("success");
               
                // navigate('/');
            
          }
          else{
            alert("some issues")
            return;
          }
        } catch (error) {
          alert("not a valid user");
          
          console.log(error);
          return;
        }
      };
    const adminLogin=()=>{
        if(authUsers.includes(currentUser.email) || authSubadmins.includes(currentUser.email)){
            setIsAdmin(true);
            navigate('/businesses');
            console.log("navigated");
        }
        else{
            alert("access denied");
        }
    }
    
    const handleAdmin=async()=>{
        const useData=Cookies.get("userData");
        if(useData){
            console.log("yeah i am trigged");
            console.log(useData);
            console.log(currentUser);
            // setCurrentUser(currentUser);
           await adminLogin();
            return;
            // Cookies.remove("userData")
        }
        await handleGoogleLogin();
        adminLogin();
         
    }
    const handleBusiness=async()=>{
        const useData=Cookies.get("userData");
        const businessData=Cookies.get("currentBusiness");
        if(businessData){
            navigate('/about',{replace:true});
            return;
        }
        if(useData){
            await businessLogin(useData);
            return;
            // Cookies.remove("userData")
        }
        await handleGoogleLogin();
        if(useData){
            await businessLogin(useData);
            return;
            // Cookies.remove("userData")
        }
    }
    const h3Style = {
        fontSize: '1.31em', /* Typical font-size for h3 */
        fontWeight: 'bold', /* h3 is typically bold */
        marginTop: '1em', /* Top margin for spacing */
        marginBottom: '1em', /* Bottom margin for spacing */
        lineHeight: '1.25', /* Adjust line-height for readability */
        cursor: 'pointer', /* Add cursor pointer to indicate clickability */
    };

    
    return(
        <>
        <div className="landing-container" >
            {<div className="navbar-landing">
                <div className='navbar-left'>
                    <p onClick={()=>{navigate('/')}} style={h3Style}>Wizzmedia</p>
                    <p onClick={()=>{navigate('/')}}>Home</p>
                    <p>About</p>
                    <p onClick={()=>{setIsPlaying(p=>!p)}}>Business</p>
                </div>
                <div  className='navbar-right'>
                    <p>Help</p>
                    <button className='landing-signup-btn' onClick={()=>{setShowModal(true)}}>Sign Up</button>
                </div>
            </div>}
            <div className="navbar-landing-mobile">
                <div className='navbar-left'>
                    <h3 onClick={()=>{navigate('/')}}>Wizzmedia</h3>
                </div>
                <div  className='navbar-right'>
                    {/* <p>Help</p> */}
                    <button className='landing-signup-btn' onClick={()=>{setShowModal(true)}}>Sign Up</button>
                    {showDrawer?<ImCross onClick={()=>{setShowDrawer(false)}} className='navbar-mobile-menu-icon' />:<MdMenu onClick={()=>{setShowDrawer(true)}} className='navbar-mobile-menu-icon' />}
                </div>
            </div>
            {/* page1 */}
            <div className='landing-container-page36'>
                <div className='super-inner-left-36'>
    
                    <div className='header-align'>
                        <h1 className='landing-header' >
                            Igniting Brands with 
                        </h1>
                        <h1 className='landing-header'>
                        AI Innovation
                        </h1>
                       <h1 className='landing-mobile-header'>Igniting Brands with AI Innovation</h1>
                    </div>
                    <div className='description-align36'>
                        <h3 className='landing-header'>
                        Start your journey with us today and watch your business</h3>
                        <h3 className='landing-header'>thrive in the Digital & Physcial landscape!</h3> 
                        <h3 className='landing-mobile-header'>Start your journey with us today and watch your business thrive in the Digital & Physcial landscape!</h3>
                    </div>
                    
                   {/* <h3 className='landing-description highligt-gold'> Start your journey with us today and watch your business thrive in the digital landscape!</h3> */}
                    {/* <h3 className='business-login-txt'>Business Login</h3> */}
                    <div className='landing-btns36'>
                        {/* <a href="https://web.whatsapp.com/send/?phone=9972968390&text=i want to buy your services" target="_blank_" className='talk-wrapper'> */}
                            <button className='start-now-btn' onClick={()=>{setShowModal(true)}}>Start Now</button>
                        {/* </a>  */}
                        {/* <button className='sign-in-btn' onClick={()=>{handleBusiness()}}>Already have an Account? Sign in</button> */}
                        <button className='sign-in-btn' onClick={()=>{setSignInModal(true)}}>Already have an Account? Sign in</button>
                    </div>
                    {/* <button onClick={()=>{handleBusiness()}} className='google-btn'>
                        <img src="google.png" alt="google" className='googleImage2'/>
                        <h3>Sign in with Google</h3>
                    </button>
                    <a href="https://web.whatsapp.com/send/?phone=9972968390&text=i want to buy your services" target="_blank_" className='talk-wrapper'>
                        <button className='talk-to-us2' >
                            <img src="whatsapp-icon.png" alt="whatsapp-icon" className='whatsapp-icon'/>
                        <p className=''>Talk to Us</p> 
                        </button>
                    </a> */}
                </div>
                <div className='super-inner-right'>
                    <img src ="base-image.png" className='super-inner-image' alt="landin-imag"/>
                </div>
            </div>
            {/* page2 */}
            <div className='landing-container-page7' >
            <div className='super-inner-right'>
                    <img src ="wizz-1.png" className='super-inner-image-page2' alt="landin-imag"/>
                </div>
                <div className='super-inner-left'>
                    {/* <img src="wizmedia.png" alt="wizz media" className='wizzmedia-landing'/> */}
                    <div className='header-align'>
                        <h1 className='landing-header' >
                        IVR & SMS 
                        </h1>
                        <h1 className='landing-header' >
                        Marketing
                        </h1>
                        <h1 className='landing-mobile-header'>IVR & SMS Marketing</h1>
                    </div>

                    <div className='description-align7'>
                        <h3  className='landing-header'>
                        Deliver targeted messages and promotions via interactive </h3>
                        <h3 className='landing-header'>voice response (IVR) and text messaging (SMS) platforms. </h3>
                        <h3 className='landing-mobile-header'>Deliver targeted messages and promotions via interactive voice response (IVR) and text messaging (SMS) platforms. </h3> 
                    </div>
                    <div className='landing-btns7'>
                            <button className='start-now-btn2' onClick={()=>{setShowModal(true)}}>Get Started</button>
                            <button className='sign-in-btn'>Check out our solutions</button>
                    </div>
                  
                </div>
            </div>

            {/* page3 */}
            {/* <div className='landing-container-page3' style={{backgroundColor:"#0d0d0d",color:"#fff"}}>
                <div className='super-inner-left'>
                    
                    <div className='header-align'>
                        <h1 className='' style={{marginTop:"40px",marginBottom:"0px",paddingTop:"0px",marginLeft:"76px",fontSize:"55px"}}>
                        WhatsApp &
                        </h1>
                        <h1 style={{marginTop:"0px",marginBottom:"0px",paddingTop:"0px",marginLeft:"76px",fontSize:"55px"}}>
                        Email Marketing
                        </h1>
                    </div>
                    
                    <div className='description-align'>
                        <h3  style={{marginLeft:"80px",marginBottom:"0px", fontWeight:500}}>
                        Engage customers with personalized messages and promotions </h3>
                        <h3 style={{marginLeft:"-13px",marginBottom:"4px",paddingLeft:"0px", fontWeight:500}}>through WhatsApp messaging and email campaigns. </h3> 
                    </div>
                    <div className='landing-btns3'>
                            <button className='start-now-btn3' onClick={()=>{setShowModal(true)}}>Get Started</button>
                            <button className='sign-in-btn'style={{marginLeft:"6%"}} >Check out our solutions</button>
                    </div>
                </div>
                <div className='super-inner-right'>
                    <img src ="wmarketing.jpeg" className='super-inner-image2' alt="landin-imag-page3" style={{marginLeft:"10px"}}/>
                </div>
            </div> */}
            {/* page30 */}
            <div className='landing-container-page6' >
                <div className='super-inner-left'>
                    
                    <div className='header-align'>
                        <h1 className='landing-header'>
                        WhatsApp &
                        </h1>
                        <h1 className='landing-header'>
                        Email Marketing
                        </h1>
                        <h1 className='landing-mobile-header'>WhatsApp & Email Marketing</h1>
                    </div>
                    
                    <div className='description-align6'>
                        <h3 className='landing-header'>
                        Engage customers with personalized messages and promotions </h3>
                        <h3 className='landing-header'>through WhatsApp messaging and email campaigns. </h3> 
                        <h3 className='landing-mobile-header'>Engage customers with personalized messages and promotions through WhatsApp messaging and email campaigns.</h3>
                    </div>
                    <div className='landing-btns6'>
                            <button className='start-now-btn3' onClick={()=>{setShowModal(true)}}>Get Started</button>
                            <button className='sign-in-btn' >Check out our solutions</button>
                    </div>
                </div>
                <div className='super-inner-right'>
                    <img src ="wmarketing.jpeg" className='super-inner-image2' alt="landin-imag-page3" style={{marginLeft:"10px"}}/>
                </div>
            </div>

            {/* page4 */}
            {/* <div className='landing-container-page4' style={{backgroundColor:"#0d0d0d",color:"#fff"}}>
                <div className='super-inner-right'>
                    <img src ="socialmedia.jpeg" className='super-inner-image4' alt="landin-imag"/>
                </div>
                <div className='super-inner-left'>
                   
                    <div className='header-align'>
                        <h1 className='' style={{marginTop:"40px",marginBottom:"0px",paddingTop:"0px",marginLeft:"7px",fontSize:"55px"}}>
                        Digital & Social 
                        </h1>
                        <h1 style={{marginTop:"0px",marginBottom:"0px",paddingTop:"0px",marginLeft:"9px",fontSize:"55px"}}>
                        Media Management
                        </h1>
                    </div>
                  
                    <div className='description-align'>
                        <h3  style={{marginLeft:"11px",marginBottom:"0px", fontWeight:500}}>
                        Enhance brand presence and engagement across digital platforms</h3>
                        <h3 style={{marginLeft:"8px",marginBottom:"4px",paddingLeft:"0px", fontWeight:500}}>through strategic content creation and community management. </h3> 
                    </div>
                    <div className='landing-btns4'>
                            <button className='start-now-btn4' onClick={()=>{setShowModal(true)}}>Get Started</button>
                            <button className='sign-in-btn'style={{marginLeft:"6%"}} >Check out our solutions</button>
                    </div>
                 
                </div>
                
            </div> */}
            <div className='landing-container-page7' >
                <div className='super-inner-right'>
                    <img src ="socialmedia.jpeg" className='super-inner-image4' alt="landin-imag"/>
                </div>
                <div className='super-inner-left'>
                    {/* <img src="wizmedia.png" alt="wizz media" className='wizzmedia-landing'/> */}
                    <div className='header-align'>
                        <h1 className='landing-header' >
                        Digital & Social 
                        </h1>
                        <h1 className='landing-header'>
                        Media Management
                        </h1>
                        <h1 className='landing-mobile-header'>Digital & Social Media Management</h1>
                    </div>
                  
                    <div className='description-align7'>
                        <h3 className='landing-header'>
                        Enhance brand presence and engagement across digital platforms</h3>
                        <h3 className='landing-header'>through strategic content creation and community management. </h3> 
                        <h3 className='landing-mobile-header'>
                        Enhance brand presence and engagement across digital platforms
                        through strategic content creation and community management. </h3> 
                    </div>
                    <div className='landing-btns7'>
                            <button className='start-now-btn4' onClick={()=>{setShowModal(true)}}>Get Started</button>
                            <button className='sign-in-btn' >Check out our solutions</button>
                    </div>
                 
                </div>
                
            </div>
                {/* page6 */}
                <div className='landing-container-page6'>
                <div className='super-inner-left'>
                    {/* <img src="wizmedia.png" alt="wizz media" className='wizzmedia-landing'/> */}
                    <div className='header-align'>
                        <h1 className='landing-header'>
                        Website & AI 
                        </h1>
                        <h1 className='landing-header'>
                        Chatbot Development
                        </h1>
                        <h1 className='landing-mobile-header'>Website & AI Chatbot Development</h1>
                    </div>
                   
                    <div className='description-align6'>
                        <h3 className='landing-header'>
                        Create user-friendly websites and AI-driven chatbots to provide </h3>
                        <h3 className='landing-header'>seamless customer experiences and drive conversions </h3> 
                        <h3 className='landing-mobile-header'>
                        Create user-friendly websites and AI-driven chatbots to provide
                        seamless customer experiences and drive conversions </h3>
                    </div>
                    <div className='landing-btns6'>
                            <button className='start-now-btn5' onClick={()=>{setShowModal(true)}}>Get Started</button>
                            <button className='sign-in-btn'style={{marginTop:"3px",paddingBottom:"2px",height:"32px"}} >Check out our solutions</button>
                            {/* <hr className='sign-in-line'/> */}
                    </div>
                  
                </div>
                <div className='super-inner-right'>
                    <img src ="chatbot.png" className='super-inner-image6' alt="landin-imag"/>
                </div>
            </div>
            
            <div className='landing-footer'>
                <div className='footer-items'>
                    {footerItems.length>0 &&
                        footerItems.map((data,index)=>(
                            <div className='footer-col'>
                            <h3>{data.header}</h3>
                            <div className='footer-col-item'>
                             {data.subHeaders.map((dat,inde)=>(
                                    <p onClick={()=>{dat.title==="Admin"?handleAdmin():navigate(dat.path)}}>{dat.title}</p>                          
                            ))}   
                            </div>
                            </div>
                        ))
                        
                    }
                </div>
                <div className='footer-bottom'>
                <p className="copyright">
                    Copyright © {currentYear} wizzmedia. All Rights Reserved. 
                </p>
                </div>
                
            </div>
        </div>
        {showModal&&
            <div className='signUpForm'>
                <div className='signUpInner'>
                    <div className='modal-header'>
                        <h2 style={{color:"#363534",marginLeft:'0px',fontSize:"33px"}}>Sign Up</h2>
                        <ImCross style={{cursor:"pointer"}} onClick={()=>{setShowModal('')}} />
                    </div>
                    <hr/>
                    {/* <label className="labels"> Name</label>
                    <input type="text" className="landingInput" placeholder="Enter your name" value={name} onChange={(e)=>{setName(e.target.value)}} /> */}
                     {/* <label className="labels">Business Name</label> */}
                    <input type="text" className="landingInput" placeholder="Enter Business name" value={businessName} onChange={(e)=>{setBusinessName(e.target.value)}} />
                    
                    {/* <label className="labels">Mobile Number</label> */}
                    <input type="text" className="landingInput" placeholder="Enter Business number" value={number} onChange={(e)=>setNumber(e.target.value)} />
                    {/* <label className="labels">City</label> */}
                    <input type="text" className="landingInput" placeholder="Enter City" value={address} onChange={(e)=>setAddress(e.target.value)} />
                    {/* <label className="labels">Google Login</label> */}
                    <div className='google-signup-container'>
                        <img src="google.png" alt="google" className='signup-google-image'/>
                        <p>Continue With Google</p>
                    </div>
                    <a href={`https://web.whatsapp.com/send/?phone=9972968390&text=I would like to learn more about Wizzmedia Marketing Services. My name is ${businessName}`} target="_blank_" className='talk-wrapper google-signup-container'  style={{marginTop:'7px'}}>
                            <img src="whatsapp-icon.png" alt="google" className='signup-google-image'/>
                            <p>Talk to Us</p>
                    </a>
                    {/* <input type="text" className="landingInput" placeholder="Enter Email" value={email} onChange={(e)=>setEmail(e.target.value)} /> */}
                    {/* <label className="labels">Category</label>
                    <input type="text" className="landingInput" placeholder="Enter category" value={category} onChange={(e)=>setCategory(e.target.value)} /> */}
                    
                    {/* <button className="landing-modal-submit" onClick={()=>{}}>Submit</button> */}
                </div>
            </div>
        }
        {signInModal&&
            <div className='signInForm'>
                <div className='signInInner'>
                    <div className='modal-header'>
                        <h2 style={{color:"#363534",marginLeft:'0px',fontSize:"33px"}}>Sign In</h2>
                        <ImCross style={{cursor:"pointer"}} onClick={()=>{setSignInModal('')}} />
                    </div>
                    <hr/>
                    <div className='google-signIn-container' onClick={()=>{handleBusiness()}}>
                        <img src="google.png" alt="google" className='signup-google-image'/>
                        <p>Continue With Google</p>
                    </div>
                    {/* <a href="https://web.whatsapp.com/send/?phone=9972968390&text=there is a problem with my sign in my business name is " target="_blank_" className='talk-wrapper'>
                        <button className='talk-to-us-signup' >
                            <img src="whatsapp-icon.png" alt="whatsapp-icon" className='whatsapp-icon' style={{marginLeft:"4px"}}/>
                        <p className='' style={{marginLeft:"6px",fontSize:"16px"}}>Talk to Us</p> 
                        </button>
                    </a>  */}
                    <a href="https://web.whatsapp.com/send/?phone=9972968390&text=there is a problem with my sign in my business name is " target="_blank_" className='talk-wrapper google-signIn-container' style={{marginTop:'8px'}}>
                            <img src="whatsapp-icon.png" alt="google" className='signup-google-image'/>
                            <p>Talk to Us</p>
                    </a>
                </div>
            </div>
        }
        {isPlaying &&
            <div className="landingbusiness-video-container">
                <div className='video-container'>
                    <div className='video-top'>
                        <IoCloseSharp className='close-video-icon' onClick={()=>{setIsPlaying(false)}} />
                    </div>
                    <video controls className='wiz-video'autoPlay={isPlaying}>
                    <source src="https://res.cloudinary.com/dswjg0rjx/video/upload/v1720031516/Wizmedia-video_sb0cmi.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                </div>
          </div>
        }
        {
            showDrawer &&
            <div className={`drawer-container ${showDrawer ? 'show-drawer' : ''}`}>
                <div className='drawer-items'>
                    <p>Home</p>
                    <p>About</p>
                    <p onClick={()=>{setShowDrawer(false);setIsPlaying(p=>!p)}}>Business</p>

                </div>
            </div>
        }
        </>
    )
}

export default LandingPage;