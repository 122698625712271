import { useSearchParams } from 'react-router-dom';
import { useState} from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import './index.css';
import { db } from '../../Config-firebase/firebase';

const UserConfirmation=()=>{
    const [businessArray,setBusinessArray]=useState("");
    const [searchParams]=useSearchParams();
   // Get specific query parameter value
   const requiredBusinessName =searchParams.get("businessname"); 

    console.log(requiredBusinessName);
    if(requiredBusinessName && businessArray===""){
        const getBusinessData=async()=>{
            const name=requiredBusinessName
            console.log(name)
            const businessCollectionRef=collection(db,"registered-businesses");
            const allDocsQuery=query(businessCollectionRef,where('businessName','==',name));
            const response= await getDocs(allDocsQuery);
            const businessArra= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            setBusinessArray(businessArra[0]);
            }
            getBusinessData();
    }
    return(
        <div className="registerUserContainer2">
            <div className='navbar-container'>
                <img src={businessArray.businessLogo} alt={requiredBusinessName} className='navbar-register-user-logo'/>
                <h2 className='' >{requiredBusinessName}</h2>
            </div>
            <div className='confirmation-box'>
                <div className='thanks-box'>
                    <h3 className=''>
                        Thanks for registering, Your Details are Submitted
                    </h3>
                </div>
            </div>
            
        </div>
    )
}

export default UserConfirmation;