import { FaPlus } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { addDoc, collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../../Config-firebase/firebase';
import './index.css';
import { useContext, useEffect, useState } from "react";
import NewSidebar from "../NewSidebar";
import './index.css';
import { dataContext } from "../../Context/data";
import { base_url } from "../../utils/new";

const PaymentHistory=()=>{
    const [showModal,setShowModal]=useState('');
    const [ordersData,setOrdersData]=useState(null);
    const [businessData,setBusinessData]=useState(null);
    const [currentBusiness,setCurrentBusiness]=useState();
    const [val,setVal]=useState(0);
    const [businessName,setBusinessName]=useState('');
    const [password,setPassword]=useState('');
    const [credits,setCredits]=useState(0);
    const [currentEditingId,setCurrentEditingId]=useState("");
    // {'Voice(IVR)':false,'Whatsapp':false,'SMS':false,'Email':false,'Reels':false,'Posts':false,'Settings':false})
    const [service,setService]=useState([{title:'Voice(IVR)',isChecked:false},{title:'Whatsapp',isChecked:false},{title:'SMS',isChecked:false},{title:'Email',isChecked:false},{title:'Reels',isChecked:false},{title:'Posts',isChecked:false}]);
    const {currentUser}=useContext(dataContext);

    const handleAddItem=async()=>{

        if(!currentBusiness){
            alert("choose a valid business");
            return ;
        }
        else if(! credits){
            alert("missing credits");
            return;
        }  
        else if(credits<0){
            alert("invalid credits");
            return;
        }
        else if(!password){
            alert("missing password");
            return;
        }
        let orderData={businessName:currentBusiness,credits,amount:handleAmount(credits),password,adminName:currentUser.displayName,adminEmail:currentUser.email};

        const options={
            method:"POST",
            headers:{'Content-Type':"application/json"},
            body:JSON.stringify({orderData})
        }
        const response= await fetch(base_url+"api/ivr-payment",options);
        const result=await response.json();
        if(result.ok){
            alert(result.msg);
        }
        else{
            alert(result.msg);
        }
        setBusinessName("");
        setCredits(0);
        setPassword("");
        setVal(e=>e+1);
        setShowModal('');
    }
    const handleAmount=(credits)=>{
        if(credits<1000){
            return credits;
        }
        else if(credits<2000){
            return credits-credits*0.1;
        }
        else if(credits<3000){
            return credits-credits*0.2;
        }
        else if(credits<4000){
            return credits-credits*0.3;
        }
        else if(credits<5000){
            return credits-credits*0.4;
        }
        else if(credits>=5000){
            return credits-credits*0.5;
        }
    }

 
   
    useEffect(()=>{
        
        const getOrdersData=async()=>{

            const ordersRef= collection(db,"ivr-orders");
           
            const response= await getDocs(ordersRef);
            const reponseFormat= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            console.log(reponseFormat);

            let formattedOrders = [];

            reponseFormat.forEach(business => {
                const { businessName, orders } = business;
                const formattedBusinessOrders = orders.map(order => {
                    const date = new Date(order.order_time);
                    const formattedDate = date.toISOString().slice(0, 19).replace('T', ' ')
                    return{
                    businessName,
                    order_amount: order.order_amount,
                    order_credits: order.order_credits,
                    date: formattedDate,
                    adminName:order.adminName || "",
                    order_id: order.order_id || "",
                    status: order.order_status}
                });
                formattedOrders = [...formattedOrders, ...formattedBusinessOrders];
            });
    
            // Sort the formatted orders by date
            formattedOrders.sort((a, b) => new Date(b.date) - new Date(a.date));
    
            setOrdersData(formattedOrders);
            // reponseFormat.sort((a,b)=>{
            //     const datea=new Date(a.order_time);
            //     const dateb=new Date(b.order_time);
            //     return dateb-datea;
            // })
            // setOrdersData(reponseFormat);
        }
        getOrdersData();
        const getBusinessData=async()=>{
            const businessRef=collection(db,"registered-businesses");
            const businesses=await getDocs(businessRef);
            const businessFormat=businesses.docs.map(doc=>({...doc.data(),id:doc.id}));
            console.log(businessFormat);
            setBusinessData(businessFormat);
        }
        getBusinessData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[val])
    // const [deletingId,setDeletingId]=useState("");

    return(
       <>
       <NewSidebar/>
       <div className='recordingusers-container'>
            <div className='voicecampaign-action-bar'>
                <span className="action-gap">
                    <h3 className="action-title">Admin{` > IVR Payment Logs`}</h3>
                    <p className="action-text">Total Payments : {ordersData?.length}</p>
                </span>
            
            <button className="" 
                style={{padding:"6px",height:"54px",width:"140px",display:"flex",alignItems:"center",gap:"10px",borderRadius:"7px",border:"none",paddingLeft:"11px",paddingRight:"11px",marginTop:"34px",marginRight:"10px",cursor:"pointer",}} 
                onClick={()=>{setShowModal('add')}}>
                <h3>Add Credits</h3> 
                <FaPlus className="plus-icon" />
            </button>
        </div>
            <table className='table-style'>
                <thead>
                    <tr>
                        <th className='slim'>S.no</th>
                        <th className='slim'>Business Name</th>
                        <th className='slim3'>Order Date</th>
                        <th className="slim">Order Amount</th>
                        <th className="slim">Order Credits</th>
                        <th className='slim'>Status</th>
                        <th className="slim">Paid By</th>
                    </tr>
                </thead>
                <tbody>
                    {ordersData && 
                    ordersData.map((order,index)=>{
                        const amount=order.order_amount;
                        const credits=order.order_credits;
                        const businessName=order.businessName;
                        const status=order.status;
                        const date=order.date;
                        const adminName=order.adminName;
                        return (
                            <tr key={index}>
                                <td className=''>{index+1}</td>
                                <td className=''>{businessName}</td>
                                <td className=''>{date}</td>
                                <td className=''>{amount}</td>
                                <td className="">{credits}</td>
                                <td className=''>
                                   {adminName? `${status}`: status}
                                </td>
                                <td>{adminName?`${adminName}`:"customer"}</td>
                            </tr>
                        )})}
                    
                </tbody>
            </table>
            {showModal && 
        <div className='campaignModalContainer'>

            <div className='campaignForm3'>
                <div className='modal-header'>
                    <h2 style={{color:"#363534",marginLeft:'17px'}}>Add Credits</h2>
                    <ImCross style={{cursor:"pointer"}} onClick={()=>{setShowModal('')}} />
                </div>
                <hr/>
                <label className="labels">Available Businesses</label>
                <select value={currentBusiness} onChange={e=>{setCurrentBusiness(e.target.value)}}
                className="campaignInput" >
                    <option value="" disabled selected>Select Business</option>
                    {businessData && businessData.map((business,index)=>{return(<option key={index}>{business?.businessName}</option>)})}
                </select>
                {/* <input type="text" className="campaignInput" placeholder="Enter name" value={name} onChange={(e)=>{setName(e.target.value)}} /> */}
                <label className="labels">Credits</label>
                <input type="number" className="campaignInput" placeholder="Enter Credits" value={credits} onChange={(e)=>{setCredits(e.target.value)}} />
                {credits!==0 && (
                    <>
                    <label className="labels">Amount of Credits</label>
                    <input type="text" className="campaignInput" placeholder="Cost of Credits" value={handleAmount(credits)} />
                    </>)}
                <label className="labels">Password</label>
                <input type="text" className="campaignInput" placeholder="Enter password" value={password} onChange={(e)=>{setPassword(e.target.value)}} />

                <button className="modal-submit"  onClick={()=>{handleAddItem()}}>Submit</button>
            </div>

        </div>}
        {/* {deletingId && <DeleteItem id={deletingId} handleClose={setDeletingId} handleDelete={deleteRecord} />} */}
        </div>
       </>
    )
}

export default PaymentHistory;