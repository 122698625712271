import {useState,useEffect} from 'react';
import './App.css';
import Navbar from './Pages/Navbar';
// import Sidebar from './Pages/Sidebar';
import Hero from './Pages/Hero';
import { dataContext } from './Context/data';
import DataModel from './Pages/DataModel';
// import NewSidebar from './Pages/NewSidebar';
import {Route,Routes,BrowserRouter} from 'react-router-dom'
import About from './Pages/About';
import Recording from './Pages/Recording';
import RecordingUsers from './Pages/RecordingUsers';
import VoiceCampaign from './Pages/VoiceCampaign';
import VoiceCampaignDetails from './Pages/VoiceCampaignDetails';
import RecordingGroups from './Pages/RecordingGroups';
import SignIn from './Pages/SignIn';
import ProtectedRoute from './Pages/ProtectedRoute';
import UsersAccount from './Pages/UsersAccount';
import SubAdmins from './Pages/Subadmins';
import VoiceReport from './Pages/VoiceReport';
import BusinessRegister from './Pages/BusinessRegister';
import BusinessLogin from './Pages/BusinessLogin';
import Businesses from './Pages/Businesses';
import BusinessProfile from './Pages/BusinessProfile';
import BusinessUsers from './Pages/BusinessUsers';
import Cookies from 'js-cookie';
import BusinessUserRegister from './Pages/BusinessUserRegister';
import UserConfirmation from './Pages/UserConfirmation';
import VoiceAccount from './Pages/VoiceAccount';
import ProtectedRoute2 from './Pages/ProtectedRoute2';
import LandingPage from './Pages/LandingPage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CashFree from './cashfree/cashfree';
import BusinessesAccount from './Pages/BusinessesAccount';
import Offices from './Pages/Offices';
import AboutVision from './Pages/AboutVision';
import AboutMission from './Pages/AboutMission';
import PaymentHistory from './Pages/PaymentHistory';
import ReelTemplate from './Pages/ReelTemplate';
import FooterRefunds from './Pages/FooterRefunds';
import FooterPolicy from './Pages/FooterPrivacyPolicy';
import FooterDiscalimer from './Pages/FooterDisclaimer';
import FooterTermsConditions from './Pages/FooterTermsConditions';
import ProviderOptions from './Pages/ProviderOptions';
import Chatbot from './Pages/Chatbot';
import CreateChatbot from './Pages/CreateChatbot';
import ChatTest from './Pages/ChatTest';
import ChatbotIntegration from './Pages/ChatbotIntegration';
import BusinessRequests from './Pages/BusinessRequests';
import BusinessChats from './Pages/BusinessChats';
import BusinessChatDemo from './Pages/BusinessChatDemo';

const App=()=> {
  const [data,setData]=useState([
            {name:'charan',number:'9989786348',check:false,status:"NILL"},
            {name:'chard',number:'8309370811',check:false,status:"NILL"},
            {name:'ruhi',number:'8008717091',check:false,status:"NILL"},
            {name:'mahesh',number:'80087172091',check:false,status:"NILL"},
            {name:'janu',number:'8978673461',check:false,status:"NILL"},
            {name:'raju',number:'8978173461',check:false,status:"NILL"},
            {name:'sriman',number:'9478673461',check:false,status:"NILL"},
            {name:'subha',number:'7674083953',check:false,status:"NILL"}]);

  const [message,setMessage]=useState("");
  const [attachFile,setAttachFile]=useState(null);
  const [details,setDetails]=useState([]);
  const[displayDataModel,setDisplayDataModel]=useState(false);
  const [runningBusiness,setRunningBusiness]=useState("");
  const [currentUser,setCurrentUser]=useState("");
  const [isAdmin,setIsAdmin]=useState(false);

  useEffect(() =>{
    const getData=async()=>{
        console.log(data);
    }
    getData();
    const loggedInUser=Cookies.get("userData")
    if(loggedInUser && currentUser===""){
      let userBe=JSON.parse(loggedInUser)
      setCurrentUser(userBe);
    }
    const busines=Cookies.get("currentBusiness");
    if(busines && runningBusiness===""){
      let parsedBusines=JSON.parse(busines);
      console.log("yes set the business");
      setRunningBusiness(parsedBusines);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  },[data,runningBusiness])

  return (
    <div className="App">
      <BrowserRouter>
        <dataContext.Provider value={{setData,data,message,setMessage,details,setDetails,displayDataModel,setDisplayDataModel,attachFile,setAttachFile,runningBusiness,setRunningBusiness,currentUser,setCurrentUser,isAdmin,setIsAdmin}}>
        <GoogleOAuthProvider clientId="903659865919-0faj0an9s17mmi5h2hvdqlerq8as14p8.apps.googleusercontent.com">
          {<Navbar />}
          {/* window.location.pathname === '/' ? 'main-page2' : */}
          <div className={ 'main-page'}>
            <Routes>
            <Route exact path="/login" element={<SignIn/>} />
            <Route exact path="/" element={<LandingPage/>}/>
            <Route exact path="/cash" element={<CashFree/>}/>
            <Route exact path="/provider-options" element={<ProviderOptions/>}/>
            

            <Route element={<ProtectedRoute2/>}>
                <Route exact path="/business-login" element={<BusinessLogin/>}/>
                
                <Route exact path="/businesses" element={<Businesses/>} />
                <Route exact path="/business-register" element={<BusinessRegister/>}/>
                <Route exact path="/businesses-account" element={<BusinessesAccount/>}/>
                <Route exact path="/provider-options" element={<ProviderOptions/>}/>
                <Route exact path="/business-requests" element={<BusinessRequests/>}/>
                <Route exact path="/business-chats" element={<BusinessChats/>} />
                <Route exact path="/business-chat-demo" element={<BusinessChatDemo/>} />

            </Route>
            
            <Route exact path="/register/" element={<BusinessUserRegister/>}/>
            <Route exact path="/user-confirmation/" element={<UserConfirmation/>}/>
            <Route exact path="/refunds" element={<FooterRefunds/>}/>
            <Route exact path="/disclaimer" element={<FooterDiscalimer/>}/>
            <Route exact path="/privacy-policy" element={<FooterPolicy/>}/>
            <Route exact path="/terms-conditions" element={<FooterTermsConditions/>}/>

              <Route element={<ProtectedRoute/>}>
                  <Route exact path="/about" element={<About/>} />
                  <Route exact path="/mission" element={<AboutMission/>} />
                  <Route exact path="/vision" element={<AboutVision/>} />
                  <Route exact path="/chatbot" element={<Chatbot/>}/>
                  
                  <Route exact path="/whatsapp-sender" element={<Hero/>} />
                  <Route exact path="/voice-groups" element={<RecordingGroups/>} />
                  <Route exact path="/voice-users/:groupId" element={<RecordingUsers/>} />
                  <Route exact path="/voice-recording" element={<Recording/>} />
                  <Route exact path="/voice-campaign" element={<VoiceCampaign/>} />
                  <Route exact path="/voice-campaign/:campaignId" element={<VoiceCampaignDetails/>} />
                  <Route exact path="/voice-report" element={<VoiceReport/>}/>
                  <Route exact path="/settings-account" element={<UsersAccount/>} />
                  <Route exact path="/subadmins" element={<SubAdmins/>} />
                  <Route exact path="/ivr-payment-history" element={<PaymentHistory/>} />
                  <Route exact path="/business-info" element={<BusinessProfile/>} />
                  <Route exact path="/business-users" element={<BusinessUsers/>}/>
                  <Route exact path="/voice-account" element={<VoiceAccount/>}/>
                  <Route exact path="/reels-template" element={<ReelTemplate/>}/>
                  <Route exact path="/manage-bot" element={<CreateChatbot/>} />
                  <Route exact path="test-chat" element={<ChatTest/>} />
                  <Route exact path="/chat-integration" element={<ChatbotIntegration/>} />

                  {/* <Route exact path="" element={</>}/> */}
                  
              </Route>
              
            </Routes>
          </div>
          <DataModel  />
          </GoogleOAuthProvider>
        </dataContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
