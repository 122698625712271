import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import './index.css'; 
import { useContext, useEffect, useState } from 'react';
import { auth, db, googleAuth } from '../../Config-firebase/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { dataContext } from '../../Context/data';

const SignIn=()=>{
    const navigate=useNavigate();
    const [authUsers,setAuthUsers]=useState(null);
    const [authSubadmins,setAuthSubadmins]=useState(null);
    const [googleUser,setGoogleUser]=useState('');
    const {setCurrentUser}=useContext(dataContext);

    useEffect(()=>{
        const getAuthorizedUsers=async()=>{
            const authorizedUsersRef= collection(db,"authorized-users");
           const response=await getDocs(authorizedUsersRef);
           const reponseFormate= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
           const emails=reponseFormate.map(r=>r.email);
          //  console.log(emails);
           setAuthUsers(emails);
        }
        getAuthorizedUsers();
        const getAuthorizedSubAdmins=async()=>{
          const authorizedSubAdminsRef= collection(db,"authorized-subadmins");
           const response=await getDocs(authorizedSubAdminsRef);
           const reponseFormate= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
           const emails=reponseFormate.map(r=>r.email);
          //  console.log(emails);
           setAuthSubadmins(emails);
        }
        getAuthorizedSubAdmins();
    },[])

    const handleGoogleLogin = async () => {
    
        try {
          const result = await signInWithPopup(auth, googleAuth);
         
          const credential =GoogleAuthProvider.credentialFromResult(result);
          setGoogleUser({name:result.user.displayName,email:result.user.email,uid:result.user.uid})
          console.log(credential,credential.accessToken);
          console.log(result.user,"user");
          
          if (result.user) {
          
            console.log(result.user);
            // console.log(authUsers,"sdfffffffffffffffffffffff")
            const authCheck=authUsers.includes(result.user.email) || authSubadmins.includes(result.user.email);
            console.log(authCheck,"checking user")
            if(authCheck){
                Cookies.set('userData', JSON.stringify(result.user),{expires:10});
                setCurrentUser(result.user);
                console.log("success");
                navigate('/');
            }
          }
        } catch (error) {
          alert("not a valid user");
          console.log(error);
        }
      };

    return(
        <div className='signin-container'>
            <img className='wizImage' src='wizmedia.png' alt="mobishaala"/>
            <div className='sign-inner'>
                <h2 style={{color:"#730A0A"}}>Wizzmedia Buzz Login</h2>
                <button onClick={handleGoogleLogin} className='socialmedia-btn'>
                    <img src="google.png" className='googleImage' alt="Google" /> <h3>Google SignIn</h3>
                </button>
            </div>
           
        </div>
    )
}

export default SignIn;