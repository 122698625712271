import { useEffect, useState} from 'react';
import { IoMdHome } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa6";
import { RiArrowDropDownLine } from "react-icons/ri";
import { LiaSmsSolid } from "react-icons/lia";
import { MdOutlineMail } from "react-icons/md";
import { BsCameraReels } from "react-icons/bs";
import { MdSettings } from "react-icons/md";
import { RiArrowDropUpLine } from "react-icons/ri";
import { MdOutlineKeyboardVoice } from "react-icons/md";
import { SiApostrophe } from "react-icons/si";
import { MdAdminPanelSettings } from "react-icons/md";
import { BiSolidBusiness } from "react-icons/bi";
import { FaRobot } from "react-icons/fa";
import './index.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../Config-firebase/firebase';

const NewSidebar=()=>{

    const [adminSelected,setAdminSelected]=useState(false);
    const [aboutSelected,setAboutSelected]=useState(false);
    const [userData,setUserData]=useState("");
    const [activeTab,setActiveTab]=useState("");
    const [currentAdmins,setCurrentAdmins]=useState(["vijay.wiz@gmail.com",]);
  
    useEffect(()=>{
        const usersData=Cookies.get('userData');
        if(usersData){
            setUserData(JSON.parse(usersData));
        }
        const getAdmins=async()=>{
            const adminRef=collection(db,"authorized-users");
            const reponse= await getDocs(adminRef);
            const admin= reponse.docs.map((doc,index)=>({...doc.data(),id:doc.id}));
            const admins=admin.map(e=>e.email)
            console.log(admins);
            setCurrentAdmins(admins);
        }
        getAdmins();

    },[])
    // const currentAdmins=['charanpraveen9989@gmail.com',"vijay.wiz@gmail.com","vijay.wiz@gmail.com","wizmediahzbteam@gmail.com","binarysculptors@gmail.com"];

    const navigate=useNavigate();
    // [{
    //     header:'Home',
    //     subHeaders:[{
    //         title:'Vision',
    //         path:'/vision',
    //     },{
    //         title:'Mission',
    //         path:'/mission',
    //     },{
    //         title:'About',
    //         path:'/about',
    //     },]
    // },]
    const sectionData=[{header:"Business",subHeaders:[{title:'Info',path:'/business-info',},{title:'Users',path:'/business-users',}]//,{title:'Stats',path:'/business-stats',},{title:'Billing',path:'/business-report'}
    },{
        header:'Voice(IVR)',subHeaders:[{title:'Recording',path:'/voice-recording',},{title:'Users',path:'/voice-groups',},{title:'Campaign',path:'/voice-campaign',},{title:'Report',path:'/voice-report',},{title:'Account',path:'/voice-account',}]
    },{
        header:'AI Chat Bot',subHeaders:[{title:'Build',path:'/manage-bot'},{title:"Behaviour",path:""},{title:'Chat',path:'/test-chat'},{title:"Configure"},{title:"Activity Log"},{title:"Leads",path:""},{title:"Action"},{title:'Integration',path:'/chat-integration'}]
    },{
        header: 'Whatsapp',subHeaders: [{ title: 'Instances', path: '/whatsapp-sender' },{ title: 'Users', path: '/whatsapp-users' },{ title: 'Campaign', path: '/whatsapp-campaign' },{ title: 'Report', path: '/whatsapp-report' },      { title: 'Settings', path: '/whatsapp-settings' },],
      },
    {
        header:'SMS',subHeaders:[{title:'Template',path:'/sms-template',},{title:'Users',path:'/sms-users',},{title:'Campaign',path:'/sms-campaign',},{title:'Report',path:'/sms-report',},{title:'Settings',path:'/sms-settings',}]
    },
    {
        header:'Email',subHeaders:[{title:'Template',path:'/email-template',},{title:'Users',path:'/email-users',},{title:'Campaign',path:'/email-campaign',},{title:'Report',path:'/email-report',},{title:'Settings',path:'/email-settings',}]
    },
    {
        header:'Reels',subHeaders:[{title:'Accounts',path:'/reels-accounts',},{title:'Template',path:'/reels-template',},{title:'Users',path:'/reels-users',},{title:'Campaign',path:'/reels-campaign',},{title:'Report',path:'/reels-report',},{title:'Settings',path:'/reels-settings',}]
    },
    {
        header:'Posts',subHeaders:[{title:'Accounts',path:'/posts-accounts',},{title:'Template',path:'/posts-template',},{title:'Users',path:'/posts-users',},{title:'Campaign',path:'/posts-campaign',},{title:'Report',path:'/posts-report',},{title:'Settings',path:'/posts-settings',}]
    },
    {
        header:'Settings',subHeaders:[{title:'Help',path:'/settings-help',},{title:'Support',path:'/settings-support',},{title:'Account',path:'/settings-account',}]
    },
]

const icons=[<BiSolidBusiness />,<MdOutlineKeyboardVoice />,<FaRobot />,<FaWhatsapp />,<LiaSmsSolid/>,<MdOutlineMail/>,<BsCameraReels/>,<SiApostrophe />,<MdSettings/>] 

    const listOfTitles=sectionData.length;
    const [sectionVisibilities, setSectionVisibilities] = useState(Array(listOfTitles.length).fill(false));
    const toggleVisibility = (index) => {
        setSectionVisibilities((prevVisibilities) => {
          const updatedVisibilities = [...prevVisibilities];
          updatedVisibilities[index] = !prevVisibilities[index]; // Toggle state for clicked list
          return updatedVisibilities;
        });
      };

  return (
    <div className="sidebar-container">
        <div className=''>
        {<>
            { 
            // currentAdmins?.includes(userData?.email)&&
            true &&
            <span className='sidebar-options' onClick={()=>{setAboutSelected((s)=>!s)}}>
                <div className='sider'>
                    <IoMdHome />
                    <p style={{cursor:"pointer"}}>About</p>
                </div>
                {!aboutSelected ?<RiArrowDropDownLine className='arrowDrop' />:
                <RiArrowDropUpLine className='arrowUp'/>}
            </span>}
            {aboutSelected  &&
             <span className="section-data">
                <p className="section-subheader" onClick={()=>{navigate(`${'/vision'}`)}}>Vision</p>
                <p className="section-subheader" onClick={()=>{navigate(`${ '/mission'}`)}}>Mission</p>
                <p className="section-subheader" onClick={()=>{navigate(`${'/about'}`)}}>About</p>
            </span>}
            </>
            }
            {sectionVisibilities&&
            sectionData.map((section,index)=>{
                return(
                    <>
                    <span className='sidebar-options' onClick={()=>toggleVisibility(index)}>
                        <div className='sider'>
                            {icons[index]}
                            <p style={{cursor:"pointer"}}>{section.header}</p>
                        </div>
                            {!sectionVisibilities[index]?<RiArrowDropDownLine className='arrowDrop' />:
                            <RiArrowDropUpLine className='arrowUp'/>}
                    </span>
                    {sectionVisibilities[index]&& section.subHeaders && (<span className="section-data" >
                        {section.subHeaders.map((subheader,inde)=>
                        <>
                            <p className={`${section-subheader} ${activeTab===subheader.path && 'showHigh'}`} onClick={()=>{navigate(`${subheader.path || '/'}`);setActiveTab(`${subheader.path}`)}}>{subheader?.title}</p>
                        </>)}
                    </span>)
                    }
                    </>
                )})
            }
            {<>
            { currentAdmins?.includes(userData?.email)&&
            <span className='sidebar-options' onClick={()=>{setAdminSelected((s)=>!s)}}>
                <div className='sider'>
                    <MdAdminPanelSettings />
                    <p style={{cursor:"pointer"}}>Admin</p>
                </div>
                {!adminSelected ?<RiArrowDropDownLine className='arrowDrop' />:
                <RiArrowDropUpLine className='arrowUp'/>}
            </span>}
            {adminSelected &&
             <span className="section-data">
                <p className="section-subheader" onClick={()=>{navigate(`${ '/subadmins'}`)}}>Sub Admins</p>
                <p className="section-subheader" onClick={()=>{navigate(`${ '/ivr-payment-history'}`)}}>IVR Payment Logs</p>
                <p className="section-subheader" onClick={()=>{navigate(`${ '/provider-options'}`)}}>Provider Options</p>
                <p className="section-subheader" onClick={()=>{navigate(`${'/admin-logs'}`)}}>Logs</p>
            </span>}
            </>
            }
            
            {/* <span className='sidebar-options' onClick={()=>{setHomeSelected((s)=>!s)}}>
                <p>Home</p>
                {!homeSelected ?<RiArrowDropDownLine className='arrowDrop' />:
                <RiArrowDropUpLine className='arrowUp'/>}
            </span>
            {homeSelected &&
             <span>
                <p>Vision</p>
                <p>Mission</p>
                <p>About</p>
            </span>}
            <span className='sidebar-options' onClick={()=>{setHomeSelected((s)=>!s)}}>
                <p>Home</p>
                {!homeSelected ?<RiArrowDropDownLine className='arrowDrop' />:
                <RiArrowDropUpLine className='arrowUp'/>}
            </span>
            {homeSelected &&
             <span>
                <p>Instance</p>
                <p>Users</p>
                <p>Message</p>
                <p>Report</p>
                <p>Settings</p>
                
            </span>} */}
        </div>
      
    </div>
  );
}

export default NewSidebar;