import Cookies from 'js-cookie';
// import {useNavigate} from 'react-router-dom';
import { collection, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';
import'./index.css';
import { useEffect, useState } from 'react';
import NewSidebar from '../NewSidebar';
import { db } from '../../Config-firebase/firebase';
// import { ImCross } from "react-icons/im";
import DeleteItem from '../DeleteItem';
import { AiFillDelete } from "react-icons/ai";

const BusinessUsers=()=>{
    // const navigate=useNavigate();
    const [businessData,setBusinessData]=useState('');
    const [usersData,setUsersData]=useState('');
    const [deletingId,setDeletingId]=useState("");
    const [val,setVal]=useState(0);

    useEffect(()=>{

        const usersData=Cookies.get('currentBusiness');
    if(usersData && businessData===''){
        setBusinessData(JSON.parse(usersData));
    }
    if(businessData===''){
        return;
    }
    const getUsers=async()=>{
        const businessCollectionRef=collection(db,"business-customers");
            const allDocsQuery=query(businessCollectionRef,where('businessName','==',businessData.businessName));
            const response= await getDocs(allDocsQuery);
            const reponseFormat= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            reponseFormat.sort((a, b) => {
                const dateA = new Date(a.date); // Convert strings to Date objects
                const dateB = new Date(b.date);
                return dateB - dateA; // Descending order (recent first)
              });
            setUsersData(reponseFormat);
    }
    getUsers();
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[businessData,val])
    // const normalData=Cookies.get('currentBusiness');
    // const bData=JSON.parse(normalData)
    const deleteUser=async(id)=>{
        const reqDoc= doc(db,"business-customers",id);
        const response=await deleteDoc(reqDoc);
        console.log(response);
        setVal(e=>e+1);
    }
    return(
        <>
        <NewSidebar/>
        <div className='business-users-container' style={{width:"100%"}}> {/* customers */} 
            <div className='voicecampaign-action-bar'>
                <span className="action-gap">
                <h3 className="action-title">Business {`> Users` }</h3>
                        <p className="action-text">Total Users : {usersData?.length}</p>
                    </span>
                
            </div>
            <table className='table-style'>
                <thead>
                    <tr>
                        <th className='slim'>S.NO</th>
                        <th className='slim-5' style={{width:"15%"}}>Date</th>
                        <th className='slim-3' style={{width:"13%"}}>Name</th>
                        <th className='slim'>Number</th>
                        <th className='slim'>Email</th>
                        <th className='slim-1'style={{width:"15%"}}>Enquiries</th>
                        <th className='slim'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {usersData &&
                    usersData.map((user,index) =>{
                        const id=user.id;
                        const name=user.name;
                        const number=user.number;
                        const details=user.details;
                        const email=user.email;
                        const date=user.date;
                        return(
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{date}</td>
                                <td>{name}</td>
                                <td>{number}</td>
                                <td>{email}</td>
                                <td>{details|| "None"}</td>
                                <td className='row-update'>
                                    <AiFillDelete className="delete-row" onClick={()=>{setDeletingId(id)}} />
                                </td>
                            </tr>
                        )})}
                    
                </tbody>
            </table>
            {deletingId && <DeleteItem id={deletingId} handleClose={setDeletingId} handleDelete={deleteUser} />}
        </div>
        </>
    )
}

export default BusinessUsers;