import {useState} from 'react';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';

import { db } from '../../Config-firebase/firebase';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa6";
import NewSidebar2 from '../NewSideBar2';
import './index.css';
import { base_url } from '../../utils/new';

const BusinessRegister=()=>{
    // console.log("entered")
    const [businessDetails, setBusinessDetails] = useState({
        businessName: "",
        businessLogo: "",
        businessOwnerName: "",
        businessOwnerNumber: "",
        businessEmail: "",
        businessGSTNumber: "",
        businessAddress: "",
        businessPincode: "",
        businessCategory: "",
        businessWebsite: "",
        businessFacebookPage: "",
        businessInstagram: "",
        businessGooglePage: "",
        businessYoutubeChannel: "",
        businessTurnoverRange: "",
      });
      const [avatar, setAvatar] = useState(""); 
      const [imageData,setImageData]=useState("");

      const navigate=useNavigate();

      const handleChange = (event,element) => {
        setBusinessDetails({
          ...businessDetails,
          [element]: event.target.value,
        });
      };

    //   const capitalizeAndSeparateWords = (str) => {
    //     return str
    //       .split(/([A-Z][a-z]+)/) // Split at uppercase followed by lowercase
    //       .map((word) => (word.length > 1 ? word.charAt(0).toUpperCase() + word.slice(1) : word)) // Capitalize first letter of each word
    //       .join(' '); // Join back with non-breaking spaces
    //   };
    const handleAvatarChange = (event) => {
        const file = event.target.files[0];
        console.log(file);
    
        if (!file?.type.startsWith('image/')) {
          console.log("Please insert a valid image file.");
          return;
        }
    
        if(file?.size>=50*1024*1024){
            console.log("file is very large cannot upload (>50 mb)"); //here notify
            alert("file is very large cannot upload (>50 mb)")
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setAvatar(reader.result); // Still set for preview
          const base64Data = reader.result.split(',')[1]; // Extract base64 data
          // Call a new function to send data to backend (explained below)
          setImageData(base64Data);
          // console.log(base64Data);
        };
      };
    

      const createBusiness=async()=>{
            if(imageData.length ===0){
                alert("Logo is empty ");
                return ;
            }
            else{
                const response = await fetch(base_url+'api/getimageurl', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body:JSON.stringify({imageData})
                });
                console.log(response,"response from frontend");
                const result=await response.json();
    
                if (response.ok) {
                    // const updatedBusinessDetails = {
                    //     ...businessDetails,
                    //     "businessLogo": result.fileUrl,
                    //   };
                
                    //   // Set businessDetails with the updated logo URL
                    //   setBusinessDetails(updatedBusinessDetails);
                
                console.log(businessDetails,"herrrrr");
                const {businessName,businessLogo,businessOwnerName,businessEmail,businessOwnerNumber,businessAddress,businessCategory,businessGSTNumber,businessWebsite,businessFacebookPage,businessGooglePage,businessTurnoverRange,businessYoutubeChannel,businessPincode}=businessDetails;
                if(!businessName && ! businessLogo && !businessOwnerName && !businessEmail && !businessOwnerNumber && !businessAddress && !businessCategory){
                console.log(businessDetails)
                    alert("some fields are missing");
                    return;
                }
                else{
                    const todayDate = new Date();
                    const yyyy = todayDate.getFullYear();
                    let mm = todayDate.getMonth() + 1; 
                    let dd = todayDate.getDate();
                    const hour = todayDate.getHours();
                    const minute = todayDate.getMinutes();
                    const second = todayDate.getSeconds();

                    const hourString = hour.toString().padStart(2, "0");
                    const minuteString = minute.toString().padStart(2, "0");
                    const secondString = second.toString().padStart(2, "0");

                    mm = String(mm).padStart(2, '0'); 
                    dd = String(dd).padStart(2, '0');

                    const formattedDate = dd + '-' + mm + '-' + yyyy+"  "+hourString+":" +minuteString+ ":"+secondString;
                    const businessCollectionRef= collection(db,"registered-businesses");
                    const response= await addDoc(businessCollectionRef,{
                        date:formattedDate,
                        businessName: businessName || "",
                        businessLogo: result.fileUrl || "",
                        businessOwnerName: businessOwnerName || "",
                        businessOwnerNumber: businessOwnerNumber || "",
                        businessEmail: businessEmail || "",
                        businessGSTNumber: businessGSTNumber ||  "",
                        businessAddress: businessAddress || "",
                        businessPincode: businessPincode || "",
                        businessCategory: businessCategory || "",
                        businessWebsite:businessWebsite || "",
                        businessFacebookPage:businessFacebookPage || "",
                        businessInstagram: "",
                        businessGooglePage:businessGooglePage || "",
                        businessYoutubeChannel: businessYoutubeChannel || "",
                        businessTurnoverRange: businessTurnoverRange|| "",
                        ivrCredits:10
                      })
                    console.log(response);
                    const userRef= collection(db,"business-users");
                    const usersData= Cookies.get('userData');
                    let userData;
                        if(usersData){
                            userData=(JSON.parse(usersData));
                        }
                        
                    const userDetails={name:userData.displayName,email:businessEmail.email,businessId:response.id,businessName:businessName}
                    await addDoc(userRef,userDetails)
                    // if(userData.email!==businessEmail){
                    //     const userDetails={name:businessName,email:businessEmail,businessId:response.id,businessName:businessName}
                    // await addDoc(userRef,userDetails)
                    // }

                    setBusinessDetails({
                        businessName: "",
                        businessLogo: "",
                        businessOwnerName: "",
                        businessOwnerNumber: "",
                        businessEmail: "",
                        businessGSTNumber: "",
                        businessAddress: "",
                        businessPincode: "",
                        businessCategory: "",
                        businessWebsite: "",
                        businessFacebookPage: "",
                        businessInstagram: "",
                        businessGooglePage: "",
                        businessYoutubeChannel: "",
                        businessTurnoverRange: "",
                    });
                    navigate("/");
            }
        }
        else{
            alert("backend not working properly while uplaoding image")
            return;
        }
        }
      }

    return(
        <>
        <NewSidebar2/>
        <div className="recordingusers-container">
            <div className='Business-action-bar'>
                <span className="action-gap">
                    <h3 className="action-title left-icon-action"> <FaArrowLeft style={{cursor:"pointer"}} onClick={()=>{navigate(-1)}} /> Register New Business</h3>
                </span>
            
            </div>
            {/* <div className='register-table-style'>
            <table className='my-table-style'>
                <thead>
                    <tr>
                        <th className='slimer'>Parameters</th>
                        <th className='slimer'>Values</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(businessDetails).map(([key, value], index) => (
                        <tr key={index}>
                        <td className="slimer parameter-cell">{capitalizeAndSeparateWords(key)}</td>
                        <td className="slimer">
                            <input
                            type="text"
                            name={key}
                            value={value}
                            className='businessInput'
                            onChange={handleChange}
                            />
                        </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div> */}
            <div className='business-register-form-container'>
                <div className=''>
                    <div className='wrapper'>
                        <div className='input-data'>
                            <input type="text" required className='' onChange={(event)=>handleChange(event,"businessName")} value={businessDetails.businessName} />
                            <div class="underline"></div>
                            <label>Business Name <span style={{color: "red"}}>*</span></label>
                        </div>
                    </div>
                    <div className='wrapper'>
                        <div className='input-data'>
                        
                        <input type="text" required className='' onChange={(event)=>handleChange(event,"businessOwnerName")} value={businessDetails.businessOwnerName} />
                       
                        <label>Business Owner Name <span style={{color: "red"}}>*</span></label>
                        </div>
                    </div>
                    <div className='wrapper'>
                        <div className='input-data'>
                       
                        <input type="" required  className='' onChange={(event)=>handleChange(event,"businessOwnerNumber")} value={businessDetails.businessOwnerNumber} />
                        <label>Business Number <span style={{color: "red"}}>*</span></label>
                        </div>
                    </div>
                    <div className='wrapper'>
                        <div className='input-data'>
                        
                        <input type="" required  className='' onChange={(event)=>handleChange(event,"businessEmail")} value={businessDetails.businessEmail} />
                        <label>Business Email <span style={{color: "red"}}>*</span></label>
                        </div>
                    </div>
                    <div className='wrapper'>
                        <div className='input-data'>
                        
                        <input type="" required className='' onChange={(event)=>handleChange(event,"businessGSTNumber")} value={businessDetails.businessGSTNumber} />
                        <label>Business GST Number <span style={{color: "red"}}>*</span></label>
                        </div>
                    </div>
                    <div className='wrapper'>
                        <div className='input-data'>
                        
                        <input type="text" required  className='' onChange={(event)=>handleChange(event,"businessAddress")} value={businessDetails.businessAddress} />
                        <label>Business Address <span style={{color: "red"}}>*</span></label>
                        </div>
                    </div>
                    
                </div>
                <div className=''>
                    <div className='logo-container'>
                    <span className="avatar-label">Business Logo</span>
                        <label htmlFor="avatar" className='avatar-labe'>
                            {avatar ? (
                            <img src={avatar} alt="Selected avatar" className="avatar-preview" />
                            ) :(
                            <img src="cameraback.jpg"  alt="choose" className="choose-image" />
                            )}
                        </label>
                        {<input type="file" id="avatar" name="avatar" accept="image/*" className='' onChange={handleAvatarChange} value={businessDetails.businessLogo} />}
                        
                    </div>
                    <div className='wrapper'>
                        <div className='input-data'>
                       
                        <input type="" required className='' onChange={(event)=>handleChange(event,"businessPincode")} value={businessDetails.businessPincode} />
                        <label>Business Pincode <span style={{color: "red"}}>*</span></label>
                        </div>
                    </div>
                    <div className='wrapper'>
                        <div className='input-data'>
                        
                        <input type="text" required  className='' onChange={(event)=>handleChange(event,"businessWebsite")} value={businessDetails.businessWebsite} />
                        <label>Business Website <span style={{color: "red"}}>*</span></label>
                    </div>
                    </div>
                    <div className='wrapper'>
                        <div className='input-data'>
                       
                        <input type="text" required  className='' onChange={(event)=>handleChange(event,"businessYoutubeChannel")} value={businessDetails.businessYoutubeChannel} />
                        <label>Business Youtube Channel</label>
                    </div>
                    </div>
                    <div className='wrapper'>
                        <div className='input-data'>
                       
                        <input type="text" required  className='' onChange={(event)=>handleChange(event,"businessTurnoverRange")} value={businessDetails.businessTurnoverRange} />
                        <label>Business Turn Over Range</label>
                    </div>
                    </div>
                </div>
            </div>
            <button className='create-business-btn' onClick={()=>{createBusiness()}}>Create</button> 
        </div>
        </>
    )
}

export default BusinessRegister;