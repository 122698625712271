import { useEffect, useState } from "react";
import "./index.css";
import { db } from "../../Config-firebase/firebase";
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { base_url } from "../../utils/new";
import { useNavigate } from "react-router-dom";
import { MdOutlineContentCopy } from "react-icons/md";

const BusinessChats=()=>{
    const [chats,setChats]=useState([]);
    const availableChats=async()=>{
        const chatsRef=collection(db,"chat-parameters");
        const response= await getDocs(chatsRef);
        const chatsData=response.docs.map((doc)=>({...doc.data(),id:doc.id}));
        setChats(chatsData);
    }
    availableChats();
    const [showDemo,setShowDemo]=useState('');
    const [chatColor, setChatColor] = useState('#000000');
    const [chatIcon, setChatIcon] = useState('https://res.cloudinary.com/dswjg0rjx/image/upload/v1722965483/t0xh4ca7ymnoewuhl29u.png');
    const [landingImage, setLandingImage] = useState('');
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [chatKey, setChatKey] = useState('');
    const [chatId, setChatId] = useState('');
    const [chatCoordinates, setChatCoordinates] = useState('');
    const [imageData,setImageData]=useState("");
    const [businessName,setBusinessName]=useState('');
    const navigate=useNavigate();

    // const runDemo = (chatId) => {
    //     setShowDemo(chatId);

    //     // Clean up existing script tag
    //     const existingScript = document.getElementById('dynamic-chat-script');
    //     if (existingScript) {
    //         existingScript.remove();
    //     }

    //     // Create new script tag
    //     const scriptTag = document.createElement('script');
    //     scriptTag.id = 'dynamic-chat-script';
    //     scriptTag.src = `https://cdn.jsdelivr.net/gh/idontnol/wizz-aibot@v7.0.0/aichat.js`; // Adjust URL and parameter
    //     scriptTag.async = true;
    //     scriptTag.chatKey=chatId;
    //     document.body.appendChild(scriptTag);
    // }
    useEffect(()=>{

    },[chatColor])

    const addChatBot=async(event)=>{
        event.preventDefault();
        const response = await fetch(base_url+'api/getimageurl', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body:JSON.stringify({imageData})
        });
        console.log(response,"response from frontend");
        const result=await response.json();
        console.log(result,result.fileUrl);
       
        if(response.ok){
            // const newchta={chatColor,chatIcon,'landingImage':result.fireUrl,welcomeMessage,chatId,chatKey,chatCoordinates};
            // console.log(newchta);
            const chatsRef=collection(db,"chat-parameters");
            const newChat={chatColor,chatIcon,'landingImage':result.fileUrl,welcomeMessage,chatId,chatKey,chatCoordinates,businessName};
            await addDoc(chatsRef,newChat);
            setChatColor("");
            setChatIcon("");
            setWelcomeMessage("");
            setChatKey("");

        }
        
    }

    const handleImage = (event) => {
        const file = event.target.files[0];
        console.log(file);
    
        if (!file?.type.startsWith('image/')) {
          console.log("Please insert a valid image file.");
          return;
        }
    
        if(file?.size>=50*1024*1024){
            console.log("file is very large cannot upload (>50 mb)"); //here notify
            alert("file is very large cannot upload (>50 mb)")
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
         // setAvatar(reader.result); // Still set for preview
          const base64Data = reader.result.split(',')[1]; // Extract base64 data
          // Call a new function to send data to backend (explained below)
          setImageData(base64Data);
          // console.log(base64Data);
        };
      };
    const handleCopy=async(key)=>{
        console.log(key);
        try {
            await navigator.clipboard.writeText(`
                <script src="https://cdn.jsdelivr.net/gh/idontnol/wizz-aibot@main/aichat2.js" 
                chatKey=${key}>
                </script>`);
        
            console.log('Text copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    }
    
    
    return(
        <div className="chatcampaign-container">

            <div className="b-chats">
                <div className="b-chats1">
                    <h2 >Available Chats</h2>
                    {!showDemo && chats.length>0 && chats.map((chat,idx)=>{
                        const BusinessName=chat['businessName'];
                        const BusinessIcon = chat['businessLogo']; 
                        return(
                            <div className="chat-card" key={idx}>
                            <div className="chat-info">
                                <img src={ 'https://res.cloudinary.com/dswjg0rjx/image/upload/v1722965483/t0xh4ca7ymnoewuhl29u.png'} 
                                alt={`${BusinessName} Icon`} className="dc-icon" />
                                <h3 className="business-name">{BusinessName}</h3>
                            </div>
                            <MdOutlineContentCopy onClick={()=>{handleCopy(chat.id)}} style={{cursor:"pointer"}} />

                            <button className="demo-button" onClick={() => { localStorage.setItem('chat_params',JSON.stringify(chat));navigate('/business-chat-demo') }}>
                                Demo
                            </button>
                        </div>)
                    })}
                </div>
                
                <div className="b-chats2">
                <h2>Chat Configuration</h2>
                <form className="chat-form" onSubmit={addChatBot}>
                <div className="form-group22">
                    <label htmlFor="businessName">Business Name:</label>
                    <input
                    required
                        type="text"
                        id="businessName"
                        placeholder="Enter Business Name"
                        name="name"
                        onChange={(e)=>{setBusinessName(e.target.value)}}
                    />
                </div>
                <div className="form-group22">
                    <label >Chat Color:</label>
                    <input
                    
                        type="color"
                        id="chatColor22"
                        name="chatColor"
                        style={{visibility:'hidden',width:'2px'}}
                        value={chatColor}
                        onChange={(e) => setChatColor(e.target.value)}
                    />
                    <label className="color-preview" htmlFor="chatColor22" 
                    style={{ backgroundColor: chatColor,width:"39px",height:"35px",cursor:"pointer" }}></label>
                    
                    <input className="color-value"
                    style={{width:"90px"}}
                    value={chatColor}
                    onChange={(e) => setChatColor(e.target.value)}
                    />
                    
                </div>

                {/* <div className="form-group22">
                    <label htmlFor="chatIcon">Chat Icon URL:</label>
                    <input
                        type="url"
                        id="chatIcon"
                        name="chatIcon"
                        // placeholder="Enter Chat Icon URL"
                        value={chatIcon}
                        // onChange={(e) => setChatIcon(e.target.value)}
                    />
                </div> */}

                <div className="form-group22">
                    <label htmlFor="landingImage">Landing Image URL:</label>
                    <input
                    required
                        type="file"
                        id="landingImage"
                        name="landingImage"
                        // placeholder="Enter Landing Image URL"
                        // value={landingImage}
                        onChange={handleImage}
                    />
                </div>

                <div className="form-group22">
                    <label htmlFor="welcomeMessage">Welcome Message:</label>
                    <textarea
                    required
                        id="welcomeMessage"
                        name="welcomeMessage"
                        placeholder="Enter Welcome Message"
                        value={welcomeMessage}
                        onChange={(e) => setWelcomeMessage(e.target.value)}
                    ></textarea>
                </div>

                <div className="form-group22">
                    <label htmlFor="chatKey">Chat Key:</label>
                    <input
                    required
                        type="text"
                        id="chatKey"
                        name="chatKey"
                        placeholder="Enter Chat Key"
                        value={chatKey}
                        onChange={(e) => setChatKey(e.target.value)}
                    />
                </div>

                <div className="form-group22">
                    <label htmlFor="chatId">Chat ID:</label>
                    <input
                    required
                        type="text"
                        id="chatId"
                        name="chatId"
                        placeholder="Enter Chat ID"
                        value={chatId}
                        onChange={(e) => setChatId(e.target.value)}
                    />
                </div>

                <div className="form-group22">
                    <label htmlFor="chatCoordinates">Chat Coordinates:</label>
                    <input
                        type="text"
                        id="chatCoordinates"
                        name="chatCoordinates"
                        placeholder="Enter Chat Coordinates"
                        value={chatCoordinates}
                        onChange={(e) => setChatCoordinates(e.target.value)}
                    />
                </div>

                    <button type="submit" className="submit-button23">Create Chat bot</button>
                </form>


                </div>
               
            </div>

        </div>
    )
}

export default BusinessChats;