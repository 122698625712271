import { FaRegEdit } from "react-icons/fa";
import {getDocs,collection,where, addDoc, doc,  getDoc, deleteDoc, updateDoc, query} from 'firebase/firestore';
import { AiFillDelete } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { FaPlus } from "react-icons/fa6";
import {Link} from 'react-router-dom';
import './index.css';
import { useContext, useEffect, useState } from "react";
import { db } from "../../Config-firebase/firebase";
import DeleteItem from "../DeleteItem";
import NewSidebar from "../NewSidebar";
import { dataContext } from "../../Context/data";
// import Cookies from 'js-cookie';

const VoiceCampaign=()=>{
    const [campaignData,setCampaignData]=useState([])
    const [showModal,setShowModal]=useState('');
    const [val,setVal]=useState(0);
    // const [req,setReq]=useState("");
    const {runningBusiness}=useContext(dataContext);

    useEffect(()=>{
        // if(!req?.businessName){
        //     const businesData=Cookies.get('currentBusiness');
        // setReq(JSON.parse(businesData));
        // console.log(req);  
        // }
        const getCampaingnData=async()=>{
        //     if (!req?.businessName) {
        //         console.warn('Business name not available yet. Skipping data fetching.');
        //         return; // Exit early if businessName is undefined
        //       }
            
            const campaignRef= collection(db,"voice-campaign")
            console.log('businessName',runningBusiness?.businessName)
            const campaignQuery=query(campaignRef,where('businessName','==',runningBusiness?.businessName));
            const response= await getDocs(campaignQuery);
            const reponseFormat= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            reponseFormat.sort((a, b) => {
                const dateA = new Date(a.date); // Convert strings to Date objects
                const dateB = new Date(b.date);
                return dateB - dateA; // Descending order (recent first)
              });
              console.log(reponseFormat)
            setCampaignData(reponseFormat);
        }
        getCampaingnData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[val])

    
    const [campaignName,setCampaignName]=useState("");
    const [category,setCategory]=useState("");
    const [currentEditingId,setCurrentEditingId]=useState("");

    const handleAddItem=async()=>{
        const campaignRef= collection(db,"voice-campaign");

        const todayDate = new Date();
        const yyyy = todayDate.getFullYear();
        let mm = todayDate.getMonth() + 1; 
        let dd = todayDate.getDate();
        const hour = todayDate.getHours();
        const minute = todayDate.getMinutes();
        const second = todayDate.getSeconds();

        const hourString = hour.toString().padStart(2, "0");
        const minuteString = minute.toString().padStart(2, "0");
        const secondString = second.toString().padStart(2, "0");

        mm = String(mm).padStart(2, '0'); 
        dd = String(dd).padStart(2, '0');

        const formattedDate = dd + '-' + mm + '-' + yyyy+"  "+hourString+":" +minuteString+ ":"+secondString;
        if(!campaignName){
            return ;
        }
        else if(! category){
            return;
        }   
        const newCampaign={date: formattedDate,campaignName:campaignName,category:category,'businessName':runningBusiness.businessName}
        await addDoc(campaignRef,newCampaign);
        setCampaignName("");
        setCategory("");
        setVal(e=>e+1);
        setShowModal('');
    }
    const handleEditItem=async()=>{
        const campaignRef= doc(db,"voice-campaign",currentEditingId);

        if(!campaignName){
            return ;
        }
           
        else if(! category){
            return;
        }
        const updatedCampaign={campaignName,category}
        await updateDoc(campaignRef,updatedCampaign);
        setCampaignName("");
        setCategory("");
        setCurrentEditingId("");
        setVal(e=>e+1);
        setShowModal('');
    }

    const deleteCampaign=async(id)=>{
        const reqDoc= doc(db,"voice-campaign",id);
        const response=await deleteDoc(reqDoc);
        console.log(response);
        setVal(e=>e+1);
    }

    const updateCampaign=(props)=>{
        const{id,name,category}=props;
        setCurrentEditingId(id);
        setCategory(category);
        setCampaignName(name);
        setShowModal('edit');

    }
    const [deletingId,setDeletingId]=useState("");

    return(
        <>
        <NewSidebar/>
    <div className="voicecampaign-container">
        <div className='voicecampaign-action-bar'>
                <span className="action-gap">
                <h3 className="action-title">Voice( IVR ) {`> Campaign`}</h3>
                    <p className="action-text">Total Campaigns : {campaignData?.length}</p>
                </span>
            
            <button className="voicecampaignAddBtn" onClick={()=>{setShowModal('add')}}><h3>Add</h3> <FaPlus className="plus-icon" /></button>
        </div>
        <div className=''>
            <table className='table-style'>
                    <thead>
                        <tr>
                            <th className='slim'>S.no</th>
                            <th className='slim'>Date</th>
                            <th className='slim-2'>Campaign Name</th>
                            <th className='slim-2'>Category</th>
                            <th className='slim'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {campaignData && campaignData.map((campaign,index)=>{
                            const id=campaign.id;
                            const name=campaign.campaignName;
                            const category=campaign.category;
                            return(
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{campaign.date}</td>
                                <td><Link to={`/voice-campaign/${campaign.id}`}>{campaign.campaignName}</Link></td>
                                <td>{campaign.category}</td>
                                <td className='row-update'>
                                    <FaRegEdit className="update-row" onClick={()=>{updateCampaign({id,name,category})}} />
                                    <AiFillDelete className="delete-row" onClick={()=>{setDeletingId(campaign.id)}} />
                                </td>
                            </tr>)
                        })}
                        {/* <tr>
                            <td>1</td>
                            <td>28-05-2024</td>
                            <td>voting </td>
                            <td>ploitical</td>
                            <td className='row-update'>
                            <FaRegEdit className="update-row" />
                            <AiFillDelete className="delete-row" />
                        </td>
                        </tr> */}
                    </tbody>
                </table>
        </div>
        {showModal && 
        <div className='campaignModalContainer'>

            <div className='campaignForm'>
                <div className='modal-header'>
                    <h2 style={{color:"#363534",marginLeft:'17px'}}>{showModal[0].toUpperCase()+showModal.slice(1)} Campaign</h2>
                    <ImCross style={{cursor:"pointer"}} onClick={()=>{setShowModal('')}} />
                </div>
                <hr/>
                <label className="labels">Campaign Name</label>
                <input type="text" className="campaignInput" placeholder="Enter campaign name" value={campaignName} onChange={(e)=>{setCampaignName(e.target.value)}} />
                <label className="labels">Category</label>
                <input type="text" className="categoryInput" placeholder="Enter category" value={category} onChange={(e)=>setCategory(e.target.value)} />
                <button className="modal-submit" onClick={()=>{showModal==="add"?handleAddItem():handleEditItem()}}>Submit</button>
            </div>

        </div>}
        {deletingId && <DeleteItem id={deletingId} handleClose={setDeletingId} handleDelete={deleteCampaign} />}
    </div></>)
}

export default VoiceCampaign;