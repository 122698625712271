import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { useEffect, useState,useContext } from 'react';

import { FaWhatsapp } from "react-icons/fa6";
import { MdOutlineMail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaCirclePlus } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './index.css';
import { db } from '../../Config-firebase/firebase';
import NewSidebar2 from '../NewSideBar2';
import { IoAlertOutline } from 'react-icons/io5';
import { dataContext } from '../../Context/data';

const Businesses=()=>{
    const [businessDetails,setBusinessDetails]=useState([]);
    const [userData,setUserData]=useState("");
    const [businessData,setBusinessData]=useState(null);
    const { setRunningBusiness } = useContext(dataContext); 

    const navigate=useNavigate("");
    useEffect(()=>{
        const getBusinessdetails=async()=>{
            const businessCollectionRef=collection(db,"registered-businesses");
            const allDocsQuery=query(businessCollectionRef);
            const queryBusinessDetails= await getDocs(allDocsQuery);
            // console.log(queryBusinessDetails.docs);
            const businessArray=queryBusinessDetails.docs.map((doc,inde)=>({...doc.data(),id:doc.id}))
            businessArray.sort((a,b)=>{
                const datea=new Date(a.date);
                const dateb=new Date(b.date);
                return dateb-datea; //descending
            })
            console.log(businessArray);
            setBusinessDetails(businessArray);
        }
        getBusinessdetails();
      
    },[])
    const authenticateUser=(businessName)=>{
        // const  businessId="hzma0MxzDxrkZFLRt81m";
        const getAllowedUsers=async()=>{
            const businessCollectionRef=collection(db,"business-users");
            const allDocsQuery=query(businessCollectionRef,where('businessName','==',businessName));
            const response= await getDocs(allDocsQuery);
            const reponseFormat= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            const emails=reponseFormat.map(e=>e.email)
            console.log(emails,"authoriZEd emails");
            const usersData= Cookies.get('userData');
            let parsedUser;
            if(usersData){
                parsedUser=JSON.parse(usersData)
                setUserData(parsedUser);
                console.log(usersData?.email);
            }
            // if (emails.includes(parsedUser?.email)){
            //     const getBusinessData=async()=>{
            //     const businessCollectionRef=collection(db,"registered-businesses");
            //     const allDocsQuery=query(businessCollectionRef,where('id','==',id));
            //     const response= await getDocs(allDocsQuery);
            //     const businessArray= response.docs.map((doc)=>({...doc.data(),id:doc.id}));
            //     setBusinessData(businessArray[0]);

            //     }
            //     getBusinessData();
            //     console.log("enterd");
            //     // let businessNamed=userData.businessName;
            //     if(businessData?.length()>0){
            //         Cookies.set('currentBusiness',JSON.stringify({businessId,businessData}))
            //     console.log("success");
            //     navigate('/about')
            //     }
            //     console.log("closed");
                
            // }
            // always true for nowwwwwwwwwwwwwwwww
            if (emails.includes(parsedUser?.email) || true) {
                 const getBusinessData = async () => {
                  const businessRegisterCollectionRef = collection(db, "registered-businesses");
                  console.log(businessName)
                  const allDocsQuer = query(businessRegisterCollectionRef, where('businessName', '==', businessName));
                  const response = await getDocs(allDocsQuer);
                  console.log(response);
                  const businessArray = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                  console.log(businessArray);
                  setBusinessData(businessArray[0])
                  return businessArray[0]; // Return the first business data
                };
              
               
                  const businessData = await getBusinessData();
                  if (businessData !=null) {
                    Cookies.set('currentBusiness', JSON.stringify(businessData ));
                    setRunningBusiness(businessData);
                    console.log("success");
                    navigate('/about',{replace:true});
                  } else {
                    console.log("No business data found");
                    // Handle the case where no business data is found (optional)
                  }
                  console.log("closed");
                
              }
              else{
                alert("Access denied");
                return;
              }

        }
        getAllowedUsers();
    }
    
    const handleAddBusiness=()=>{
        navigate("/business-register");
    }
    return(
        <><NewSidebar2/>
        <div className='businesses-container'>
             {businessDetails.length>0 && businessDetails.map((details,index)=>{
                const {businessName,businessLogo,businessOwnerName,businessEmail,businessOwnerNumber,businessAddress,businessCategory}=details;
             return(
                <div className='business-card' key={index} onClick={()=>{}}>
                    <div className='image-container'>
                        <img src={businessLogo} className='business-icon' alt={`${businessName} Logo`}/>
                    </div>
                    
                    <div className='business-daat1'>
                        <h2 style={{marginBottom:"6px"}}>{businessName}</h2>
                        <h4 className='with-icon'><FaWhatsapp className='business-icoo' />{businessOwnerNumber}</h4>
                    </div>
                    <div className='business-daat2'>
                        <span className='daat2-data'>
                            <h4>{businessCategory}</h4>
                            <h4>{businessOwnerName}</h4>
                        </span>
                        
                        <h4 className='with-icon'><MdOutlineMail className='business-icoo' />{businessEmail}</h4>
                    </div>
                    <h4 className='with-icon'><FaLocationDot className='business-icoo' />{businessAddress}</h4>
                    <div className='authbtn-container'>
                        <button className='authenticate-btn' onClick={()=>{authenticateUser(businessName)}}>Login</button>
                    </div>
                   
                </div>)}
            )}
            <FaCirclePlus className='addBusiness-icon' onClick={ handleAddBusiness} />
        </div>
        </>
    )
}

export default Businesses;