import React from 'react';
import { Navigate,Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ children, ...rest }) => {

  const businessData= Cookies.get('currentBusiness');
 return businessData? <Outlet/>:<Navigate to="/" />

};

export default ProtectedRoute;
