import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore';
import {getAuth,GoogleAuthProvider} from 'firebase/auth';

// const firebaseConfig = {
//     apiKey: process.env.FIREBASE_API_KEY,
//     authDomain: process.env.AUTHDOMAIN,
//     projectId: process.env.PROJECT_ID,
//     storageBucket: process.env.STORAGE_BUCKET,
//     messagingSenderId: process.env.MESSAGE_SENDER_ID,
//     appId: process.env.APP_ID,
//     measurementId: process.env.MEASUREMENT_ID
//   };
const firebaseConfig = {
    apiKey: "AIzaSyC9F7WIMTnYxQcyzJmJCNABsxQ_9J0Z90I",
    authDomain: "wizzmedia-buzz.firebaseapp.com",
    projectId: "wizzmedia-buzz",
    storageBucket: "wizzmedia-buzz.appspot.com",
    messagingSenderId: "1026346131975",
    appId: "1:1026346131975:web:89e4ce2977e1721ec280a2",
    measurementId: "G-NLGEZJM820"
  };

const app=initializeApp(firebaseConfig);
export const auth= getAuth(app);
export const googleAuth=new GoogleAuthProvider();
export const db=getFirestore(app);