// import { useEffect, useState } from 'react';
// import './index.css';
// import { base_url } from '../../utils/new';
// import showdown from 'showdown';

const BusinessChatDemo=()=>{
    const chatDetailsString = localStorage.getItem('chat_params');
    const chatDetails = chatDetailsString ? JSON.parse(chatDetailsString) : {};
    console.log(chatDetails);
    
    // console.log(chat_color,chat_icon,landing_image,welcome_message,chat_key ,chat_id,chat_coordinates );
    // const runDemo = (chatId) => {
    //     // setShowDemo(chatId);

    //     // Clean up existing script tag
    //     const existingScript = document.getElementById('dynamic-chat-script');
    //     if (existingScript) {
    //         existingScript.remove();
    //     }

    //     // Create new script tag
    //     const scriptTag = document.createElement('script');
    //     scriptTag.id = 'dynamic-chat-script';
    //     scriptTag.src = `https://cdn.jsdelivr.net/gh/idontnol/wizz-aibot@main/nchat.js`; // Adjust URL and parameter
    //     scriptTag.async = true;
    //     scriptTag.chatKey=chatId;
    //     document.body.appendChild(scriptTag);
    // }
    
    // const [chatKey, setChatKey] = useState(null);
    // const [chatParams, setChatParams] = useState({});
    // const [chatData, setChatData] = useState([{ answer: '' }]);
    // const [query, setQuery] = useState('');
    // const [isCollapsed, setIsCollapsed] = useState(true);
    // const [isTyping, setIsTyping] = useState(false);
    // useEffect(() => {
    //     if (chatDetails?.id && !chatKey) {
    //         setChatKey(chatDetails.id);
    //     }
    //     //eslint-disable-next-line
    // }, [chatDetails]);

    // const fetchChatParams = async () => {
    //     try {
    //     const response = await fetch(`https://wizzmedias-buzz-backend.vercel.app/api/chatbot/getparamters/${chatKey}`);
    //     const result = await response.json();
    //     const params = result?.params || {};
    //     setChatParams(params);
    //     console.log(params['welcomeMessage'],"heeeeee");
    //     setChatData([{ answer: params['welcomeMessage'] || '' }]);
    //     } catch (error) {
    //     console.error('Error fetching chat params:', error);
    //     }
    // };

    // const sendQuery = async () => {
    //     if (!query || query.length < 5) {
    //     alert('Please enter a valid query');
    //     return;
    //     }

    //     const tempDataWithQuestion = [...chatData, { question: query }];
    //     setChatData(tempDataWithQuestion);
    //     setQuery('');
    //     setIsTyping(true);

    //     try {
    //     const encodedQuery = encodeURIComponent(query);
    //     const key = encodeURIComponent(chatDetails.id);
    //     console.log(key,"key here");
    //     const response = await fetch(base_url+`api/chatbot/query/?question=${encodedQuery}&key=${key}`);
    //     let { answer } = await response.json();
    //     let converter = new showdown.Converter();
    //     answer = converter.makeHtml(answer);
    //     setIsTyping(false);

    //     let currentAnswer = '';

    //     for (let i = 0; i < answer.length; i++) {
    //         currentAnswer += answer[i];
    //         await new Promise(resolve => setTimeout(resolve, 8)); // Simulate typing effect
    //         setChatData(tempDataWithQuestion.concat({ answer: currentAnswer }));
    //     }
    //     } catch (error) {
    //     console.error('Error sending query:', error);
    //     setIsTyping(false);
    //     }
    // };

    // const handleKeyDown = (event) => {
    //     if (event.key === 'Enter') {
    //     sendQuery();
    //     }
    // };


    // useEffect(() => {
    //     if (chatKey && Object.keys(chatParams).length === 0) {
    //     fetchChatParams();
    //     }
    //     //eslint-disable-next-line
    // }, [chatKey]);

    // const toggleCollapse = () => {
    //     setIsCollapsed(!isCollapsed);
    // };
   
    return(
        <div className=""
        style={{ 
            backgroundImage: `url(${chatDetails['landingImage']})`,
            backgroundSize:"cover",
            height: "100vh", 
            width: "100%",
            top:0,
            left:0,
            position:'fixed'
        }}>
            {/* <div className='chatbot-wrapper'>
             <div className={`chatbot-container ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="chatbot-header" style={{ backgroundColor: chatParams['chatColor'] }}>
          <img
            src={chatParams['chatIcon'] || 'default-icon-url'}
            alt="profile-icon"
            className="profile-icon"
            style={{ cursor: 'pointer' }}
            onClick={toggleCollapse}
          />
          <div className="header-icons">
            <span style={{ cursor: 'pointer',display: isCollapsed && 'none' }} onClick={toggleCollapse} >
              &#10006;
            </span>
          </div>
        </div>
        <div className="chatbot-body" id="chatBody">
          {chatData.map((chat, idx) => (
            <div key={idx} className={`chatbox ${chat.question ? 'questionBox' : 'answerBox'}`}>
              {chat.question || chat.answer}
            </div>
          ))}
          {isTyping && (
            <div className="chatbox-typing">
              <p>Typing</p>
              <div className="typing-dots">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
        </div>
        <div className="chatbot-input">
          <input
            type="text"
            id="chatInput"
            placeholder="Type your question here..."
            className="chat-input"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <span style={{ cursor: 'pointer' }} onClick={sendQuery}>
            &#9658;
          </span>
        </div>
      </div>
      </div> */}
            
        </div>
    )
}

export default BusinessChatDemo;