import Cookies from 'js-cookie';
import {useNavigate} from 'react-router-dom';
import'./index.css';
import { useContext, useEffect, useRef, useState } from 'react';
import NewSidebar from '../NewSidebar';
import { dataContext } from '../../Context/data';
import QRCode from 'react-qr-code'
import { IoMdDownload } from "react-icons/io";
import { base_url } from '../../utils/new';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

const BusinessProfile=()=>{
    const navigate=useNavigate();
    const [businessData,setBusinessData]=useState('');
    const {setRunningBusiness}=useContext(dataContext);
    const baseUrl="https://www.wizzmedia.in"
    const [qrUrl, setQrUrl] = useState('');
    const [showPoster,setShowPoster]=useState(false);
    const doc = new jsPDF();

    const logoutBusiness=()=>{
        Cookies.remove('currentBusiness');
        setBusinessData("");
        setRunningBusiness("");
        navigate('/');
    }
    // const handleDownload = () => {
    //     const link = document.createElement('a');
    //     link.href = qrUrl;
    //     link.traget="_blank_"
    //     link.download = 'qr-code.png'; // Set filename for download
    //     link.click();
    //   };
 
        const downloadRef = useRef(null); // Reference to the div to download
      
        // const handleDownload = async () => {
        //   const canvas = await html2canvas(downloadRef.current);
        //   const dataURL = canvas.toDataURL('image/png'); // Specify desired image format
        //   const doc = new jsPDF({
        //     orientation: 'portrait', // Adjust orientation if needed
        //     unit: 'mm', // Unit for measurements
        //     format: [210, 297], // A4 paper size (adjust dimensions as needed)
        //   });
    
        //   // Add the QR code image to the PDF (consider positioning)
        //   const imageWidth = 60; // Adjust image width in mm
        //   const imageHeight = 50; // Adjust image height in mm
        //   doc.addImage(dataURL, 'PNG', 10, 10, imageWidth, imageHeight); // Adjust x, y coordinates
        //   doc.addImage(businessData.businessLogo, 'PNG',11,12, 16, 10// Adjust coordinates as needed
        //    );
        //   // Add additional content to the PDF (optional)
        //   // doc.text('Additional content here', 20, 70); // Example
    
        //   doc.save('business-qr-code.pdf');
        //   // Set filename and mimetype
        // };
        const handleDownload = () => {
            const element = downloadRef.current;
            html2canvas(element, { useCORS: true }).then((canvas) => {
              const imgData = canvas.toDataURL('image/png');
              const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'px',
                format: [canvas.width, canvas.height],
              });
              pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
              pdf.save('download.pdf');
            });
          };
    useEffect(()=>{

        const usersData=Cookies.get('currentBusiness');
    if(usersData && businessData===''){
        setBusinessData(JSON.parse(usersData));
    }
    if(businessData!==""){
        setQrUrl(`https://api.qrserver.com/v1/generate?data=${`${baseUrl}/register/?businessname=${businessData.businessName}`}&size=250x250`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    // const normalData=Cookies.get('currentBusiness');
    // const bData=JSON.parse(normalData)

    return(
        <>
        <NewSidebar/>
        <div className='business-profile-container'>
             <div className='business-profile-left'>
                <h2>Business Details</h2>
                <img src={businessData.businessLogo} alt={businessData.businessName} className='business-profile-logo'/>

                <div className='business-profile-left-details'><h4>Business Name:</h4>  <p>{businessData?.businessName}</p> </div>
                <div className='business-profile-left-details'><h4>Owner Name: </h4>  <p>{businessData?.businessOwnerName}</p> </div>
                <div className='business-profile-left-details'><h4>Contact: </h4>  <p>{businessData?.businessOwnerNumber}</p> </div>
                <div className='business-profile-left-details'><h4>Gmail: </h4>  <p>{businessData?.businessEmail}</p> </div>
                <div className='business-profile-left-details'><h4>Address: </h4>  <p>{businessData?.businessAddress}   {businessData?.businessPincode}</p> </div>
                <div className='business-profile-left-details'><h4>Category: </h4>  <p>{businessData?.businessCategory}</p> </div>
                <div className='business-profile-left-details'><h4>Website: </h4>  <a href={businessData.businessWebsite} target='_blank_'>{businessData?.businessWebsite}</a> </div>
                
                {/* <p className=''>Business Turn Over Range: {businessData?.businessTurnoverRange}</p>
                <p className=''>Business GST Number: {businessData?.businessGSTNumber}</p>
                <p className=''>Business Youtube Channel: {businessData?.businessYoutubeChannel}</p>
                <p className=''>Business Google Page: {businessData?.businessGooglePage}</p> */}
                <button onClick={()=>{logoutBusiness()}}>logout</button>
            </div>

            
            <div className='business-profile-right'>
                <div className='downlad-container'>
                    <IoMdDownload className='download-qr' onClick={()=>{setShowPoster(true)}} />
                </div>
                
                <div className='business-qr-right-header'>
                    <img className='business-qr-logo' src={businessData.businessLogo} alt="mobishaala"/>
                    <h1 className=''>{businessData.businessName}</h1>
                </div>
                
                   

                
                <QRCode value={`${baseUrl}/register/?businessname=${businessData.businessName}`}/>
                <h4>Scan the QR code</h4>
            </div>
            {/* test */}
            {showPoster && <div className='qr-poster'>
                <div ref={downloadRef} style={{ position: 'relative', display: 'inline-block' }} className='poster-outer' >
                    <img src="qrcode-poster4.png" alt="Background" style={{ width: '100%' }} className='poster-back' />
                    <div className='poster-name'
                    style={{
                        position: 'absolute',
                        left: '45%',
                        top: '9%',
                        transform: 'translate(-50%, -50%)',
                        textTransform: 'uppercase', // Converts text to uppercase
                        fontStyle: 'italic', // Applies italic style
                        fontWeight: 'bold', // Optional: Makes text bold
                        fontFamily: 'Arial, sans-serif', // Optional: Specify a font family
                        fontSize: '2em', // Optional: Adjust font size
                        color: '#333',
                        width:'100%',
                        display: 'flex',
                        justifyContent: 'center',
                        }}
                    ><h2>{businessData.businessName}</h2>
                    </div>
                    <div style={{
                    position: 'absolute',
                    left: '34%',
                    top: '69%',
                    transform: 'translate(-50%, -50%)',
                    }}>
                    <QRCode value={`${baseUrl}/register/?businessname=${businessData.businessName}`}   />
                    </div>
                </div>
                <div className='poster-btns'>
                <button onClick={()=>{handleDownload();setShowPoster(false)}} className='download-poster'>Download Poster</button>
                <button onClick={()=>{setShowPoster(false)}} className='close-poster'>Close Poster</button>
                </div>
               
            </div>}
            

        </div>
        </>
    )
}

export default BusinessProfile;